<img src="../../../assets/logos/mobilend-light-logo.png" alt="mobilend logo" />
<div class="row">
  <div class="col-6">
    <div>
      <p class="fw-bold">Contact Us:</p>
      <p>(866) 330-1669</p>
      <p>support@mobilend.com</p>
    </div>
    <br />
    <div>
      <p class="fw-bold">Opening Hours:</p>
      <p>Monday to Friday, from 8am to 5pm (Pacific Time)</p>
    </div>
    <br />
    <div class="advertising-disclosure">
      <p>Advertising Disclosure</p>
      <p>
        This credit eligibility service is completely free to use. We may receive a fee or commission for introductions to credit providers. Using this service does not impact the interest rates that you are eligible for. The offers available through this service do not include all financial services companies and do not include all products or services available. All rates, fees, and terms are presented without guarantee and are subject to change pursuant to each provider’s discretion
      </p>
    </div>
  </div>
  <div class="col-6">
    <div class="links-container">
      <div>
        <span class="links-label">Company</span>
        <ul>
          <li>
            <a routerLink="/about-us">About Us</a>
            </li>
          <li>
            <a [href]="blogUrl">Blog</a>
          </li>
          <!-- <li>
            <a href="">Licensing</a>
          </li> -->
          <li>
            <a routerLink="/legal-center">Legal Center</a>
          </li>
          <li>
            <a routerLink="/help-center">Help Center</a>
          </li>
        </ul>
      </div>
      <div>
        <span class="links-label">Connect</span>
        <ul>
          <li>
            <a routerLink="/contact-us">Contact Us</a>
          </li>
        </ul>
      </div>
      <div>
        <span class="links-label">Social media</span>
        <ul>
          <li>
            <div class="d-flex justify-content-center justify-content-sm-start gap-4">
              <!-- <a href="">
                <img src="../../../assets/logos/facebook.png" alt="mobilend facebook" />
              </a> -->
              <a href="https://twitter.com/MobilendInc" target="_blank">
                <img src="../../../../assets/logos/x.png" alt="mobilend x" />
              </a>
              <a href="https://www.linkedin.com/company/mobilend-com" target="_blank">
                <img src="../../../../assets/logos/linkedin.png" alt="mobilend linkedin" />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-column flex-sm-row gap-4 w-100">
  <span class="flex-grow-1 text-center text-sm-start">
    © 2016-{{ currentYear }} Mobilend.com, a Kuber Financial, LLC product. All rights reserved.
  </span>
  <div class="
    flex-grow-1
    d-flex
    gap-5
    justify-content-center
    justify-content-sm-end
  ">
    <span>
      Kuber Financial NMLS: 1735696
    </span>
    <span>
      Lender License: 60DBO58789
    </span>
  </div>
</div>