import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";
import { Roles } from "src/app/core/types/role.types";

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  @Input() appHasRole: Roles[];
  isVisible = false;
  
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const loggedUser = this.authService.getLoggedUser()!;
   
      if (!loggedUser) {
        this.viewContainerRef.clear();
      }

      if (this.appHasRole.includes(loggedUser?.role)) {
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
  }
}
