import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { CoreService } from '../../../core/services/core.service';
import { TwoFactorAuthenticationPayload } from '../../../core/types/auth.types';
import { Roles } from '../../../core/types/role.types';

@Component({
  selector: 'app-2fa-login',
  templateUrl: './2fa-login.component.html',
  styleUrls: ['./2fa-login.component.scss']
})
export class TwoFactorAuthenticationLoginComponent implements OnInit {
  public token: string;
  public twoFactorAuthenticationLoginForm = this.fb.nonNullable.group({
    code: ['', [Validators.required]],
  });
  
  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService, 
    private coreService: CoreService,
  ) {}

  public ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.token = params['token'];
      }
    );
  }

  public getErrorMessage(controlName: keyof typeof this.twoFactorAuthenticationLoginForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.twoFactorAuthenticationLoginForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    }

    return errorMessage;
  }

  public handleSubmit(): void {
    if (this.twoFactorAuthenticationLoginForm.valid) {
      const twoFactorAuthenticationLoginFormData = this.twoFactorAuthenticationLoginForm.value as TwoFactorAuthenticationPayload;

      this.coreService.setIsLoading(true);
      this.authService
        .login2fa(twoFactorAuthenticationLoginFormData, this.token)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe(({ user }) => {
          const url = this.getRouteUrl(user.role.name, user.applications?.[0]?.id);

          if (url) {
            this.router.navigate(
              [url],
              { relativeTo: this.route }
            );
          }
        });
    }
  }

  private getRouteUrl(role: Roles, applicationId?: number): string {
    let url = '';

    switch (role) {
      case Roles.Admin:
        url = '../../../admin';
        break;
      case Roles.CustomerService:
        url = `../../../customer-service`;
        break;
      case Roles.Underwriter:
        url = `../../../underwriter`;
        break;
      case Roles.Affiliate:
        url = `../../../affiliate`;
        break;
      case Roles.Agent:
        url = `../../../agent`;
        break;
      case Roles.Application:
        url = `../../../customer/applications/${applicationId}`;
        break;          
      default:
        break;
    }
    return url;
  }
}
