import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Application } from '../../../../core/types/application.types';
import { Offer } from '../../../../core/types/offer.types';
import { BankAccount } from '../../../../core/types/bank-account.types';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-ach-agreement',
  templateUrl: './ach-agreement.component.html'
})
export class AchAgreementComponent implements OnInit {
  @Input() public application: Application;
  @Input() public selectedOffer: Offer;
  @Input() public bankAccount: BankAccount;
  @Input() public signature?: SafeResourceUrl;
  @Input() public signaturesMap: Map<string, boolean>;
  @Output() public onSign: EventEmitter<string> = new EventEmitter();
  public signedAt?: string;
  public today: number = Date.now();
  public baseUrl: string = environment.baseUrl;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setExistingSignatureDate();
  }

  public get formattedPhoneNumber(): string {
    return this.application.customer.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  public handleSignButtonClick(signatureIdentifier: string): void {
    this.onSign.emit(signatureIdentifier);
  }

  private setExistingSignatureDate(): void {
    const achAgreement = this.application.contractSignatures?.find(
      (signature) => signature.agreement === 'ach' && signature.status !== 'rejected'
    );

    if (achAgreement) {
      this.signedAt = achAgreement.signedAt;
    }
  }
}
