<div class="
  d-flex
  flex-column
  flex-sm-row
  align-items-center
  justify-content-between
  gap-4
  bg-transparent
  header
">
  <a routerLink="/home">
    <img
      *ngIf="variant === 'dark'"
      src="../../../assets/logos/mobilend-dark-logo.png"
      alt="mobilend logo"
    />
    <img
      *ngIf="variant === 'light'"
      src="../../../assets/logos/mobilend-light-logo.png"
      alt="mobilend logo"
    />
  </a>
  <div class="header-buttons">
    <ng-container *ngTemplateOutlet="loginButton"></ng-container>
    <mat-icon
      svgIcon="menu"
      [ngClass]="{
        'menu-button': true,
        'light-menu-button': variant === 'light'
      }"
      (click)="toggleMenu()"
    ></mat-icon>
  </div>
</div>

<app-backdrop *ngIf="showMenu" [@showMenuAnimation]>
  <div class="d-flex justify-content-end h-100">
    <div class="menu">
      <div class="menu-buttons">
        <ng-container *ngTemplateOutlet="loginButton"></ng-container>
        <mat-icon class="close-button" (click)="toggleMenu()">close</mat-icon>
      </div>
      <nav>
        <div>
          <div class="d-flex gap-4 align-items-center">
            <span>01</span>
            <a routerLink="/about-us" class="link-label">
              ABOUT US
            </a>
          </div>
          <div>
            <ul>
              <li>
                <a routerLink="/what-we-do" class="sublink-label">
                  WHAT WE DO
                </a>
              </li>
              <li>
                <a routerLink="/why-mobilend" class="sublink-label">
                  WHY CHOOSE US
                </a>
              </li>
            </ul>
          </div>
        </div>
        <a
          routerLink="/our-services"
          class="d-flex gap-4 align-items-center"
        >
          <span>02</span>
          <span class="link-label">OUR SERVICES</span>
        </a>
        <a
          routerLink="/how-it-works"
          class="d-flex gap-4 align-items-center"
        >
          <span>03</span>
          <span class="link-label">HOW IT WORKS</span>
        </a>
        <a
          routerLink="/contact-us"
          class="d-flex gap-4 align-items-center"
        >
          <span>04</span>
          <span class="link-label">CONTACT US</span>
        </a>
      </nav>
      <div class="d-flex gap-5">
        <a routerLink="/help-center" class="sublink-label">
          HELP CENTER
        </a>
        <a routerLink="/legal-center" class="sublink-label">
          LEGAL CENTER
        </a>
      </div>
    </div>
  </div>
</app-backdrop>

<ng-template #loginButton>
  <button class="login-button" routerLink="/auth/login">
    <span>LOG</span>
    <mat-icon>login</mat-icon>
  </button>
</ng-template>