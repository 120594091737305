import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Application, CreateEmploymentInformatioStepDto } from '../../../core/types/application.types';
import { Offer } from '../../../core/types/offer.types';
import { CoreService } from '../../../core/services/core.service';
import { ApplicationService } from "../../../core/services/application.service";
import { OfferService } from "../../../core/services/offer.service";
import { Actions, StepperAction } from '../../../features/enrollment/enrollment.component';
import { LoggedUser } from '../../../core/types/auth.types';
import { AuthService } from '../../../core/services/auth.service';
import { ModalComponent } from '../modal/modal.component';
import { isValidYearsInMonthsValidator } from '../../directives/years-in-monts/years-in-months.directive';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-employment-information-step',
  templateUrl: './employment-information-step.component.html',
  styleUrls: ['./employment-information-step.component.scss']
})
export class EmploymentInformationStepComponent implements OnInit {
  @Input() application?: Application;
  @Output() public onAction = new EventEmitter<StepperAction>();
  public loggedUser: LoggedUser;
  public incomeTypeInputOptions: SelectOption[] = [
    { label: 'Employment Income', value: 'Employment Income' },
    { label: 'Self-Employment Income', value: 'Self-Employment Income' },
    { label: 'Rental Income', value: 'Rental Income' },
    { label: 'Investment Income', value: 'Investment' },
    { label: 'Pension Income', value: 'Pension' },
    { label: 'Social Security Income', value: 'Social Security' },
    { label: 'Alimony or Child Support', value: 'Alimony or Child Support' },
    { label: 'Government Assistance', value: 'Government Assistance' },
    { label: 'Annuity Income', value: 'Annuity Income' },
    { label: 'Royalties', value: 'Royalties' },
    { label: 'Other Sources of Income', value: 'Other' }
  ];
  public otherIncomeInputOptions: SelectOption[] = [
    { label: 'Public Assistance', value: 'Public Assistance' },
    { label: 'Disability', value: 'Disability' },    
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Financial AID', value: 'Financial AID' },
    { label: 'Regular Allowance', value: 'Regular allowance' },
    { label: 'Other', value: 'Other' }
  ];
  public employerStatusInputOptions: SelectOption[] = [
    { label: 'Employee', value: 'Employee' },
    { label: 'Self Employee', value: 'Self Employee' },
    { label: 'Worker', value: 'Worker' },
    { label: 'Student', value: 'Student' },
    { label: 'Unemployed', value: 'Unemployed' },
  ];
  public paymentFrequencyInputOptions: SelectOption[] = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Bi-Weekly', value: 'Bi Weekly' },
    { label: 'Bi-Monthly', value: 'Bi Monthly' },
    { label: 'Other', value: 'Other' }
  ];
  public holidayPaymentsInputOptions: SelectOption[] = [
    { label: 'Before', value: 'Before' },
    { label: 'After', value: 'After' }
  ];
  public employmentInformationForm = this.fb.nonNullable.group({
    employerName: ['', Validators.required],
    employerStatus: ['', Validators.required],
    holidayPayments: [''],
    incomeType: ['', Validators.required],
    otherIncome: [''],
    monthsAtEmployer: ['', [Validators.required, isValidYearsInMonthsValidator()]],
    paymentFrequency: ['', Validators.required],
    workPhone: [''],
    tcpaConsent: [false, Validators.requiredTrue],
    electronicConsent: [false, Validators.requiredTrue]
  });
  public modalRef?: MatDialogRef<ModalComponent>;

  constructor(
    private coreService: CoreService,
    private fb: FormBuilder,
    private authService: AuthService,
    private applicationService: ApplicationService,
    private offersService: OfferService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser()!;
    this.setFormsInitialValues();
  }

  public handleBackButtonClick(): void {
    if (this.loggedUser) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      this.onAction.emit({ action: Actions.Back });
    }
  }

  public getErrorMessage(controlName: keyof typeof this.employmentInformationForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.employmentInformationForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    } else if (control.hasError('requiredTrue')) {
      errorMessage = `${label} must be accepted`;
    } else if (control.hasError('isValidYearsInMonths')) {
      errorMessage = control.errors!['isValidYearsInMonths']
    }

    return errorMessage;
  }

  public isCheckboxInvalid(controlName: 'electronicConsent' | 'tcpaConsent'): boolean {
    return (
      this.employmentInformationForm.controls[controlName].touched &&
      this.employmentInformationForm.controls[controlName].invalid
    );
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  public async handleFormSubmit(): Promise<void> {
    this.employmentInformationForm.markAllAsTouched();

    if (this.employmentInformationForm.valid) {
      try {
        this.coreService.setIsLoading(true);

        const storage = this.coreService.getSessionStorageData<{ token: string }>('token');
  
        const application = await this.createEmploymentInformationApplication(storage?.token);
        application.offers = await this.generateOffers(application.id, storage?.token);

        if (this.loggedUser && this.loggedUser.applicationId === application.id) {
          this.toastrService.success(
            'Application successfully updated', 
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
          this.router.navigate(['..'], { relativeTo: this.route });
        } else {
          this.onAction.emit({ action: Actions.Next, data: { application } });
        }
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  private setFormsInitialValues(): void {
    if (this.application?.employmentInformation) {
      const { customer, employmentInformation } = this.application;

      this.employmentInformationForm.patchValue({
        employerName: employmentInformation.employerName,
        employerStatus: employmentInformation.employerStatus,
        holidayPayments: employmentInformation.holidayPayments,
        incomeType: employmentInformation.incomeType,
        monthsAtEmployer: String(employmentInformation.monthsAtEmployer),
        paymentFrequency: employmentInformation.paymentFrequency,
        workPhone: employmentInformation.workPhone,
        tcpaConsent: true,
        electronicConsent: true
      });
    }
  }

  private createEmploymentInformationApplication(token?: string): Promise<Application> {
    const { monthsAtEmployer, ...restEmployerInformationValues } = this.employmentInformationForm.value!;
    const createEmploymentInformationStepPayload = {
      monthsAtEmployer: Number(monthsAtEmployer),
      ...restEmployerInformationValues,
    } as CreateEmploymentInformatioStepDto;

    return lastValueFrom(this.applicationService.createEmploymentInformation(
      this.application?.id || this.loggedUser.applicationId, 
      createEmploymentInformationStepPayload, 
      token
    ));
  }

  private generateOffers(applicationId: number, token?: string): Promise<Offer[]> {
    return lastValueFrom(this.offersService.generateOffers(applicationId, token));
  }
}
