import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SignatureInputComponent } from './signature-input.component';

@NgModule({
  declarations: [SignatureInputComponent],
  imports: [CommonModule, AngularSignaturePadModule, MatInputModule, MatFormFieldModule],
  exports: [SignatureInputComponent]
})
export class SignatureInputModule {}
