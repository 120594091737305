import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { NumberToWordsModule } from './pipes/number-to-words/number-to-words.module';
import { FormatPhraseModule } from './pipes/format-phrase/format-phrase.module';
import { FormatSizeModule } from './pipes/format-size/format-size.module';
import { InputAddressModule } from './directives/input-address/input-address.module';
import { HasRoleModule } from './directives/role/has-role.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { ButtonModule } from './components/button/button.module';
import { SignatureInputModule } from './components/signature-input/signature-input.module';
import { DateInputModule } from './components/date-input/date-input.module';
import { CardModule } from './components/card/card.module';
import { TableModule } from './components/table/table.module';
import { ApplicationDetailsModule } from './components/application-details/application-details.module';
import { AgreementsModule } from './components/agreements/agreements.module';
import { AddApplicationsModule } from './components/add-application/add-application.module';
import { UploadDocumentsModule } from './components/upload-documents/upload-documents.module';
import { SupermoneyOffersModule } from './components/supermoney-offers/supermoney-offers.module';
import { AddressInputModule } from './components/address-input/address-input.module';
import { ApplicationsChartModule } from './components/applications-chart/applications-chart.module';
import { ApplicationsListModule } from './components/applications-list/applications-list.module';
import { ApplicationsTableModule } from './components/applications-table/applications-table.module';
import { BackdropModule } from './components/backdrop/backdrop.module';
import { LoggedUserModule } from './components/logged-user/logged-user.module';
import { ModalModule } from './components/modal/modal.module';
import { UsersModule } from './components/users/user.module';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.module';
import { WarningModule } from './components/warning/warning.module';

@NgModule({
  exports: [
    NumberToWordsModule,
    FormatPhraseModule,
    FormatSizeModule,
    InputAddressModule,
    HasRoleModule,
    ButtonModule,
    SignatureInputModule,
    DateInputModule,
    CardModule,
    TableModule,
    ApplicationDetailsModule,
    AgreementsModule,
    AddApplicationsModule,
    UploadDocumentsModule,
    SupermoneyOffersModule,
    AddressInputModule,
    ApplicationsChartModule,
    ApplicationsListModule,
    ApplicationsTableModule,
    BackdropModule,
    HeaderModule,
    FooterModule,
    LoggedUserModule,
    ModalModule,
    WarningModule,
    UsersModule,
    BreadcrumbModule
  ]
})
export class SharedModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('arrow-right', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/arrow-right.svg'));
    iconRegistry.addSvgIcon('arrow-left', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/arrow-left.svg'));
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/edit.svg'));
    iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/calendar.svg'));
    iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/search.svg'));
    iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/info.svg'));
    iconRegistry.addSvgIcon('next', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/next.svg'));
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/upload.svg'));
    iconRegistry.addSvgIcon('file', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/file.svg'));
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/trash.svg'));
    iconRegistry.addSvgIcon('view-on', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/carbon_view-on.svg'));
    iconRegistry.addSvgIcon('view-off', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/carbon_view-off.svg'));
    iconRegistry.addSvgIcon('exclamation-mark', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/exclamation-mark.svg'));
    iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/add.svg'));
    iconRegistry.addSvgIcon('checkmark', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/checkmark.svg'));
    iconRegistry.addSvgIcon('menu', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/menu.svg'));
    iconRegistry.addSvgIcon('semiarrow-right', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/semiarrow-right.svg'));
    iconRegistry.addSvgIcon('semiarrow-left', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/semiarrow-left.svg'));
    iconRegistry.addSvgIcon('box-arrow-right', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/box-arrow-right.svg'));
    iconRegistry.addSvgIcon('box-arrow-left', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/box-arrow-left.svg'));
    iconRegistry.addSvgIcon('bank', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/bank-bold.svg'));
    iconRegistry.addSvgIcon('bank-cards', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/bank-cards.svg'));
    iconRegistry.addSvgIcon('dollar', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/dollar-solid.svg'));
    iconRegistry.addSvgIcon('check', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/check.svg'));
    iconRegistry.addSvgIcon('check-circle-outlined', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/check-circle-outlined.svg'));
  }
}
