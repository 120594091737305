import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Application } from '../../../core/types/application.types';
import { Actions, StepperAction } from '../enrollment.component';
import { Offer } from '../../../core/types/offer.types';
import { CoreService } from '../../../core/services/core.service';

@Component({
  selector: 'app-congrats-step',
  templateUrl: './congrats-step.component.html',
  styleUrls: ['./congrats-step.component.scss']
})
export class CongratsStepComponent implements OnInit {
  @Input() public application: Application;
  @Output() public onAction = new EventEmitter<StepperAction>();
  public selectedOffer?: Offer;

  constructor(private router: Router, private coreService: CoreService) {}

  public ngOnInit(): void {
    this.coreService.clearSessionStorage('enrollment')
    this.coreService.clearSessionStorage('token');
    this.selectedOffer = this.application.offers?.find((offer) => offer.selected);
  }

  public handleBackToHomeButtonClick(): void {
    this.onAction.emit({ action: Actions.Finish });
    this.router.navigate(['/home']);
  }
}
