import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OurServicesComponent } from './our-services.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [OurServicesComponent],
  imports: [RouterModule, SharedModule]
})
export class OurServicesModule {}
