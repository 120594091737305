import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { HelpCenterComponent } from './help-center.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [HelpCenterComponent],
  imports: [MatExpansionModule, SharedModule, CommonModule]
})
export class HelpCenterModule {}
