<div>
  <h1>Create your account</h1>
</div>
<form
  [formGroup]="createAccountForm"
  (submit)="handleFormSubmit()"
>
  <div class="row">
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>First Name*</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error>
          {{ getErrorMessage('firstName', 'First Name') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>Middle Name</mat-label>
        <input matInput formControlName="middleName" />
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Last Name*</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error>
          {{ getErrorMessage('lastName', 'Last Name') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Email*</mat-label>
        <input matInput formControlName="email" />
        <mat-error>
          {{ getErrorMessage('email', 'Email') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Phone Number*</mat-label>
        <input matInput formControlName="phone" mask="(000) 000-0000" />
        <mat-error>
          {{ getErrorMessage('phone', 'Phone Number') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>How did you hear about Mobilend?</mat-label>
        <mat-select formControlName="heardFrom">
          <mat-option *ngFor="let option of heardFromInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <p class="mt-4 mb-5">
    By creating an account, you accept Kuber Financial, LLC's dba Mobilend ("Mobilend") <a routerLink="/legal-center/terms-conditions" target="_blank">Terms of Service</a> and <a routerLink="/legal-center/privacy-policy" target="_blank">Privacy Statement</a>.
  </p>
  <div class="
    d-flex
    justify-content-center
    justify-content-lg-end
  ">
    <div class="
      d-flex
      flex-column
      flex-sm-row
      flex-md-column
      flex-lg-row
      gap-4
    ">
      <app-button
        variant="outlined"
        label="BACK"
        (click)="handleBackButtonClick()"
      ></app-button>
      <app-button
        type="submit"
        label="CONTINUE"
        svgIcon="semiarrow-right"
      ></app-button>
    </div>
  </div>
</form>