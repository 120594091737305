import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { DontSellMyPersonalInformationModalComponent } from './dont-sell-my-personal-information-modal/dont-sell-my-personal-information-modal.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  @ViewChild(DontSellMyPersonalInformationModalComponent)
  private dontSellMyPersonalInformationModalComponent: DontSellMyPersonalInformationModalComponent;
  public modalRef?: MatDialogRef<ModalComponent>;

  constructor(private dialog: MatDialog) {}

  public handleDontSellMyPersonalInformationClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Don\'t Sell My Personal Information',
        contentTemplate: this.dontSellMyPersonalInformationModalComponent.dontSellMyPersonalInformationModalContentTemplate,
        actionsTemplate: this.dontSellMyPersonalInformationModalComponent.dontSellMyPersonalInformationModalActionsTemplate
      },
      restoreFocus: false
    });
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }
}
