import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { passwordValidator } from '../../../shared/directives/password/password.directive';
import { AuthService } from '../../../core/services/auth.service';
import { CoreService } from '../../../core/services/core.service';
import { AdminSetPasswordPayload } from '../../../core/types/auth.types';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  public token: string;
  public setPasswordForm = this.fb.group(
    {
      password: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', Validators.required],
    },
    {
      validators: (control) => {
        if (control.value.password !== control.value.confirmPassword) {
          control.get("confirmPassword")?.setErrors({ notSame: true });
        }
        return null;
      }
    }
  );
  public hideNewPasswordValue: boolean = true;
  public hideConfirmPasswordValue: boolean = true;
  
  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService, 
    private coreService: CoreService,
    private toastrService: ToastrService
  ) {}

  public ngOnInit(): void {
    this.route.params
      .subscribe(params => {
        this.token = params['token'];
      }
    );
  }

  public togglePasswordInput(event: MouseEvent, controlName: keyof typeof this.setPasswordForm.controls): void {
    event.stopPropagation();

    if (controlName === 'password') {
      this.hideNewPasswordValue = !this.hideNewPasswordValue;
    } else {
      this.hideConfirmPasswordValue = !this.hideConfirmPasswordValue;
    }
  }

  public getErrorMessage(controlName: keyof typeof this.setPasswordForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.setPasswordForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('notSame')) {
      errorMessage = 'Passwords must match';
    } else if (control.hasError('passwordInvalid')) {
      errorMessage = control.errors!['passwordInvalid'].join('<br />');
    }

    return errorMessage;
  }

  public handleSubmit(): void {
    if (this.setPasswordForm.valid) {
      const credentials = this.setPasswordForm.value as AdminSetPasswordPayload;

      this.coreService.setIsLoading(true);
      this.authService
        .setPassword(credentials, this.token)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe(() => {
          this.toastrService.success(
            'Password successfully set',
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
          this.router.navigate(
            ['../../login'],
            { relativeTo: this.route }
          );
        });
    }
  }
}
