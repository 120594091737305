import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxMaskModule } from 'ngx-mask';

import { EnrollmentComponent } from "./enrollment.component";
import { CreateAccountStepComponent } from "./create-account-step/create-account-step.component";
import { DebtAmountStepComponent } from "./debt-amount-step/debt-amount-step.component";
import { PersonalInformationStepComponent } from "./personal-information-step/personal-information-step.component";
import { ChoosePlanStepComponent } from "./choose-plan-step/choose-plan-step.component";
import { ReviewApplicationStepComponent } from "./review-application-step/review-application-step.component";
import { BankAccountStep } from "./bank-account-step/bank-account-step.component";
import { UploadDocumentsStepComponent } from './upload-documents-step/upload-documents-step.component';
import { SignAgreementsStepComponent } from "./sign-agreements-step/sign-agreements-step.component";
import { CongratsStepComponent } from "./congrats-step/congrats-step.component";
import { EnrollmentRoutingModule } from "./enrollment-routing.module";
import { SharedModule } from "../../shared/shared.module";
import { EmploymentInformationStepModule } from '../../shared/components/employment-information-step/employment-information-step.module';
import { EquifaxDisclosuresModalComponent } from "./personal-information-step/equifax-disclosures-modal/equifax-disclosures-modal.component";

@NgModule({
  declarations: [
    EnrollmentComponent,
    CreateAccountStepComponent,
    DebtAmountStepComponent,
    PersonalInformationStepComponent,
    EquifaxDisclosuresModalComponent,
    ChoosePlanStepComponent,
    ReviewApplicationStepComponent,
    BankAccountStep,
    UploadDocumentsStepComponent,
    SignAgreementsStepComponent,
    CongratsStepComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatSliderModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTabsModule,
    GooglePlaceModule,
    NgxMaskModule,
    EnrollmentRoutingModule,
    SharedModule,
    EmploymentInformationStepModule
  ]
})
export class EnrollmentModule {}
