import { Component } from '@angular/core';

interface QA {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {
  public qas: QA[] = [
    {
      question: 'What is a Personal Loan?',
      answer: 'Personal Loans are unsecured loans used for general purposes (primarily for personal, family, or household purposes). You can use one of our personal loans for everything from consolidating your high interest debt to updating your kitchen to complement your unique style.'
    },
    {
      question: 'How do I qualify for a personal loan?',
      answer: 'To qualify, you must be at least 18 years old and be a U.S. resident. You must also reside in a state where we do business. Besides age and location, qualification for a personal loan is largely based on your credit score, monthly income, and amount of existing debt, among other items.'
    },
    {
      question: 'How is my rate determined?',
      answer: 'Your personal loan rate is determined by your financial profile and payment method selected. Your financial profile is made up of your credit history, yearly income, debt amount, loan term, home ownership, and number of credit inquiries, among other items.'
    },
    {
      question: 'What can I use my personal loan for?',
      answer: 'We offer a variety of personal loans that you can customize to your liking. You can use one of our loans to consolidate your debt, escape to a dream vacation, purchase an engagement ring for your special someone, or get money for that new project you\'ve been brainstorming for years. You cannot use our personal loan for student loans or illegal purposes.'
    },
    {
      question: 'How much can I borrow? And for how long?',
      answer: 'Our loan programs extend from $5,000 to $75,000 and are repaid in either 12 or 60 months. Your individual situation will dictate the offers presented to you. Please note that we take into account your credit profile, your income, your "Debt to Income" ratio, and present offers which we believe you have the ability to comfortably repay. None of our programs have prepayment penalties, so you can pay off the remaining balance at any time without additional fees.'
    },
    {
      question: 'How long does it take to receive my money?',
      answer: 'Depending on your bank, and our ability to validate your information electronically, you may be able to receive Next Day Funding.'
    },
    {
      question: 'Can I re-apply for a loan if my application is denied?',
      answer: 'Of course! If your loan was denied because of circumstances that have changed (for example, you raised your credit score or your debt to income ratio changed for the better), there is a possibility your loan will be approved if you re-apply.'
    },
    {
      question: 'Will viewing my rate affect my credit?',
      answer: 'Viewing your rate with us will not impact your credit score. This is because your rate is generated using a soft inquiry instead of a hard inquiry. Employers, rental companies, and lenders may use soft inquiries as a way to verify your identity and assess any potential financial risks  —  and that\'s all a soft inquiry does. So, you don\'t have to worry about affecting your score if you\'re just "window shopping". When you select a loan to move forward with and we confirm your identity, we will do a hard pull. A hard pull of your credit report is beyond a mere inquiry and will likely impact your credit score.'
    },
    {
      question: 'Can I get a loan from you if I live outside of the United States?',
      answer: 'We do not offer personal loans to Non-U.S. residents.'
    },
    {
      question: 'Who can answer questions not addressed here?',
      answer: 'We strive to make your loan process as fast and effortless as possible and ensure any questions you have are answered right away. During our normal business hours (6:00 am - 5:00 pm PT Monday – Friday you can reach one of our Personal Loan Experts directly by calling (866) 330-1669. You can also email support@mobilend.com anytime.'
    }
  ];
}
