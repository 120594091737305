<div>
  <h1>Sign contract</h1>
  <h2>
    To finish, we need to confirm that you agree to the terms and conditions of these agreements
  </h2>
</div>
<app-agreements
  [application]="application"
  (createSignature)="handleCreateSignature($event)"
></app-agreements>
<div class="
  d-flex
  justify-content-center
  justify-content-lg-end
">
  <div class="
    d-flex
    flex-column
    flex-lg-row
    gap-4
  ">
    <app-button
      variant="outlined"
      label="BACK"
      (click)="handleBackButtonClick()"
    ></app-button>
    <app-button
      label="AGREE AND FINALIZE"
      svgIcon="semiarrow-right"
      (click)="handleAgreeAndFinalizeClick()"
      [disabled]="disableAgreeAndFinalizeButton"
    ></app-button>
  </div>
</div>