<div>
  <h1>Upload Documents</h1>
  <h2>
    Easily send us the documents below by taking a photo of them with your mobile device or uploading from your computer.
  </h2>
</div>
<app-upload-documents
  [documentsToBeRendered]="documentsToBeUploaded"
  (onFileUpload)="handleFileAction($event)"
  (onFileDelete)="handleFileAction($event)"
  (onFileDownload)="handleDownloadFile($event)"
></app-upload-documents>
<div class="
  d-flex
  justify-content-center
  justify-content-lg-end
">
  <div class="
    d-flex
    flex-column
    flex-sm-row
    flex-md-column
    flex-lg-row
    gap-4
  ">
    <app-button
      variant="outlined"
      label="BACK"
      (click)="handleBackButtonClick()"
    ></app-button>
    <app-button
      label="CONTINUE"
      svgIcon="semiarrow-right"
      (click)="handleContinueButtonClick()"
      [disabled]="disableContinueButton"
    ></app-button>
  </div>
</div>