import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgxFileDropModule } from 'ngx-file-drop';

import { UploadDocumentsComponent } from './upload-documents.component';

@NgModule({
  declarations: [UploadDocumentsComponent],
  imports: [CommonModule, MatExpansionModule, MatIconModule, NgxFileDropModule],
  exports: [UploadDocumentsComponent]
})
export class UploadDocumentsModule {}
