import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Application } from '../../core/types/application.types';
import { Partners } from '../../core/types/offer.types';
import { CoreService } from 'src/app/core/services/core.service';
import { CreateAccountStepComponent } from './create-account-step/create-account-step.component';
import { DebtAmountStepComponent } from './debt-amount-step/debt-amount-step.component';
import { PersonalInformationStepComponent } from './personal-information-step/personal-information-step.component';
import { ChoosePlanStepComponent } from './choose-plan-step/choose-plan-step.component';
import { ReviewApplicationStepComponent } from './review-application-step/review-application-step.component';
import { BankAccountStep } from './bank-account-step/bank-account-step.component';
import { UploadDocumentsStepComponent } from './upload-documents-step/upload-documents-step.component';
import { SignAgreementsStepComponent } from './sign-agreements-step/sign-agreements-step.component';
import { CongratsStepComponent } from './congrats-step/congrats-step.component';
import { EmploymentInformationStepComponent } from '../../shared/components/employment-information-step/employment-information-step.component';

export interface StepperAction {
  action: Actions;
  data?: {
    selectedAmount?: number;
    application?: Application;
  };
}
interface EnrollmentFlowStorageData {
  application?: Application;
  currentStepNumber?: number;
  selectedAmount?: number;
  steps?: Step[];
}
interface Step {
  completed: boolean;
  component: {
    class: Type<any>;
    props: {
      [key: string]: any;
    }
  };
  label: string;
  number: number;
}
export enum Actions {
  Next = 'next',
  Back = 'back',
  Finish = 'finish'
}

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements AfterContentInit {
  @ViewChild('stepContentContainer', { static: true, read: ViewContainerRef })
  public stepContentContainer: ViewContainerRef;
  public steps: Step[] = [
    {
      completed: false,
      component: {
        class: CreateAccountStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Create account',
      number: 1,
    },
    {
      completed: false,
      component: {
        class: DebtAmountStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Debt amount',
      number: 2
    },
    {
      completed: false,
      component: {
        class: PersonalInformationStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Personal information',
      number: 3,
    },
    {
      completed: false,
      component: {
        class: EmploymentInformationStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Employment information',
      number: 4,
    },
    {
      completed: false,
      component: {
        class: ChoosePlanStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Choose offer',
      number: 5
    },
    {
      completed: false,
      component: {
        class: ReviewApplicationStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Review application',
      number: 6
    },
    {
      completed: false,
      component: {
        class: BankAccountStep,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Connect bank',
      number: 7
    },
    {
      completed: false,
      component: {
        class: UploadDocumentsStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Upload documents',
      number: 8
    },
    {
      completed: false,
      component: {
        class: SignAgreementsStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Sign contract',
      number: 9
    },
    {
      completed: false,
      component: {
        class: CongratsStepComponent,
        props: {
          onAction: this.handleAction
        }
      },
      label: 'Congrats',
      number: 10
    }
  ];
  public currentStepNumber: number = 1;
  public selectedAmount: number = 0;
  public congratsStepNumber: number = 10;
  public application?: Application;
  private previousStepNumber?: number;

  constructor(private coreService: CoreService, private el: ElementRef) {}

  public get numberOfCompletedSteps(): number {
    return this.steps.filter((step) => step.completed).length;
  }

  public get progress(): number {
    const completedSteps = this.steps.filter((step) => step.completed);
    const progress = Math.floor((completedSteps.length / this.steps.length) * 100);

    return this.steps.length === this.currentStepNumber ? 100 : progress;
  }

  public ngAfterContentInit(): void {
    this.getStorageData();
    this.createCurrentStepComponent();
  }

  public handleAction(stepperAction: StepperAction): void {
    if (stepperAction.action === Actions.Finish) {
      this.currentStepNumber = 1;
      this.previousStepNumber = undefined;
    } else {
      this.setCurrentStepNumber(stepperAction);

      if (stepperAction.action === Actions.Next) {
        if (stepperAction.data?.selectedAmount) {
          this.selectedAmount = stepperAction.data!.selectedAmount;
        } else if (stepperAction.data?.application) {
          this.application = stepperAction.data!.application;
        }
      }
      
      this.updateStepsData(stepperAction.action);
      this.moveSteps();
      this.createCurrentStepComponent();
      this.coreService.setSessionStorageData<EnrollmentFlowStorageData>(
        'enrollment',
        { currentStepNumber: this.currentStepNumber, steps: this.steps }
      );

      if (stepperAction.data) {
        this.coreService.setSessionStorageData<EnrollmentFlowStorageData>(
          'enrollment',
          { ...stepperAction.data }
        )
      }
      
      window.scrollTo(0, 0);
    }
  }

  private getStorageData(): void {
    const storage = this.coreService.getSessionStorageData<EnrollmentFlowStorageData>('enrollment');
    
    if (storage) {
      this.currentStepNumber = storage.currentStepNumber || 1;
      this.selectedAmount = storage.selectedAmount || 0;
      this.application = storage.application;
      this.steps = this.steps.map((step) => {
        const stepInStorage = storage.steps!.find((storageStep) => storageStep.number === step.number)!;

        return {
          ...step,
          completed: stepInStorage.completed,
          component: {
            class: step.component.class,
            props: {
              ...stepInStorage.component.props,
              onAction: step.component.props['onAction']
            }
          }
        };
      });
    }
  }

  private createCurrentStepComponent(): void {
    this.stepContentContainer.clear();

    const componentToBeCreated = this.steps.find((step) => step.number === this.currentStepNumber)!.component;
    const componentRef = this.stepContentContainer.createComponent(componentToBeCreated.class);

    if (componentToBeCreated.props) {
      Object.keys(componentToBeCreated.props).forEach((key) => {
        if (componentRef.instance[key] instanceof EventEmitter) {
          const outputProp = componentRef.instance[key] as EventEmitter<any>;

          outputProp.subscribe((event: StepperAction) => {
            componentToBeCreated.props![key].call(this, event);
          });
        } else {
          componentRef.instance[key] = componentToBeCreated.props![key];
        }
      });
    }
  }

  private setCurrentStepNumber(stepperAction: StepperAction): void {
    this.previousStepNumber = this.currentStepNumber;

    if (stepperAction.action === Actions.Next) {
      const choosePlanStepNumber = 5;
      const reviewApplicationStep = 6;
      const bankAccountStep = 7;

      if ([choosePlanStepNumber, reviewApplicationStep, bankAccountStep].includes(this.currentStepNumber)) {
        if (this.currentStepNumber === choosePlanStepNumber) {
          this.handleChoosePlanStep(stepperAction);
        } else if (this.currentStepNumber === reviewApplicationStep) {
          this.handleReviewApplicationStep(stepperAction);
        } else {
          this.handleBankAccountStep(stepperAction);
        }
      } else {
        this.currentStepNumber++;
      }
    } else {
      this.currentStepNumber--;
    }
  }

  private handleChoosePlanStep(stepperAction: StepperAction): void {
    const stepperActionData = stepperAction.data!.application as Application;
    const selectedOffer = stepperActionData.offers?.find((offer) => offer.selected);

    if (!selectedOffer) { // Chosen offer is Supermoney
      this.currentStepNumber = this.congratsStepNumber;
    } else {
      this.currentStepNumber++;
    }
  }

  private handleReviewApplicationStep(stepperAction: StepperAction): void {
    const stepperActionData = stepperAction.data!.application as Application;
    const selectedOffer = stepperActionData.offers?.find((offer) => offer.selected)!;
    const bankAccountStepNumber = 7;

    if (selectedOffer.partner === Partners.Achieve || selectedOffer.partner === Partners.Monevo) {
      this.currentStepNumber = this.congratsStepNumber;
    } else {
      this.currentStepNumber = bankAccountStepNumber;
    }
  }

  private handleBankAccountStep(stepperAction: StepperAction): void {
    const stepperActionData = stepperAction.data!.application as Application;
    const selectedOffer = stepperActionData.offers?.find((offer) => offer.selected)!;
    const uploadDocumentsStepNumber = 8;

    if (selectedOffer.partner === Partners.FreedomFinancial) {
      this.currentStepNumber = this.congratsStepNumber;
    } else {
      this.currentStepNumber = uploadDocumentsStepNumber;
    }
  }

  private updateStepsData(stepperAction: Actions): void {
    const currentStep = this.steps.find((step) => step.number === this.currentStepNumber)!;

    currentStep.component.props = {
      ...currentStep.component.props,
      selectedAmount: this.selectedAmount,
      application: this.application
    };

    if (stepperAction === Actions.Next) {
      const previousStep = this.steps.find((step) => step.number === this.previousStepNumber)!;

      previousStep.completed = true;
    }

    if (currentStep.number === this.congratsStepNumber) {
      currentStep.completed = true;
    }
  }

  private moveSteps(): void {
    const componentElement = this.el.nativeElement as HTMLElement;

    if (componentElement.clientWidth <= 767) {
      const stepsElement = componentElement.querySelector('.steps')! as HTMLElement;
      const stepsElementFlexGapInPx = 6;
      const translateXPxValue = (stepsElement.clientWidth + stepsElementFlexGapInPx) * (this.currentStepNumber - 1);
      const translateXValue = `-${translateXPxValue}px`;

      stepsElement.style.setProperty('transform', `translateX(${translateXValue})`);
    }
  }
}
