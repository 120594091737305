import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dont-sell-my-personal-information-modal',
  templateUrl: 'dont-sell-my-personal-information-modal.component.html'
})
export class DontSellMyPersonalInformationModalComponent implements OnInit {
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @ViewChild('dontSellMyPersonalInformationModalContentTemplate')
  public dontSellMyPersonalInformationModalContentTemplate: TemplateRef<any>;
  @ViewChild('dontSellMyPersonalInformationModalActionsTemplate')
  public dontSellMyPersonalInformationModalActionsTemplate: TemplateRef<any>;
  public dontSellMyPersonalInformation = new FormControl(false);

  public ngOnInit(): void {
    this.setDontSellMyPersonalInformationInputInitialValue();
  }

  public handleConfirmButtonClick(): void {
    localStorage.setItem('dsmpiconsent', String(Boolean(this.dontSellMyPersonalInformation.value)));

    this.cancel.emit();
  }

  public handleCancelButtonClick(): void {
    this.cancel.emit();
  }

  private setDontSellMyPersonalInformationInputInitialValue(): void {
    const dontSellMyPersonalInformationConsent = localStorage.getItem('dsmpiconsent');

    if (dontSellMyPersonalInformationConsent) {
      const initialValue = dontSellMyPersonalInformationConsent === 'true';

      this.dontSellMyPersonalInformation.setValue(initialValue);
    }
  }
}
