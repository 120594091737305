<h1>Your account is successfully created!</h1>
<div>
  <ng-container *ngIf="selectedOffer?.partner === 'Mobilend'">
    <p>
      Thank you for completing your application and submitting the requested documents!
      <br />
      We are already reviewing the information that you provided and will be sending you an email shortly with our decision on your application. Typically, this takes less than 1 hour but can sometimes take slightly longer depending on volume.
    </p>
    <p>
      Be sure sure to also check your spam folder to make sure you receive our email. If approved, your funds will be provided to you via the bank account you provided on your application.
    </p>
  </ng-container>
  <ng-container *ngIf="!selectedOffer || selectedOffer.partner === 'Achieve' || selectedOffer.partner === 'Monevo'">
    <p>
      Thank you for completing your application!
      <br />
      Our lending partner will be reaching out to you shortly.
    </p>
  </ng-container>
</div>
<div class="d-flex justify-content-center justify-content-md-end">
  <app-button
    label="BACK TO HOME"
    svgIcon="semiarrow-right"
    (click)="handleBackToHomeButtonClick()"
  ></app-button>
</div>