import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LegalCenterMainComponent } from './legal-center-main/legal-center-main.component';
import { LegalCenterComponent } from './legal-center.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CommunicationConsentAgreementComponent } from './communication-consent-agreement/communication-consent-agreement.component';
import { FinancialEducationResourceCenterComponent } from './financial-education-resource-center/financial-education-resource-center.component';
// import { UsuryDislosureComponent } from './usury-disclosure/usury-disclosure.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LegalCenterMainComponent
  },
  {
    path: '',
    component: LegalCenterComponent,
    children: [
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'communication-consent-agreement',
        component: CommunicationConsentAgreementComponent
      },
      {
        path: 'financial-education-resource-center',
        component: FinancialEducationResourceCenterComponent
      },
      // {
      //   path: 'usury-disclosure',
      //   component: UsuryDislosureComponent
      // }
    ]
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class LegalCenterRoutingModule {}
