<div>
  <h1>Employment information</h1>
</div>
<form
  class="mt-5"
  [formGroup]="employmentInformationForm"
  (submit)="handleFormSubmit()"
>
  <div class="employment-information-inputs mb-5">
    <!-- <h2>See if you're approved in a few easy steps</h2> -->
    <div class="row">
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Income Type*</mat-label>
          <mat-select formControlName="incomeType">
            <mat-option *ngFor="let option of incomeTypeInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('incomeType', 'Income Type') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Other Income</mat-label>
          <mat-select formControlName="otherIncome">
            <mat-option *ngFor="let option of otherIncomeInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Employer Status*</mat-label>
          <mat-select formControlName="employerStatus">
            <mat-option *ngFor="let option of employerStatusInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('employerStatus', 'Employer Status') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Payment Frequency*</mat-label>
          <mat-select formControlName="paymentFrequency">
            <mat-option *ngFor="let option of paymentFrequencyInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('paymentFrequency', 'Payment Frequency') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Holiday Payments</mat-label>
          <mat-select formControlName="holidayPayments">
            <mat-option *ngFor="let option of holidayPaymentsInputOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ getErrorMessage('holidayPayments', 'Holiday Payments') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Employer Name*</mat-label>
          <input matInput formControlName="employerName" />
          <mat-error>
            {{ getErrorMessage('employerName', 'Employer Name') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Months At Employer*</mat-label>
          <input
            type="number"
            matInput
            formControlName="monthsAtEmployer"
          />
          <mat-error>
            {{ getErrorMessage('monthsAtEmployer', 'Months At Employer') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6 col-md-12 col-lg-6">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Work Phone</mat-label>
          <input matInput formControlName="workPhone" mask="(000) 000-0000" />
          <mat-error>
            {{ getErrorMessage('workPhone', 'Work Phone') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="employment-information-checkboxes-container">
      <div class="d-flex align-items-start">
        <mat-checkbox
          formControlName="tcpaConsent"
          color="primary"
          [ngClass]="{ 'mat-checkbox-invalid': isCheckboxInvalid('tcpaConsent') }"
        ></mat-checkbox>
        <div>
          <p>
            By clicking “Continue”, I give my express written consent for Kuber Financial LLC, dba Mobilend 
            and/or affiliates, such as <a class="text-reset text-decoration-underline" href="https://app.monevo.us/partners" target="_blank">Movevo Inc</a>. or others. I am matched with to call, text or email me with
            marketing offers or other messages regarding the products and services about which I have inquired at the phone
            number I have provided using an automated dialing system, artificial or prerecorded voice or otherwise. I
            understand that I may receive calls at the phone number provided even if my telephone number is currently listed on
            any internal, corporate, state, federal or national do-not-call (DNC) list. I understand that my consent is not a
            condition of purchase. If you would rather receive personal loan information via a phone call, please call (866) 330-1669
          </p>
          <mat-hint *ngIf="isCheckboxInvalid('tcpaConsent')">
            You must accept to continue
          </mat-hint>
        </div>
      </div>
      <div class="d-flex align-items-start">
        <mat-checkbox
          formControlName="electronicConsent"
          color="primary"
          [ngClass]="{ 'mat-checkbox-invalid': isCheckboxInvalid('electronicConsent') }"
        ></mat-checkbox>
        <div>
          <p>
            I have read and acknowledge receipt of Kuber Financial, LLC dba Mobilend <a routerLink="/legal-center/privacy-policy" target="_blank" fragment="electronic-records-statement">Statement on the Use of Electronic Records</a> via the link provided, and hereby agree to receive the loan agreement, all legally required disclosures, notices (including the <a routerLink="/legal-center/privacy-policy" target="_blank">Privacy Policy</a>), and other information related to this loan transaction in electronic form, and to use electronic signatures to sign any loan agreement.
          </p>
          <mat-hint *ngIf="isCheckboxInvalid('electronicConsent')">
            You must accept to continue
          </mat-hint>
        </div>
      </div>
    </div>
  </div>
  <div class="
    d-flex
    justify-content-center
    justify-content-lg-end
  ">
    <div class="
      d-flex
      flex-column
      flex-sm-row
      flex-md-column
      flex-lg-row
      gap-4
    ">
      <app-button
        variant="outlined"
        label="BACK"
        (click)="handleBackButtonClick()"
      ></app-button>
      <app-button
        type="submit"
        label="CONTINUE"
        svgIcon="semiarrow-right"
      ></app-button>
    </div>
  </div>
</form>