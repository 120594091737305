<div class="bg-purple-100">
  <app-header variant="light"></app-header>
</div>
<div class="
  flex-md-row
  flex-column
  enrollment-content
">
  <div class="left-side">
    <span class="step-title">
      Step {{ currentStepNumber | number: '2.' }}
    </span>
    <div class="enrollment-progress-container">
      <span class="text-grey-500 fw-bold">
        Completed steps {{ numberOfCompletedSteps }}/{{ steps.length }}
      </span>
      <div class="enrollment-progress">
        <div
          class="progress"
          role="progressbar"
          aria-label="Enrollment progress"
          aria-valuemin="0"
          aria-valuemax="100"
          [attr.aria-valuenow]="progress"
        >
          <div class="progress-bar bg-cyan-100" [ngStyle]="{ 'width': progress + '%' }"></div>
        </div>
        <span class="fw-semibold">
          {{ progress }}%
        </span>
      </div>
    </div>
    <div class="steps-container">
      <div class="steps">
        <div
          *ngFor="let step of steps"
          [ngClass]="{
            'step': true,
            'active-step': step.number === currentStepNumber,
            'completed-step': step.completed && step.number !== currentStepNumber
          }"
          class="step"
        >
          <div class="step-number">
            {{ step.number | number: '2.' }}
          </div>
          <div class="step-label-status-container">
            <div [ngClass]="{
              'step-label': true,
              'skipped-step': !step.completed && currentStepNumber === congratsStepNumber
            }">
              {{ step.label }}
            </div>
            <div class="step-status">
              <mat-icon *ngIf="step.completed">
                check_icon
              </mat-icon>
              <mat-icon *ngIf="!step.completed && currentStepNumber === congratsStepNumber">
                clear_icon
              </mat-icon>
              <mat-icon *ngIf="!step.completed && currentStepNumber === step.number">
                panorama_fish_eye
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-side">
    <div class="enrollment-right-side-image-container">
      <img src="../../../assets/images/enrollment.png" alt="icons" />
    </div>
    <div class="step-content-container">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>Mobilend</span>
      </div>
      <ng-template #stepContentContainer></ng-template>
    </div>
  </div>
</div>
<app-footer></app-footer>