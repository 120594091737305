import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule } from 'ngx-mask';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AgreementsTabComponent } from './agreements-tab/agreements-tab.component';
import { BankInformationTabComponent } from './bank-information-tab/bank-information-tab.component';
import { BRFTabComponent } from './brf-tab/brf-tab.component';
import { CommentsTabComponent } from './comments-tab/comments-tab.component';
import { CreditReportTabComponent } from './credit-report-tab/credit-report-tab.component';
import { DocumentsTabComponent } from './documents-tab/documents-tab.component';
import { EmailCommunicationsTabComponent } from './email-communications-tab/email-communications-tab.component';
import { EmploymentInformationTabComponent } from './employment-information-tab/employment-information-tab.component';
import { ExpansionPanelsComponent } from './expansion-panels/expansion-panels.component';
import { LogsTabComponent } from './logs-tab/logs-tab.component';
import { OffersTabComponent } from './offers-tab/offers-tab.component';
import { SelectOfferModalComponent } from './offers-tab/select-offer-modal/select-offer-modal.component';
import { PaymentManagementTabComponent } from './payment-management-tab/payment-management-tab.component';
import { ConfirmPayoffModalComponent } from './payment-management-tab/confirm-payoff-modal/confirm-payoff-modal.component';
import { UnderwritingRulesTabComponent } from './underwriting-rules-tab/underwriting-rules-tab.component';
import { UserInformationTabComponent } from './user-information-tab/user-information-tab.component';
import { ApplicationDetailsComponent } from './application-details.component';
import { AgreementsModule } from '../agreements/agreements.module';
import { CardModule } from '../card/card.module';
import { UploadDocumentsModule } from '../upload-documents/upload-documents.module';
import { TableModule } from '../table/table.module';
import { HasRoleModule } from '../../directives/role/has-role.module';
import { SupermoneyOffersModule } from '../supermoney-offers/supermoney-offers.module';
import { IntegrationsTabComponent } from './integrations-tab/integrations-tab.component';
import { WarningModule } from '../warning/warning.module';
import { ReschedulePaymentModalComponent } from './payment-management-tab/reschedule-payment-modal/reschedule-payment-modal.component';
import { DateInputModule } from '../date-input/date-input.module';
import { ConfirmFundingModalComponent } from './confirm-funding-modal/confirm-funding-modal.component';

@NgModule({
  declarations: [
    AgreementsTabComponent,
    BankInformationTabComponent,
    BRFTabComponent,
    CommentsTabComponent,
    CreditReportTabComponent,
    DocumentsTabComponent,
    EmailCommunicationsTabComponent,
    EmploymentInformationTabComponent,
    ExpansionPanelsComponent,
    LogsTabComponent,
    OffersTabComponent,
    SelectOfferModalComponent,
    IntegrationsTabComponent,
    PaymentManagementTabComponent,
    ConfirmPayoffModalComponent,
    ReschedulePaymentModalComponent,
    UnderwritingRulesTabComponent,
    UserInformationTabComponent,
    ApplicationDetailsComponent,
    ConfirmFundingModalComponent
  ],
  exports: [ApplicationDetailsComponent],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    NgxMaskModule,
    CommonModule,
    MatIconModule,
    WarningModule,
    MatExpansionModule,
    MatTooltipModule,
    AgreementsModule,
    CardModule,
    UploadDocumentsModule,
    TableModule,
    HasRoleModule,
    SupermoneyOffersModule,
    DateInputModule
  ]
})
export class ApplicationDetailsModule {}
