import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { Application } from '../../../../core/types/application.types';
import { Offer } from '../../../../core/types/offer.types';
import { BankAccount } from '../../../../core/types/bank-account.types';
import { Amortization } from 'src/app/core/types/payment-setting.types';

@Component({
  selector: 'app-loan-agreement',
  templateUrl: './loan-agreement.component.html',
  styleUrls: ['./loan-agreement.component.scss']
})
export class LoanAgreementComponent implements OnInit {
  @Input() public application: Application;
  @Input() public selectedOffer: Offer;
  @Input() public bankAccount: BankAccount;
  @Input() public signature?: SafeResourceUrl;
  @Input() public signaturesMap: Map<string, boolean>;
  @Output() public onSign: EventEmitter<string> = new EventEmitter();
  public initialAmortizations: Amortization[] = [];
  public signedAt?: string;
  public today: number = Date.now();
  public userIpAddress: string;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setExistingSignatureDate();
    this.setInitialAmortizations();
  }

  public get formattedUserAddress(): string {
    const { street, city, stateCode, zipCode } = this.application.customer;

    return `${street}, ${city}, ${stateCode}, ${zipCode}`;
  }

  public get paymentBeginningDate(): Date {
    return new Date(this.application.paymentSetting!.amortizations![0].paymentDate);
  }

  public get originationFee(): number {
    return this.selectedOffer?.fee && this.selectedOffer.amount ? this.selectedOffer.amount * (this.selectedOffer.fee / 100) : 0;
  }

  public async handleSignButtonClick(signatureIdentifier: string): Promise<void> {
    this.onSign.emit(signatureIdentifier);
  }

  public setExistingSignatureDate(): void {
    const loanAgreement = this.application.contractSignatures?.find(
      (signature) => signature.agreement === 'loan' && signature.status !== 'rejected'
    );

    if (loanAgreement) {
      this.signedAt = loanAgreement.signedAt;
    }
  }

  public setInitialAmortizations(): void {
    this.application.paymentSetting!.amortizations.forEach((amortization) => {
      const existingAmortizationForMonth = this.initialAmortizations.find((initialAmortization) => initialAmortization.month === amortization.month);

      if (!existingAmortizationForMonth && !amortization.earlyPayoff) {
        this.initialAmortizations.push(amortization);
      }
    });
  }
}
