import { NgModule } from '@angular/core';

import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { HomeModule } from './home/home.module';
import { AboutUsModule } from './about-us/about-us.module';
import { WhatWeDoModule } from './what-we-do/what-we-do.module';
import { WhyMobilendModule } from './why-mobilend/why-mobilend.module';
import { OurServicesModule } from './our-services/our-services.module';
import { HowItWorksModule } from './how-it-works/how-it-works.module';
import { EnrollmentModule } from './enrollment/enrollment.module';
import { AuthModule } from './auth/auth.module';
import { LegalCenterModule } from './legal-center/legal-center.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { HelpCenterModule } from './help-center/help-center.module';

@NgModule({
  imports: [
    PageNotFoundModule,
    HomeModule,
    AboutUsModule,
    WhatWeDoModule,
    WhyMobilendModule,
    OurServicesModule,
    HowItWorksModule,
    EnrollmentModule,
    AuthModule,
    LegalCenterModule,
    ContactUsModule,
    HelpCenterModule
  ]
})
export class FeaturesModule {}
