<div>
  <h1>Financial Education Resource Center</h1>
  <h2>
    We are here with you all the way on your financial journey. We collected a few financial education resources for you
  </h2>
</div>
<div class="row gy-2">
  <div class="col-md-4">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>Welcome to Mobilend.com</span>
    </div>
  </div>
  <div class="col-md-8">
    <div class="d-flex flex-column gap-4">
      <div class="resource-container">
        <img src="../../../../assets/logos/fdic-logo.png" alt="fdic logo" />
        <a href="https://www.fdic.gov/resources/consumers/consumer-assistance-topics/loans.html">
          https://www.fdic.gov/resources/consumers/consumer-assistance-topics/loans.html
        </a>
        <p>
          There are many types of loans, such as student loans, vehicle loans, and business loans. Consumer protections may vary by loan type. FDIC provides more information on common loans and tips to consider when borrowing money for certain purposes.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/flec-logo.png" alt="flec logo" />
        <a href="https://www.mymoney.gov/borrow">
          https://www.mymoney.gov/borrow
        </a>
        <p>
          Sometimes it’s necessary to borrow for major purchases like an education , a car, a house, or maybe even to meet unexpected expenses. Your ability to get a loan generally depends on your credit history, and that depends largely on your track record at repaying what you’ve borrowed in the past and paying your bills on time.  So, be careful to keep your credit history strong.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/ftc-logo.png" alt="ftc logo" />
        <a href="https://consumer.gov/section/credit-loans-debt">
          https://consumer.gov/section/credit-loans-debt
        </a>
        <p>
          Consumer.gov is a FDIC resource center that helps you understand how to manage your money, different types of credit and loan products as well as familiarize yourself on how to prevent identity theft.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/cfpb-logo.png" alt="cfpb logo" />
        <a href="https://www.consumerfinance.gov/ask-cfpb/what-is-a-personal-installment-loan-en-2114">
          https://www.consumerfinance.gov/ask-cfpb/what-is-a-personal-installment-loan-en-2114
        </a>
        <p>
          Consumer Financial Production Bureau launched a website with frequent updates on understanding all aspects of a personal installment loan, including how to apply for a loan and ways to make on time payemnts.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/cfpb-logo.png" alt="cfpb logo" />
        <a href="https://www.consumerfinance.gov/ask-cfpb/what-do-i-need-to-know-if-im-thinking-about-consolidating-my-credit-card-debt-en-1861">
          https://www.consumerfinance.gov/ask-cfpb/what-do-i-need-to-know-if-im-thinking-about-consolidating-my-credit-card-debt-en-1861
        </a>
        <p>
          Consumer Financial Protection Bureau has information about how to consolidate your credit card debt. It's frequently updated with the latest information if you fall behind with your credit card payments.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/ftc-logo.png" alt="ftc logo" />
        <a href="https://consumer.ftc.gov/articles/how-get-out-debt">
          https://consumer.ftc.gov/articles/how-get-out-debt
        </a>
        <p>
          The FTC or the Federal Trade Commission has a website setup to educate consumers on understanding debt. If you’re worried about how to get out of debt, here are some things to know — and how to find legitimate help.
        </p>
      </div>
      <div class="resource-container">
        <img src="../../../../assets/logos/cfpb-logo.png" alt="cfpb logo" />
        <a href="https://www.consumerfinance.gov/consumer-tools/educator-tools/adult-financial-education/tools-and-resources/">
          https://www.consumerfinance.gov/consumer-tools/educator-tools/adult-financial-education/tools-and-resources/
        </a>
        <p>
          CFPB or the Consumer Finance Protection Bureau has gathered tools, worksheets, handouts, and audio recordings by topic and by audience on Financial Literacy. You can use these resources by clicking through the link above.
        </p>
      </div>
    </div>
  </div>
</div>