import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { CreateUploadedDocument, SignedUrl, UploadedDocument } from '../types/uploaded-document.types';
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class UploadedDocumentService {
  private baseUrl = `${environment.apiUrl}/applications`;

  constructor(private http: HttpClient) {}

  public createUploadedDocuments(
    applicationId: number,
    createUploadedDocuments: CreateUploadedDocument[],
    signatureId?: number,
    token?: string
  ): Observable<UploadedDocument[]> {
    const formData = new FormData();

    createUploadedDocuments.forEach((createUploadedDocument) => {
      createUploadedDocument.files.forEach((file) => {
        formData.append(createUploadedDocument.documentType, file);
      });
    });

    if (signatureId) {
      formData.append('signatureId', String(signatureId));
    }

    return this.http.post<UploadedDocument[]>(
      `${this.baseUrl}/${applicationId}/media/documents`,
      formData,
      {
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public getByApplicationId(applicationId: number): Observable<UploadedDocument[]> {
    return this.http.get<UploadedDocument[]>(
      `${this.baseUrl}/${applicationId}/media/documents`,
      { withCredentials: true }
    );
  }

  public getDocumentSignedUrl(applicationId: number, documentId: number): Observable<SignedUrl> {
    return this.http.get<SignedUrl>(
      `${this.baseUrl}/${applicationId}/media/documents/${documentId}/url`,
      { withCredentials: true }
    );
  }

  public delete(applicationId: number, documentId: number): Observable<UploadedDocument> {
    return this.http.delete<UploadedDocument>(
      `${this.baseUrl}/${applicationId}/media/documents/${documentId}`,
      { withCredentials: true }
    );
  }
}
