import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';

import { Application } from "../../../core/types/application.types";
import { Offer } from "../../../core/types/offer.types";
import { StepperAction, Actions } from '../enrollment.component';
import { Partners } from "../../../core/types/offer.types";;
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { CoreService } from '../../../core/services/core.service';
import { OfferService } from '../../../core/services/offer.service';
import { Router } from "@angular/router";

type SelectableOffer = Offer & { iconPath: string; number: number; }

@Component({
  selector: 'app-choose-plan-step',
  templateUrl: './choose-plan-step.component.html',
  styleUrls: ['./choose-plan-step.component.scss']
})
export class ChoosePlanStepComponent implements OnInit {
  @Input() public application: Application;
	@Output() public onAction = new EventEmitter<StepperAction>();
	@ViewChild('continueButton', { read: ElementRef })
	public continueButtonRef: ElementRef<HTMLElement>;
	@ViewChild('confirmRedirectingModalContentTemplate')
  public confirmRedirectingModalContentTemplate: TemplateRef<any>;
  @ViewChild('confirmRedirectingModalActionsTemplate')
  public confirmRedirectingModalActionsTemplate: TemplateRef<any>;
	@ViewChild('showDisclosuresModalContentTemplate')
  public showDisclosuresModalContentTemplate: TemplateRef<any>;
  @ViewChild('showDisclosuresModalActionsTemplate')
  public showDisclosuresModalActionsTemplate: TemplateRef<any>;
	@ViewChild('showDisclaimerModalContentTemplate')
  public showDisclaimerModalContentTemplate: TemplateRef<any>;
  @ViewChild('showDisclaimerModalActionsTemplate')
  public showDisclaimerModalActionsTemplate: TemplateRef<any>;
	@ViewChild('confirmResetApplicationModalContentTemplate')
  public confirmResetApplicationModalContentTemplate: TemplateRef<any>;
  @ViewChild('confirmResetApplicationModalActionsTemplate')
  public confirmResetApplicationModalActionsTemplate: TemplateRef<any>;
  public selectedOffer?: Partial<Offer>;
  public selectableOffers: SelectableOffer[] = [];
  public modalRef?: MatDialogRef<ModalComponent>;
  public showDisclosures: boolean = false;
	private lendersLogosMap: Map<Partners, string> = new Map<Partners, string>([
		[Partners.FreedomFinancial, 'freedom-financial-logo.svg'],
		[Partners.Mobilend, 'mobilend-dark-logo.png'],
		[Partners.Achieve, 'achieve-logo.svg']
	]);

  constructor(
		private dialog: MatDialog, 
		private coreService: CoreService, 
		private offerService: OfferService,
		private router: Router,
	) {}

  public ngOnInit(): void {
		this.selectedOffer = this.application.offers?.find((offer) => offer.selected);

    this.addNumberToOffers();
  }

  public handleSelectOfferButtonClick(offer: Offer): void {
		if (offer.partner === Partners.Achieve || offer.partner === Partners.Monevo) {
			this.modalRef = this.dialog.open(ModalComponent, {
				autoFocus: 'dialog',
				data: {
					contentTemplate: this.confirmRedirectingModalContentTemplate,
					actionsTemplate: this.confirmRedirectingModalActionsTemplate,
					context: { offer, continueButtonRef: this.continueButtonRef }
				},
				restoreFocus: false
			});
		} else {
			this.updateSelectedOffer(offer);
			this.moveToNextStep();
		}
  }

	public handleSupermoneyContinueButtonClick(): void {
		this.coreService.setIsLoading(true);
		
		const storage = this.coreService.getSessionStorageData<{ token: string }>('token');

		this.offerService
			.selectSupermoneyOffer(this.application.id, storage?.token)
			.subscribe(() => {
				this.updateSelectedOffer({ partner: Partners.Supermoney });
				this.coreService.setIsLoading(false);
				this.moveToNextStep();
			});
	};

	public handleMonevoOfferDisclosuresClick(offer: Offer): void {
		this.modalRef = this.dialog.open(ModalComponent, {
			autoFocus: 'dialog',
			data: {
				contentTemplate: this.showDisclosuresModalContentTemplate,
				actionsTemplate: this.showDisclosuresModalActionsTemplate,
				context: { disclosures: offer.disclosures }
			},
			restoreFocus: false
		});
	}

	public handleMonevoOfferDisclaimerClick(offer: Offer): void {
		this.modalRef = this.dialog.open(ModalComponent, {
			autoFocus: 'dialog',
			data: {
				contentTemplate: this.showDisclaimerModalContentTemplate,
				actionsTemplate: this.showDisclaimerModalActionsTemplate,
			},
			restoreFocus: false
		});
	}

	public handleBackButtonClick(): void {
		this.modalRef = this.dialog.open(ModalComponent, {
			autoFocus: 'dialog',
			data: {
				contentTemplate: this.confirmResetApplicationModalContentTemplate,
				actionsTemplate: this.confirmResetApplicationModalActionsTemplate,
			},
			restoreFocus: false
		});
	}

	public handleContinueButtonClick(): void {
		if (this.selectedOffer!.partner === Partners.Supermoney) {
			const storage = this.coreService.getSessionStorageData<{ token: string }>('token');

			this.coreService.setIsLoading(true);
			this.offerService
				.selectSupermoneyOffer(this.application.id, storage?.token)
				.pipe(
					finalize(() => {
						this.coreService.setIsLoading(false);
					})
				)
				.subscribe(() => {
					this.moveToNextStep();
				});
		} else {
			this.moveToNextStep();
		}
	}

	public handleModalConfirmButtonClick(context: { offer: Offer, continueButtonRef: any }): void {
    window.open(context.offer.externalLink!, '_blank');
		this.updateSelectedOffer(context.offer);
    this.closeModal();
		this.moveToNextStep();
  }

	public handleResetModalConfirmButtonClick(): void {
		this.coreService.clearSessionStorage('enrollment');
		this.coreService.clearSessionStorage('token');

		this.router.navigate(['/home']);
    this.closeModal();
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 200);
  }

  public closeModal(): void {
    this.modalRef!.close();

    this.modalRef = undefined;
  }

  private addNumberToOffers(): void {
    const offers: SelectableOffer[] = [];

    this.application.offers
			?.sort((a, b) => {
				if (a.partner === b.partner) {
					return a.term > b.term ? -1 : 1;
				} else if (a.partner === Partners.Mobilend) {
					return -1;
				}

				return 0;
			})
			.forEach((offer) => {
				let iconPath = `../../../../assets/logos/${this.lendersLogosMap.get(offer.partner)}`;

				if (offer.partner === Partners.Monevo) {
					iconPath = offer.lenderLogo!;
				}

				offers.push({
					...offer,
					iconPath,
					number: offers.length + 1
				});
			});

		this.selectableOffers = [...offers];
  }

	private moveToNextStep(): void {
		this.onAction.emit({
			action: Actions.Next,
			data: { application: this.application }
		});
	}

	private updateSelectedOffer(selectedOffer: Partial<Offer>): void {
		this.application.offers?.forEach((offer) => {
			if (offer.selected) {
				offer.selected = false;
			}

			if (offer.id === selectedOffer?.id) {
				offer.selected = true;
			}
		});

		this.selectedOffer = selectedOffer;
	}
}
