import { Injectable } from "@angular/core";
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { CoreService } from '../services/core.service';

export const DISPLAY_TOAST_ON_ERROR = new HttpContextToken<boolean>(() => true);

@Injectable({ providedIn: 'root' })
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService, private coreService: CoreService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((errorResponse: any) => {
          const errorMessage = errorResponse.status === 500 || errorResponse.status === 0 ?
            'Something went wrong! Please try again later' :
            errorResponse.error.message || errorResponse.statusText;

          this.coreService.setIsLoading(false);
  
          if (req.context.get(DISPLAY_TOAST_ON_ERROR) === true) {
            this.toastrService.error(errorMessage, undefined, { positionClass: 'toast-custom-bottom-center' });
          }

          return of(errorResponse);
        })
      );
  }
}
