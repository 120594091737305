import { Component } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent {
  public toggleInformationContainersHoverEffect(event: MouseEvent): void {
    const allInformationContainerElements = (event.target! as HTMLDivElement).children;
    
    for (let i = 0; i < allInformationContainerElements.length; i++) {
      const informationContainerElement = allInformationContainerElements[i];
      
      informationContainerElement.classList.toggle('hovered-information-containers');
    }
  }

  public toggleInformationContainerElementHoverEffect(event: MouseEvent): void {
    const hoveredInformationContainerElement = (event.target! as HTMLDivElement);
    const hoveredImageContainerElement = hoveredInformationContainerElement.children[0];
    const hoveredImageElement = hoveredImageContainerElement.children[0];

    hoveredInformationContainerElement.classList.toggle('hovered-information-container')
    hoveredImageElement.classList.toggle('hovered-information-container-image');
  }
}
