import { Component } from '@angular/core';

@Component({
  selector: 'app-why-mobilend',
  templateUrl: './why-mobilend.component.html',
  styleUrls: ['./why-mobilend.component.scss']
})
export class WhyMobilendComponent {
  public scrollTo(element: HTMLElement): void {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
