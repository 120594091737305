<app-header></app-header>
<div class="first-container">
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column gap-3">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>Mobilend.com</span>
      </div>
      <h1 class="text-blue-purple-gradient">
        About Us
      </h1>
      <p>
        Mobilend was founded in 2016 and based in Southern California. Our team is made up of financial services professionals with a common goal to help consumers find the right product to resolve their debt. Mobilend designed loan products to help consumers consolidate unsecured debt.
        <br />
        <br />
        We are here to support you along the way, feel free to <a routerLink="/contact-us" class="text-decoration-none text-cyan-100">Contact Us</a>
      </p>
    </div>
  </div>
</div>
<div class="second-container">
  <div class="row gy-3">
    <div class="col-xl-2">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>Mobilend.com</span>
      </div>
    </div>
    <div class="col-xl-4">
      <h2>Get funded and get ahead</h2>
      <p>
        Mobilend’s staff works with consumers with complex financial situations to lower their cost of credit.
      </p>
      <p>
        We are constantly cultivating new products and new ways to help reduce the cost of debt for many Americans living with high interest and compounding credit card interests. Mobilend’s single mission is to help you save and get ahead.
      </p>
      <p>
        We work directly with national creditors to lower the cost of credit for many consumers across the United States. We are constantly finding ways to build state of the art technology to bring even more efficiency between consumers and creditors. 
      </p>
      <p>
        If you are carrying a high rate revolving line of credit from a national credit card issuer and you are having a bit of issue making payments. Please contact us at Mobilend.com. We might have a solution for you and get you back on track and get ahead in life.
      </p>
    </div>
    <div class="col-xl-6">
      <div class="position-relative">
        <div>
          <div class="icon-container blue-icon-container">
            <mat-icon svgIcon="bank-cards"></mat-icon>
          </div>
        </div>
        <div>
          <div class="box-with-left-border cyan-left-border">
            <div class="content">
              <!-- <img src="../../../assets/logos/transunion.png" alt="transunion" /> -->
            </div>
          </div>
        </div>
        <div class="my-3 mt-sm-6">
          <div class="icon-container purple-icon-container">
            <mat-icon svgIcon="dollar"></mat-icon>
          </div>
          <div class="icon-container transparent-icon-container">
            <mat-icon svgIcon="bank"></mat-icon>
          </div>
          <div class="box-with-left-border pink-left-border">
            <div class="content">
              <!-- <img src="../../../assets/logos/experian.png" alt="experian" /> -->
            </div>
          </div>
        </div>
        <div class="box-with-left-border red-left-border">
          <div class="content">
            <!-- <img src="../../../assets/logos/equifax.png" alt="equifax" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="third-container">
  <carousel>
    <slide>
      <h1>Tailored Plans</h1>
      <p>
        At mobilend.com, we understand that every customer’s situation is different and unique. Leveraging our state of the art technology, we can tailor a different plan for each of our clients. Whether it is to pay off a high rate revolving credit card or consolidation of a few personal loans, our system can help place you on the path to savings.
      </p>
      <p>
        Credit card revolving debt has surpassed over $1 trillion dollars. Many Americans struggle to manage their financial life with their already busy lives. At Mobilend.com we are engineered to solve this problem with our technology and a team of professionals.
      </p>
      <p>
        We have dedicated the past few years to build a team of debt consolidation and personal finance experts right here in the U.S.A. Our knowledgeable staff and our customer call center is located in sunny Southern California. We are here to answer any of your questions and help you on your way to financial freedom.
      </p>
    </slide>
    <slide>
      <h1>We’ve Got You Covered</h1>
      <p>
        We understand that life is full of small surprises and you might feel like you are falling behind. If you have 3 or 4 credit cards, auto payments and other personal debt, wouldn’t it be easier to consolidate all of your obligation into one single simple interest personal loan? You may even pay less every month moving forward by doing so. At mobilend.com we make it easy, we help you pay down your debt by paying your creditors directly.
      </p>
      <p>
        Using Mobilend.com's consolidation loan proceeds to pay off your high interest credit card debt, your credit score may improve and you may even save on your monthly payment. We make the complexity of paying off your high interest credit card debt disappear with our knowledgeable staff and technology.
      </p>
      <p>
        Your personal loan proceeds go directly to your bank account so you can use it to pay off your high interest credit card balance right away. You have the freedom to choose the way you pay off your debt. Our staff is ready to assist you Monday to Friday 8 to 5PM PST.
      </p>
    </slide>
    <slide>
      <h1>Partners You Can Trust</h1>
      <p>
        Our consumer advocates have years of experience helping clients get out of debt. We are dedicated individuals right here in Southern California to serve you nationally. We leverage technology to bring you dedicated service and low cost finance options. We are here to help you to get ahead of your financial life. We are working together to make finance simple.  
      </p>
      <p>
        We are working with some of the largest national payment, debt relief and escrow agencies to lower the cost of credit across our financial system. Technology and service is what drives us at mobilend.com everyday to deliver the best finance product for our customers.
      </p>
      <p>
        Founded in 2016, we have been building our team of consumer advocates to work on your behalf to get the best possible product for you.
      </p>
    </slide>
  </carousel>
</div>
<div class="fourth-container">
  <div class="container-description">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>Mobilend.com</span>
    </div>
    <h1>
      Our <span class="text-purple-100">Team</span>
    </h1>
    <h2>
      Meet people who made it possible
    </h2>
  </div>
  <div class="team">
    <div class="team-member-card">
      <img src="../../../assets/images/nicole.png" alt="nicole">
      <h2>Nicole Houston</h2>
      <h3>CEO</h3>
      <p>
        Nicole has decades of personal finance product experience. She ran consumer sales and operations for major national finance companies such as LoanDepot.com. Nicole manages all aspects of our lending company. Nicole graduated from University of California, Irvine.
      </p>
    </div>
    <div class="team-member-card">
      <img src="../../../assets/images/tim.png" alt="tim">
      <h2>Timothy Li</h2>
      <h3>CTO</h3>
      <p>
        Tim has over 15 years of banking and online consumer finance experience. Most recently he worked at the CIO of RealtyMogul.com and launched LoanDepot's Personal Lending platform. He has an engineering and mathematics degree from SJSU and University of Southern California.
      </p>
    </div>
    <div class="team-member-card">
      <img src="../../../assets/images/henry.png" alt="henry">
      <h2>Henry Wang, Ph.D</h2>
      <h3>Chief Risk Officer</h3>
      <p>
        Henry has multiple advanced degrees in engineering and statistics. He has managed decision sciences and credit risk modeling groups for multiple lending companies such as JPMorgan Chase, LoanDepot.com. Henry graduated from Virginia Tech and University of Florida.
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>