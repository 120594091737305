import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { AboutUsComponent } from './about-us.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    SharedModule,
    CarouselModule.forRoot()
  ]
})
export class AboutUsModule {}
