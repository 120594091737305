import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { AuthComponent } from "./auth.component";
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SetPasswordComponent } from './set-password/set-password.component';
import { SharedModule } from "../../shared/shared.module";
import { AuthRoutingModule } from './auth-routing.module';
import { TwoFactorAuthenticationLoginComponent } from "./2fa-login/2fa-login.component";

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    TwoFactorAuthenticationLoginComponent,
    ForgotPasswordComponent,
    SetPasswordComponent
  ],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    SharedModule,
    AuthRoutingModule
  ]
})
export class AuthModule {}
