import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { Application } from '../../../core/types/application.types';

export interface DocumentToBeRendered {
  description: string;
  uploadedFiles: UploadedFile[];
  type: string;
  label: string;
}
export interface FileEvent {
  action: 'upload' | 'delete';
  documentKey: string;
  uploadedFiles: UploadedFile[];
}
export interface UploadedFile {
  displayDownloadButton?: boolean;
  displayTrashButton?: boolean;
  file: File;
  id?: number;
  status?: string;
  rejectReason?: string;
}

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  @Input() public documentsToBeRendered: DocumentToBeRendered[] = [];
  @Input() public application?: Application;
  @Output() public onFileUpload: EventEmitter<FileEvent> = new EventEmitter();
  @Output() public onFileDelete: EventEmitter<FileEvent> = new EventEmitter();
  @Output() public onFileDownload: EventEmitter<UploadedFile> = new EventEmitter();
  public documentsToBeRenderedMap: Map<string, DocumentToBeRendered> = new Map();

  constructor() {}

  public ngOnInit (): void {
    this.documentsToBeRendered.forEach((document) => {
      this.documentsToBeRenderedMap.set(
        document.type,
        {
          description: document.description,
          uploadedFiles: document.uploadedFiles,
          type: document.type,
          label: document.label
        }
      );
    });
  }

  public keepDocumentsToBeRenderedMapOrder(): number {
    return 0;
  }

  public handleFileDrop(droppedFiles: NgxFileDropEntry[], documentKey: string): void {
    const newUploadedFiles = this.generateNewUploadedFiles(droppedFiles);

    this.onFileUpload.emit({ action: 'upload', documentKey, uploadedFiles: newUploadedFiles });
  }

  public downloadFile(uploadedFile: UploadedFile): void {
    this.onFileDownload.emit(uploadedFile);
  }

  public deleteFile(documentKey: string, uploadedFileToDelete: UploadedFile): void {
    this.onFileDelete.emit({
      action: 'delete',
      documentKey,
      uploadedFiles: [uploadedFileToDelete]
    });
  }

  private generateNewUploadedFiles(droppedFiles: NgxFileDropEntry[]): UploadedFile[] {
    const newUploadedFiles: UploadedFile[] = [];
    const filteredFiles = droppedFiles.filter(this.isValidFile);

    if (filteredFiles.length) {
      const fileEntry = filteredFiles[0].fileEntry as FileSystemFileEntry;

      fileEntry.file((file) => {
        const newUploadedFile = { file, id: Date.now() };

        newUploadedFiles.push(newUploadedFile);
      });
    }

    return newUploadedFiles;
  }

  private isValidFile(fileDropEntry: NgxFileDropEntry): boolean {
    const { fileEntry } = fileDropEntry;
    const regex = /\.[^\.]+$/;
    const allowedExtensions = ['.pdf', '.jpg', '.jpeg', '.png'];
    const execResult = regex.exec(fileEntry.name);
    const isValidFile = Boolean(execResult && allowedExtensions.includes(execResult[0].toLowerCase()));

    return isValidFile;
  }
}
