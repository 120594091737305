import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { StepperAction, Actions } from '../enrollment.component';
import { Application, CreatePersonalInformationStepDto } from '../../../core/types/application.types';
import { CoreService } from '../../../core/services/core.service';
import { ApplicationService } from "../../../core/services/application.service";
import { isValidYearsInMonthsValidator } from '../../../shared/directives/years-in-monts/years-in-months.directive';
import { LoggedUser } from '../../../core/types/auth.types';
import { AuthService } from '../../../core/services/auth.service';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-personal-information-step',
  templateUrl: './personal-information-step.component.html',
  styleUrls: ['./personal-information-step.component.scss']
})
export class PersonalInformationStepComponent implements OnInit {
  @Input() selectedAmount: number;
  @Input() application?: Application;
  @Output() public onAction = new EventEmitter<StepperAction>();
  public loggedUser: LoggedUser;
  public isSSNInputVisible: boolean = false;
  public suffixInputOptions: SelectOption[] = [
    { label: 'None', value: 'none'},
    { label: 'Jr', value: 'JR' },
    { label: 'Sr', value: 'SR' },
    { label: 'I', value: 'I' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' }
  ];
  public residenceTypeInputOptions: SelectOption[] = [
    { label: 'Home Owner', value: 'Home Owner'},
    { label: 'Rent', value: 'Rent' },
    { label: 'Live with Family', value: 'Live with Family' },
    { label: 'Other', value: 'Other' },
  ];
  public creditRatingInputOptions: SelectOption[] = [
    { label: 'Excellent  (781 - 850)', value: 'Excellent'},
    { label: 'Good  (661 - 780)', value: 'Good' },
    { label: 'Fair  (601 - 660)', value: 'Fair' },
    { label: 'Poor  (300-600)', value: 'Poor' },
  ]
  public loanPurposeInputOptions: SelectOption[] = [
    { label: 'Debt Consolidation', value: 'Debt Consolidation' },
    { label: 'Home Improvement', value: 'Home Improvement' },
    { label: 'Pool', value: 'Pool' },
    { label: 'Solar', value: 'Solar' },
    { label: 'Vacation', value: 'Vacation' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Alimony or Child Support', value: 'Alimony or Child Support' },
    { label: 'New Auto Purchase', value: 'New Auto Purchase' },
    { label: 'Large Purchase', value: 'Large Purchase' },
    { label: 'Pay Off Credit Cards', value: 'Pay Off Credit Cards' },
    { label: 'Student Loan Refinancing', value: 'Student Loan Refinancing' },
    { label: 'Education', value: 'Education' },
    { label: 'Special Occasion', value: 'Special Occasion' },
    { label: 'Cosmetic Procedures', value: 'Cosmetic Procedures' },
    { label: 'Moving and Relocation', value: 'Moving and Relocation' },
    { label: 'Household Expenses', value: 'Household Expenses' },
    { label: 'Medical', value: 'Medical' },
    { label: 'Dental', value: 'Dental' },
    { label: 'Taxes', value: 'Taxes' },
    { label: 'Business', value: 'Business' },
    { label: 'Funeral Expenses', value: 'Funeral Expenses' },
    { label: 'Auto Loan Refinance', value: 'Auto Loan Refinance' },
    { label: 'Used Auto Purchase', value: 'Used Auto Purchase' },
    { label: 'Auto Lease Buyout', value: 'Auto Lease Buyout' },
    { label: 'Baby', value: 'Baby' },
    { label: 'Adoption Expenses', value: 'Adoption Expenses' },
    { label: 'Emergency Expenses', value: 'Emergency Expenses' },
    { label: 'Wedding', value: 'Wedding' },
    { label: 'Engagement', value: 'Engagement' },
    { label: 'Auto Repairs', value: 'Auto Repairs' },
    { label: 'Equipment Purchase', value: 'Equipment Purchase' },
    { label: 'Other', value: 'Other' },
  ];
  public personalInformationForm = this.fb.nonNullable.group({
    requestedAmount: [''],
    address: [
      {
        street: '',
        city: '',
        state: '',
        stateCode: '',
        zipCode: ''
      },
      Validators.required
    ],
    monthsAtAddress: ['', [Validators.required, isValidYearsInMonthsValidator()]],
    monthlyRent: ['', Validators.required],
    residenceType: ['', Validators.required],
    socialSecurityNumber: [''],
    birthDate: ['', Validators.required],
    suffix: ['', Validators.required],
    loanPurpose: ['', Validators.required],
    creditRating: ['', Validators.required],
    annualIncome: ['', Validators.required],
    campaignCode: [''],
    creditReportConsent: [false, Validators.requiredTrue],
    sharingConsent: [false, Validators.requiredTrue]
  });

  constructor(
    private coreService: CoreService,
    private fb: FormBuilder,
    private applicationService: ApplicationService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public get maxBirthDate(): Date {
    const maxBirthDate = new Date();
    const maxYear = maxBirthDate.getFullYear() - 18;

    maxBirthDate.setFullYear(maxYear);

    return maxBirthDate;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser()!;

    this.setFormsInitialValues();
  }

  public handleBackButtonClick(): void {
    if (this.loggedUser) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      this.onAction.emit({ action: Actions.Back });
    }
  }

  public getErrorMessage(controlName: keyof typeof this.personalInformationForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.personalInformationForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('mask')) {
      errorMessage = `${label} is invalid`;
    } else if (control.hasError('isValidYearsInMonths')) {
      errorMessage = control.errors!['isValidYearsInMonths']
    }

    return errorMessage;
  }

  public async handleFormSubmit(): Promise<void> {
    this.personalInformationForm.markAllAsTouched();

    if (this.personalInformationForm.valid) {
      try {
        this.coreService.setIsLoading(true);
  
        const existingToken = this.coreService.getSessionStorageData<{ token: string }>('token');
        const application = await this.createPersonalInformation(existingToken?.token);

        if (this.loggedUser && this.loggedUser.applicationId === application.id) {
          this.router.navigate(['..', 'employment'], { relativeTo: this.route });
        } else {
          this.onAction.emit({ action: Actions.Next, data: { application } });
        }
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public toggleVisibility(): void {
    this.isSSNInputVisible = !this.isSSNInputVisible;
  }

  public getSSNInputClass(): string {
    return this.isSSNInputVisible ? 'input-security-none' : 'input-security-disc';
  }

  public isCheckboxInvalid(controlName: 'creditReportConsent' | 'sharingConsent'): boolean {
    return (
      this.personalInformationForm.controls[controlName].touched &&
      this.personalInformationForm.controls[controlName].invalid
    );
  }

  private setFormsInitialValues(): void {
    this.personalInformationForm.patchValue({
      requestedAmount: String(this.selectedAmount)
    });

    if (this.application) {
      const { customer } = this.application;

      this.personalInformationForm.patchValue({
        address: {
          street: customer.street || '',
          city: customer.city || '',
          state: customer.state || '',
          stateCode: customer.stateCode || '',
          zipCode: customer.zipCode || ''
        },
        socialSecurityNumber: customer.socialSecurityNumber || '',
        birthDate: customer.birthDate || '',
        suffix: customer.suffix || '',
        monthsAtAddress: String(customer.monthsAtAddress) || '',
        monthlyRent: customer.monthlyRent ? String(customer.monthlyRent) : '',
        annualIncome: customer.monthlyIncome ? String(parseFloat((customer.monthlyIncome * 12).toFixed(2))) : '',
        residenceType: customer.residenceType || '',
        loanPurpose: this.application.loanPurpose || '',
        creditRating: this.application.creditRating || '',
        campaignCode: this.application.campaignCode || '',
        creditReportConsent: false,
        sharingConsent: false
      });
    }
  }

  private createPersonalInformation(token?: string): Promise<Application> {
    const { address, suffix, annualIncome, requestedAmount, ...restPersonalInformation } = this.personalInformationForm.value;
    
    const createApplicationPayload = {
      ...address,
      ...restPersonalInformation,
      requestedAmount: Number(requestedAmount),
      monthlyRent: Number(restPersonalInformation.monthlyRent),
      monthlyIncome: parseFloat((Number(annualIncome) / 12).toFixed(2)),
      monthsAtAddress: Number(restPersonalInformation.monthsAtAddress),
      suffix: suffix === 'none' ? '' : suffix
    } as CreatePersonalInformationStepDto;


    return lastValueFrom(this.applicationService.createPersonalInformationV2(
      this.application?.id || this.loggedUser.applicationId,
      createApplicationPayload,
      token
    ));
  }
}
