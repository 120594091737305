<ng-template #updateSignatureStatusModalContentTemplate let-context>
  <span class="d-inline-block mb-2">
    {{
      'Are you sure you want to ' + context.action + ' this signature?'
    }}
  </span>

  <mat-form-field *ngIf="context.action === 'reject'">
    <mat-label>Reject Reason</mat-label>
    <input matInput [formControl]="rejectReason" />
  </mat-form-field>
</ng-template>

<ng-template #updateSignatureStatusModalActionsTemplate let-context>
  <div class="
    d-flex
    align-items-center
    gap-3
    justify-content-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick()"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleConfirmButtonClick(context)"
    >
      Confirm
    </button>
  </div>
</ng-template>