import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';

import { EmploymentInformationStepComponent } from './employment-information-step.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [EmploymentInformationStepComponent],
  exports: [EmploymentInformationStepComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    NgxMaskModule,
    ButtonModule
  ]
})
export class EmploymentInformationStepModule {}
