<form
  [formGroup]="setPasswordForm"
  (submit)="handleSubmit()"
>
  <h1>
    Set <span class="text-purple-100">New Password</span>
  </h1>
  <div class="form-content">
    <div class="d-flex flex-column gap-4">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>New Password</mat-label>
        <input
          matInput
          formControlName="password"
          [type]="hideNewPasswordValue ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="togglePasswordInput($event, 'password')"
        >
          <mat-icon>
            {{ hideNewPasswordValue ? 'visibility' : 'visibility_off' }}
          </mat-icon>
        </button>
        <mat-error
          [innerHTML]="getErrorMessage('password', 'New Password')"
        >
        </mat-error>
      </mat-form-field>
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          [type]="hideConfirmPasswordValue ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="togglePasswordInput($event, 'confirmPassword')"
        >
          <mat-icon>
            {{ hideConfirmPasswordValue ? 'visibility' : 'visibility_off' }}
          </mat-icon>
        </button>
        <mat-error
          [innerHTML]="getErrorMessage('confirmPassword', 'Confirm Password')"
        >
        </mat-error>
      </mat-form-field>
    </div>
    <app-button
      label="CONTINUE"
      svgIcon="semiarrow-right"
      type="submit"
    ></app-button>
  </div>
</form>