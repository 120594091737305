import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSliderChange } from '@angular/material/slider';

import { StepperAction, Actions } from '../enrollment.component';

@Component({
  selector: 'app-debt-amount-step',
  templateUrl: './debt-amount-step.component.html',
  styleUrls: ['./debt-amount-step.component.scss']
})
export class DebtAmountStepComponent implements OnInit {
  @Input() public selectedAmount?: number;
  @Output() public onAction = new EventEmitter<StepperAction>();
  public debtAmount = new FormControl(20000);

  public ngOnInit(): void {
    if (this.selectedAmount) {
      this.debtAmount.setValue(this.selectedAmount);
    }
  }

  public handleInputChange(event: MatSliderChange): void {
    this.debtAmount.setValue(event.value);
  }

  public handleContinueButtonClick(): void {
    this.onAction.emit({
      action: Actions.Next,
      data: { selectedAmount: this.debtAmount.value || 0 }
    });
  }
}
