<div *ngIf="displaySignatureBox" class="signature-box-container">
  <div
    class="d-flex flex-column gap-3"
    [class.d-none]="!isCreating"
  >
    <span class="signature-box-label">
      Create your sign
    </span>
    <p>
      Please click and hold your mouse to sign your signature in the box below. You will need to sign the fields in the agreements below with your signature.
    </p>
    <app-signature-input
      #signatureInput
      appearance="none"
      [formControl]="signature"
      [isInputValid]="signature.valid"
      [hasFormBeenSubmitted]="false"
      [canvasHeight]="100"
    ></app-signature-input>
  </div>
  <div *ngIf="!isCreating" class="signature-container">
    <img [src]="savedSignature" alt="signature" />
  </div>
  <div class="
    d-flex
    justify-content-center
    justify-content-lg-end
    align-self-end
  ">
    <div class="
      d-flex
      flex-column
      flex-sm-row
      flex-md-column
      flex-lg-row
      gap-4
    ">
    <ng-container *ngIf="isCreating">
      <app-button
        variant="outlined"
        label="CLEAR"
        (click)="signatureInput?.clearCanvas()"
        [disabled]="signatureInput?.isEmptySignature()"
      ></app-button>
      <app-button
        label="SAVE"
        [disabled]="signatureInput?.isEmptySignature()"
        (click)="handleSaveSignatureClick()"
      ></app-button>
    </ng-container>
    <app-button
      *ngIf="savedSignature && !isCreating"
      label="RECREATE SIGNATURE"
      (click)="handleRecreateSignatureClick(); signatureInput?.clearCanvas()"
    ></app-button>
    </div>
  </div>
</div>

<div class="d-flex flex-column gap-2">
  <div class="agreement">
    <button (click)="handleAgreementButtonClick('loan')">
      Loan Agreement
    </button>
  </div>
  <div class="agreement">
    <button (click)="handleAgreementButtonClick('ach')">
      ACH Agreement
    </button>
  </div>
</div>