<app-header></app-header>
<div class="first-container">
  <div class="title-container">
    <h1>
      Mobilend.com offers <span class="text-blue-purple-gradient">real solutions</span>
    </h1>
  </div>
  <div class="voice-for-our-consumer-container">
    <span>Voice for our consumer</span>
    <p>
      Our mission is to leverage technology to bring the best personal finance product to all Americans. Our true debt consolidation loan pays creditors directly and securely. We use technology to make complex financial transactions easy and transparent. Our customers can enjoy the savings and get ahead in life.
    </p>
  </div>
</div>
<div class="second-container">
  <div class="
    d-flex
    flex-column
    justify-content-end
    align-items-center
    align-items-xxl-start
  ">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>Mobilend.com</span>
    </div>
    <h1>Making Finance Simple</h1>
    <div>
      <app-button
        label="CALL US"
        svgIcon="semiarrow-right"
        phone="tel:1-866-330-1669"
      ></app-button>
    </div>
  </div>
  <div class="flex-grow-1">
    <div class="button-container">
      <app-button
        label="LEARN MORE ABOUT US"
        svgIcon="semiarrow-right"
        (click)="scrollTo(thirdContainer)"
      ></app-button>
    </div>
    <div class="client-boxes-container">
      <div class="client-boxes-row">
        <div class="client-box">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../../../assets/images/tasha.png" alt="avatar" />
            <div class="
              d-flex
              flex-column
              align-items-end
              gap-2
              fw-bold"
            >
              <span class="client-name">
                Tasha I.
              </span>
              <span>Client</span>
            </div>
          </div>
          <p>
            Consolidated all my credit cards into one loan and I can pay it off in a few years.
            <br />
            Thanks
          </p>
        </div>
        <div class="client-box">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../../../assets/images/robert.png" alt="avatar" />
            <div class="
              d-flex
              flex-column
              align-items-end
              gap-2
              fw-bold"
            >
              <span class="client-name">
                Robert C.
              </span>
              <span>Client</span>
            </div>
          </div>
          <p>
            I needed to reduce my monthly payment to buy a small starter home. Mobilend.com was there to help me.
            <br />
            Thank you.
          </p>
        </div>
      </div>
      <div class="client-boxes-row">
        <div class="client-box">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../../../assets/images/matt.png" alt="avatar" />
            <div class="
              d-flex
              flex-column
              align-items-end
              gap-2
              fw-bold"
            >
              <span class="client-name">
                Matt H.
              </span>
              <span>Client</span>
            </div>
          </div>
          <p>
            Mobilend.com helped to reduce my monthly payment and got rid of a few of my high interest credit cards.
            <br />
            Thanks.
          </p>
        </div>
        <div class="client-box">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../../../assets/images/flora.png" alt="avatar" />
            <div class="
              d-flex
              flex-column
              align-items-end
              gap-2
              fw-bold"
            >
              <span class="client-name">
                Flora W.
              </span>
              <span>Client</span>
            </div>
          </div>
          <p>
            I was slightly behind with one of my credit cards until I found mobilend.com. Friendly staff and really knowledgable.
            <br />
            Thanks!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div #thirdContainer class="third-container">
  <div class="title-overview">
    <div class="rectangle"></div>
    <span>MOBILEND.COM</span>
  </div>
  <div class="row gy-7">
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>Advanced Underwriting</span>
          <p>
            Mobilend.com utilizes advance data analytics to underwrite a consumer in minutes.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>Data Security</span>
          <p>
            Our consumer’s data is securely stored within our cloud storage.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>Flexible Product</span>
          <p>
            We offer unique solutions to each of our customers using our underwriting technology.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>Financial Education</span>
          <p>
            We believe that a debt consolidation loan, although beneficial, it’s not the end of our mission. We work with national education agencies to deliver financial education to all of our clients.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>U.S. Call Center</span>
          <p>
            Finance is complicated, let’s make it easy with folks you can call and really help you understand your path to financial freedom. We are here in sunny southern California.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="why-mobilend-box">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Frame 85">
          <g id="Vector">
          <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
          <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
          <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
          <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
          </g>
          </g>
        </svg>
        <div>
          <span>Customer Care</span>
          <p>
            We are a tech-enabled financial services firm. Our customer care staff is the pride of our company. We are here to serve you, our clients.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fourth-container">
  <div class="d-flex flex-column align-items-center align-items-lg-start">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>Mobilend.com</span>
    </div>
    <h1>
      Make the right choice
    </h1>
    <p>
      Mobilend.com is headquartered in Irvine, California. Our southern California call center has some of the most knowledge customer advocates helping many clients on a daily basis. Experience the mobilend.com difference and call us today.
    </p>
    <div class="buttons-container">
      <app-button
        label="HOW IT WORKS"
        variant="outlined"
        routerLink="/how-it-works"
      ></app-button>
      <app-button
        label="START NOW"
        svgIcon="semiarrow-right"
        routerLink="/enrollment"
      ></app-button>
    </div>
  </div>
  <img src="../../../assets/images/iphone-2.png" alt="iphone" />
</div>
<app-footer></app-footer>