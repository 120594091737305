import { NgModule } from '@angular/core';
import { NgChartsModule } from 'ng2-charts';

import { ApplicationsChartComponent } from './applications-chart.component';
import { CardModule } from '../card/card.module';


@NgModule({
  declarations: [ApplicationsChartComponent],
  imports: [NgChartsModule, CardModule],
  exports: [ApplicationsChartComponent]
})
export class ApplicationsChartModule {}
