<span *ngIf="!contractSignatures?.length">
  No data received
</span>

<div *ngIf="contractSignatures?.length" class="table-responsive">
  <table class="table custom-table">
    <thead>
      <tr>
        <th scope="col">Agreement</th>
        <th scope="col">Signed At</th>
        <th scope="col">Status</th>
        <th scope="col">Status By</th>
        <th scope="col">Reject Reason</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let signature of signaturesTableRowsData">
        <td>
          {{ signature.agreement | titlecase }}
        </td>
        <td>
          {{ signature.signedAt | date:'MM/dd/yyyy' }}
        </td>
        <td>
          {{ signature.status ? (signature.status | titlecase) : 'N/A' }}
        </td>
        <td>
          {{ signature.statusBy || 'N/A' }}
        </td>
        <td>
          {{ signature.rejectReason || 'N/A' }}
        </td>
        <td>
          <button
            mat-icon-button
            matTooltip="Download"
            (click)="handleDownloadButtonClick(signature)"
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            *ngIf="signature.displayApproveButton"
            mat-icon-button color="primary"
            matTooltip="Approve"
            (click)="handleApproveButtonClick(signature)"
          >
            <mat-icon>done</mat-icon>
          </button>
          <button
            *ngIf="signature.displayRejectButton"
            mat-icon-button color="warn"
            matTooltip="Reject"
            (click)="handleRejectButtonClick(signature)"
          >
            <mat-icon>block</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-update-signature-status-modal
  (cancel)="closeModal()"
  (updateStatus)="handleUpdateStatus($event)"
></app-update-signature-status-modal>