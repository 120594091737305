import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { CoreService } from '../../../core/services/core.service';
import { AdminLoginPayload } from '../../../core/types/auth.types';
import { Roles } from '../../../core/types/role.types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });
  public hidePasswordValue: boolean = true;

  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService, 
    private coreService: CoreService
  ) {}

  public ngOnInit(): void {
    const loggedUser = this.authService.getLoggedUser();
    if (loggedUser) {
      const url = this.getRouteUrl(loggedUser.role, loggedUser.applicationId);

      if (url) {
        this.router.navigate(
          [url],
          { relativeTo: this.route }
        );

      }
    }
  }

  public getErrorMessage(controlName: keyof typeof this.loginForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.loginForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    }

    return errorMessage;
  }

  public togglePasswordInput(event: MouseEvent): void {
    event.stopPropagation();

    this.hidePasswordValue = !this.hidePasswordValue;
  }

  public handleLoginFormSubmit(): void {
    if (this.loginForm.valid) {
      const credentials = this.loginForm.value as AdminLoginPayload;

      this.coreService.setIsLoading(true);
      this.authService
        .login(credentials)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe(({ user, token }) => {
          if (token) {
            this.router.navigate(
              [`/auth/2fa/${token}`],
              { relativeTo: this.route }
            );
          }

          if (user) {
            const url = this.getRouteUrl(user.role.name, user.applications?.[0]?.id)
            
            if (url) {
              this.router.navigate(
                [url],
                { relativeTo: this.route }
              );
            }
          }
          this.coreService.clearSessionStorage('enrollment');
          this.coreService.clearSessionStorage('token');
        });
    }
  }

  private getRouteUrl(role: Roles, applicationId?: number): string {
    let url = '';

    switch (role) {
      case Roles.Admin:
        url = '../../admin';
        break;
      case Roles.CustomerService:
        url = '../../customer-service';
        break;
      case Roles.Underwriter:
        url = '../../underwriter';
        break;
      case Roles.Affiliate:
        url = '../../affiliate';
        break;
      case Roles.Agent:
        url = '../../agent';
        break;
      case Roles.Application:
        url = `../../customer/applications/${applicationId}`;
        break;          
      default:
        break;
    }

    return url;
  }
}
