<app-header></app-header>
<div class="legal-center-component-content">
  <a routerLink="/legal-center" class="justify-content-center justify-content-sm-start">
    <mat-icon svgIcon="semiarrow-left"></mat-icon>
    <span>Back to overview</span>
  </a>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>