<app-header></app-header>
<div>
  <div class="first-container">
    <div class="d-flex flex-column gap-3 align-items-center">
      <h1 class="text-center text-sm-start">
        Customer <span class="text-purple-100">Help Center</span>
      </h1>
      <span>Search our website or read answers below.</span>
    </div>
    <img src="../../../assets/images/help-center.png" alt="Help center" />
  </div>
  <div class="
    flex-column
    flex-md-row
    second-container
  ">
    <div class="
      text-center
      text-md-start
      left-side
    ">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>MOBILEND.COM</span>
      </div>
      <h1>Frequently asked questions</h1>
    </div>
    <mat-accordion class="right-side">
      <mat-expansion-panel *ngFor="let qa of qas">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ qa.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ qa.answer }}
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="third-container">
    <div class="d-flex flex-column align-items-start">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>MOBILEND</span>
      </div>
      <h1>Have more questions?</h1>
      <p>
        Whether you have a question about our services, need assistance or just want to talk, we want to hear from you.
      </p>
      <app-button
        label="CONTACT OUR SUPPORT TEAM"
        svgIcon="semiarrow-right"
        phone="tel:1-866-330-1669"
        class="align-self-center"
      ></app-button>
    </div>
  </div>
</div>
<app-footer></app-footer>