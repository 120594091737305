<div>
  <h1>Connect Bank</h1>
  <h2>
    Please, provide the bank account information where you receive your payroll direct deposit or deposit your payroll check for income verification purposes
  </h2>
</div>
<div class="check">
  <div class="check-inner-container">
    <div>
      <div class="d-flex justify-content-between mb-2">
        <div>
          <p>
            {{ application.customer.firstName }} {{ application.customer.lastName }}
          </p>
          <p>
            {{ application.customer.street }},
            <br />
            {{ application.customer.city }}, {{ application.customer.stateCode }}
          </p>
        </div>
        <span class="check-number">
          0001
        </span>
      </div>
      <div class="
        date
        justify-content-center
        justify-content-sm-end
      ">
        <span>Date:</span>
        <div class="underlined-text">
          {{ application.createdAt | date:'YYYY-MM-dd' }}
        </div>
      </div>
    </div>
    <div>
      <div class="
        align-items-center
        align-items-lg-end
        flex-column
        flex-lg-row
        payment-container
      ">
        <div class="flex-grow-1 d-flex">
          <span>
            Pay to the
            <br />
            order of
          </span>
          <div class="underlined-text">
            {{ selectedOffer!.partner }}
          </div>
        </div>
        <div class="
          align-items-end
          align-items-lg-center
          payment-value
        ">
          $ <span>{{ selectedOffer!.monthlyPayment | currency:'USD':'':'1.2-2' }}</span>
        </div>
      </div>
      <div class="mt-3 mb-4 underlined-text">
        {{ selectedOffer!.monthlyPayment | numberToWords }}
      </div>
      <div class="
        d-flex
        flex-column
        flex-lg-row
        justify-content-between
        align-items-center
        align-items-lg-end
        gap-3
      ">
        <div class="memo">
          <span>Memo:</span>
          <div class="underlined-text">
            Monthly savings plan
          </div>
        </div>
        <div class="underlined-text name-on-account">
          {{ bankAccountForm.controls['nameOnAccount'].value }}
        </div>
      </div>
    </div>
    <div class="
      d-flex
      align-items-center
      flex-column
      flex-lg-row
      gap-2
      gap-lg-5
      check-number
    ">
      <span>{{ routingNumber }}</span>
      <span>{{ accountNumber }}</span>
    </div>
  </div>
</div>
<form
  [formGroup]="bankAccountForm"
  (submit)="handleFormSubmit()"
>
  <div class="row gy-4 mb-5">
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Account Type</mat-label>
        <mat-select formControlName="accountType">
          <mat-option *ngFor="let option of accountTypeInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('accountType', 'Account Type') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Bank Name</mat-label>
        <input matInput formControlName="bankName" />
        <mat-error>
          {{ getErrorMessage('bankName', 'Bank Name') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Routing Number</mat-label>
        <input matInput formControlName="routingNumber" mask="000000000" />
        <mat-error>
          {{ getErrorMessage('routingNumber', 'Routing Number') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Account Number</mat-label>
        <input matInput formControlName="accountNumber" mask="00000000999999999" />
        <mat-error>
          {{ getErrorMessage('accountNumber', 'Account Number') }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="
    d-flex
    justify-content-center
    justify-content-lg-end
  ">
    <div class="
      d-flex
      flex-column
      flex-sm-row
      flex-md-column
      flex-lg-row
      gap-4
    ">
      <app-button
        variant="outlined"
        label="BACK"
        (click)="handleBackButtonClick()"
      ></app-button>
      <app-button
        type="submit"
        label="CONTINUE"
        svgIcon="semiarrow-right"
      ></app-button>
    </div>
  </div>
</form>