<button
  [ngClass]="{
    'contained': variant === 'contained',
    'outlined': variant === 'outlined',
    'warn' : variant === 'warn',
    'disabled': disabled,
    'small': size === 'small'
  }"
  [disabled]="disabled"
  (click)="handleClick()"
>
  <span *ngIf="label">
    {{ label }}
  </span>
  <mat-icon
    *ngIf="icon || svgIcon"
    [svgIcon]="svgIcon"
  >
    {{ icon }}
  </mat-icon>
</button>