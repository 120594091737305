import { Application } from "./application.types";

export interface PutBankAccount {
  accountType: AccountTypes;
  bankName: string;
  nameOnAccount: string;
  routingNumber: string;
  accountNumber: string;
  bankAccountId?: string;
  signature?: File;
  pdf?: File;
}
export interface BankAccount {
  id: number;
  application?: Application;
  bankName: string;
  nameOnAccount: string;
  accountType: AccountTypes;
  accountNumber: string;
  routingNumber: string;
  createdAt: string;
  updatedAt: string;
}
export enum AccountTypes {
  Checking = 'Checking',
  Savings = 'Savings'
}
