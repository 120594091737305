<form
  [formGroup]="forgotPasswordForm"
  (submit)="handleForgotPasswordFormSubmit()"
>
  <h1>
    Recover <span class="text-purple-100">Password</span>
  </h1>
  <div class="form-content">
    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error>
        {{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <a
      routerLink="/auth/login"
      class="text-cyan-100 fw-bold text-decoration-none"
    >
      Already have an account?
    </a>
    <app-button
      label="CONTINUE"
      svgIcon="semiarrow-right"
      type="submit"
    ></app-button>
  </div>
</form>
