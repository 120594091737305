import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { ExternalPartnerResponse, Offer, OffersStatistics } from "../types/offer.types";
import { environment } from "../../../environments/environment";
import { Application } from '../types/application.types';

@Injectable({ providedIn: 'root' })
export class OfferService {
  private baseUrl = `${environment.apiUrl}/applications`;

  constructor(private http: HttpClient) {}

  public generateOffers(applicationId: number, token?: string): Observable<Offer[]> {
    return this.http.post<Offer[]>(
      `${this.baseUrl}/${applicationId}/offers`, 
      null, 
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public getByApplicationId(applicationId: number): Observable<Offer[]> {
    return this.http.get<Offer[]>(`${this.baseUrl}/${applicationId}/offers`, { withCredentials: true });
  }

  public getAchieveOffers(applicationId: number): Observable<ExternalPartnerResponse> {
    return this.http.get<ExternalPartnerResponse>(`${this.baseUrl}/${applicationId}/offers/achieve`, { withCredentials: true });
  }

  public getFreedomFinancialOffers(applicationId: number): Observable<ExternalPartnerResponse> {
    return this.http.get<ExternalPartnerResponse>(`${this.baseUrl}/${applicationId}/offers/fdr`, { withCredentials: true });
  }

  public getUpdatedMonevoOffers(applicationId: number): Observable<ExternalPartnerResponse> {
    return this.http.get<ExternalPartnerResponse>(`${this.baseUrl}/${applicationId}/offers/monevo`,{ withCredentials: true });
  }

  public selectOffer(applicationId: number, offerId: number, token?: string): Observable<Offer> {
    return this.http.patch<Offer>(
      `${this.baseUrl}/${applicationId}/offers/${offerId}`,
      null,
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : new HttpHeaders()
      }
    );
  }

  public selectSupermoneyOffer(applicationId: number, token?: string): Observable<Application> {
    return this.http.patch<Application>(
      `${this.baseUrl}/${applicationId}/offers/supermoney/select`,
      null,
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : new HttpHeaders()
      }
    );
  }

  public getOffersStatictics(): Observable<OffersStatistics> {
    return this.http.get<OffersStatistics>(`${this.baseUrl}/offers/statistics`, { withCredentials: true });
  }
}
