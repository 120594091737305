import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { LegalCenterComponent } from './legal-center.component';
import { SharedModule } from '../../shared/shared.module';
import { LegalCenterRoutingModule } from './legal-center-routing.module';
import { LegalCenterMainComponent } from './legal-center-main/legal-center-main.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DontSellMyPersonalInformationModalComponent } from './privacy-policy/dont-sell-my-personal-information-modal/dont-sell-my-personal-information-modal.component';
import { CommunicationConsentAgreementComponent } from './communication-consent-agreement/communication-consent-agreement.component';
import { FinancialEducationResourceCenterComponent } from './financial-education-resource-center/financial-education-resource-center.component';
import { UsuryDislosureComponent } from './usury-disclosure/usury-disclosure.component';

@NgModule({
  declarations: [
    LegalCenterComponent,
    LegalCenterMainComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    DontSellMyPersonalInformationModalComponent,
    CommunicationConsentAgreementComponent,
    FinancialEducationResourceCenterComponent,
    UsuryDislosureComponent
  ],
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    SharedModule,
    LegalCenterRoutingModule
  ]
})
export class LegalCenterModule {}
