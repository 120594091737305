import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { SupermoneyOffersComponent } from './supermoney-offers.component';
import { CardModule } from '../card/card.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [SupermoneyOffersComponent],
  imports: [CommonModule, MatButtonModule, CardModule, ButtonModule],
  exports: [SupermoneyOffersComponent]
})
export class SupermoneyOffersModule {}
