import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs";

import { Application } from "../../../core/types/application.types";
import { StepperAction, Actions } from '../enrollment.component';
import { Offer } from "../../../core/types/offer.types";
import { AccountTypes, PutBankAccount } from "../../../core/types/bank-account.types";
import { BankAccountService } from "../../../core/services/bank-account.service";
import { CoreService } from "../../../core/services/core.service";
import { routingNumberValidator } from '../../../shared/directives/routing-number/routing-number.directive';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-bank-account-step',
  templateUrl: './bank-account-step.component.html',
  styleUrls: ['./bank-account-step.component.scss']
})
export class BankAccountStep implements OnInit {
  @Input() public application: Application;
  @Output() public onAction: EventEmitter<StepperAction> = new EventEmitter();
  public selectedOffer?: Offer;
  public accountTypeInputOptions: SelectOption[] = [
    { label: 'Checking', value: AccountTypes.Checking },
    { label: 'Savings', value: AccountTypes.Savings }
  ];
  public bankAccountForm = this.fb.group({
    accountType: ['', Validators.required],
    bankName: ['', Validators.required],
    nameOnAccount: ['', Validators.required],
    routingNumber: [
      '',
      [Validators.required, routingNumberValidator()]
    ],
    accountNumber: [
      '',
      [Validators.required]
    ]
  });
  public routingNumber: string = '000000000';
  public accountNumber: string = '00000000';

  public constructor(
    private fb: FormBuilder,
    private coreService: CoreService,
    private bankAccountService: BankAccountService
  ) {}

  public ngOnInit(): void {
    this.setFormInitialValues();
    this.padBankNumbers();
  }

  public getErrorMessage(controlName: keyof typeof this.bankAccountForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.bankAccountForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('mask') || control.hasError('invalidRoutingNumber')) {
      errorMessage = `${label} is invalid`;
    }

    return errorMessage;
  }

  public handleBackButtonClick(): void {
    this.onAction.emit({ action: Actions.Back });
  }

  public handleFormSubmit(): void {
    if (this.bankAccountForm.valid) {
      const bankAccountData = this.bankAccountForm.value as PutBankAccount;
      const storage = this.coreService.getSessionStorageData<{ token: string }>('token');

      this.coreService.setIsLoading(true);
      this.bankAccountService
        .put(this.application.id, bankAccountData, storage?.token)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe((bankAccount) => {
          bankAccount.application = undefined;
          this.application.bankAccount = bankAccount;

          this.onAction.emit({
            action: Actions.Next,
            data: { application: this.application }
          });
        });
    }
  }

  private setFormInitialValues(): void {
    this.bankAccountForm.controls.nameOnAccount
      .setValue(`${this.application.customer.firstName} ${this.application.customer.lastName}`);

    if (this.application.bankAccount) {
      const { bankAccount } = this.application;

      this.bankAccountForm.patchValue({
        accountType: bankAccount.accountType,
        bankName: bankAccount.bankName,
        nameOnAccount: bankAccount.nameOnAccount,
        routingNumber: bankAccount.routingNumber,
        accountNumber: bankAccount.accountNumber
      });
    }
  }

  private padBankNumbers(): void {
    this.selectedOffer = this.application.offers!.find((offer) => offer.selected);
    this.routingNumber = this.padBankNumber(this.bankAccountForm.controls.routingNumber.value, 9);
    this.accountNumber = this.padBankNumber(this.bankAccountForm.controls.accountNumber.value, 8);

    this.bankAccountForm.controls.routingNumber.valueChanges.subscribe((value) => {
      this.routingNumber = this.padBankNumber(value, 9);
    });
    this.bankAccountForm.controls.accountNumber.valueChanges.subscribe((value) => {
      this.accountNumber = this.padBankNumber(value, 8);
    });
  }

  private padBankNumber(value: string | null, maxLength: number): string {
    const strValue = value || '';

    return strValue.padEnd(maxLength, '0');
  }
}
