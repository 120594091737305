import { Component } from '@angular/core';
import { environment } from "../../../../environments/environment";


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public blogUrl = environment.blogUrl;

  public get currentYear(): number {
    return new Date().getFullYear();
  }
}
