import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum ApplicationDetailsTabs {
  UserInformation = 'User Information',
  EmploymentInformation = 'Employment Information',
  Integrations = 'Integrations',
  CreditReport = 'Credit Report',
  BRF = 'BRF',
  UnderwritingRules = 'Rules',
  Offers = 'Offers',
  BankInformation = 'Bank Information',
  Documents = 'Documents',
  Agreements = 'Agreements',
  PaymentManagement = 'Payment Management',
  EmailCommunications = 'Email Communications',
  Logs = 'Logs',
  Comments = 'Comments'
}

@Injectable({ providedIn: 'root' })
export class CoreService {
  private isLoadingSource = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSource.asObservable();

  public setLocalStorageData<T>(key: string, data: T): void {
    let storageData = null;
    const previousData = this.getLocalStorageData<T>(key);

    if (previousData) {
      storageData = { ...previousData, ...data };
    } else {
      storageData = { ...data };
    }

    localStorage.setItem(key, JSON.stringify(storageData));
  }

  public getLocalStorageData<T>(key: string): null | T {
    const data = localStorage.getItem(key);
    return !!data ? JSON.parse(data) : data;
  }

  public clearLocalStorage(key: string): void {
    return localStorage.removeItem(key);
  }

  public setSessionStorageData<T>(key: string, data: T): void {
    let storageData = null;
    const previousData = this.getSessionStorageData<T>(key);

    if (previousData) {
      storageData = { ...previousData, ...data }
    } else {
      storageData = { ...data }
    }

    sessionStorage.setItem(key, JSON.stringify(storageData));
  }

  public getSessionStorageData<T>(key: string): null | T {
    const data = sessionStorage.getItem(key);
    return !!data ? JSON.parse(data) : data;
  }

  public clearSessionStorage(key: string): void {
    return sessionStorage.removeItem(key);
  }

  public setIsLoading(isLoading: boolean): void {
    this.isLoadingSource.next(isLoading);
  }
}
