import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ContractSignature } from '../../../../core/types/contract-signature.types';
import { ModalComponent } from '../../modal/modal.component';
import {
  UpdateSignatureStatusModalComponent
} from './update-signature-status-modal/update-signature-status-modal.component';

@Component({
  selector: 'app-signatures-table',
  templateUrl: './signatures-table.component.html'
})
export class SignaturesTableComponent implements OnInit {
  @Input() contractSignatures?: ContractSignature[] = [];
  @Output() downloadButtonClick: EventEmitter<ContractSignature> = new EventEmitter();
  @ViewChild(UpdateSignatureStatusModalComponent)
  private updateSignatureStatusModal: UpdateSignatureStatusModalComponent;
  public signaturesTableColumnsAndHeaders: Map<string, string> = new Map([
    ['agreement', 'Agreement'],
    ['signedAt', 'Signed At'],
    ['status', 'Status'],
    ['statusBy', 'Status By'],
    ['rejectReason', 'Reject Reason']
  ]);
  public signaturesTableRowsData: (ContractSignature & { displayApproveButton: boolean, displayRejectButton: boolean })[] = [];
  public modalRef?: MatDialogRef<ModalComponent>;

  public constructor(private dialog: MatDialog) {}

  public ngOnInit(): void {
    this.setupTableData();
  }

  public handleDownloadButtonClick(contractSignature: ContractSignature): void {
    this.downloadButtonClick.emit(contractSignature);
  }

  public handleApproveButtonClick(contractSignature: ContractSignature): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Approve Signature',
        contentTemplate: this.updateSignatureStatusModal.updateSignatureStatusModalContentTemplate,
        actionsTemplate: this.updateSignatureStatusModal.updateSignatureStatusModalActionsTemplate,
        context: { contractSignature, action: 'approve' }
      },
      restoreFocus: false
    });
  }

  public handleRejectButtonClick(contractSignature: ContractSignature): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Reject Signature',
        contentTemplate: this.updateSignatureStatusModal.updateSignatureStatusModalContentTemplate,
        actionsTemplate: this.updateSignatureStatusModal.updateSignatureStatusModalActionsTemplate,
        context: { contractSignature, action: 'reject' }
      },
      restoreFocus: false
    });
  }

  public handleUpdateStatus(contractSignature: ContractSignature): void {
    const correspondingUpdatedSignatureIdx = this.contractSignatures!
      .findIndex((signature) => signature.id === contractSignature.id);

    this.contractSignatures![correspondingUpdatedSignatureIdx] = contractSignature;
    this.contractSignatures = [...this.contractSignatures!];

    this.setupTableData();
    this.closeModal();
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private setupTableData(): void {
    this.signaturesTableRowsData = this.contractSignatures!.map((signatureForRowData) => {
      const lastAgreementSignature = this.contractSignatures!
        .find((signature) => signature.agreement === signatureForRowData.agreement);

      return {
        ...signatureForRowData,
        displayApproveButton: signatureForRowData.status !== 'approved' && signatureForRowData.agreement !== 'ach' && signatureForRowData.id === lastAgreementSignature!.id,
        displayRejectButton: signatureForRowData.status !== 'rejected'
      }
    });
  }
}
