import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Application } from '../../../core/types/application.types';
import { Actions, StepperAction } from '../enrollment.component';
import { FileEvent, DocumentToBeRendered, UploadedFile } from '../../../shared/components/upload-documents/upload-documents.component';
import { CreateUploadedDocument, DocumentTypes, UploadedDocument } from '../../../core/types/uploaded-document.types';
import { CoreService } from '../../../core/services/core.service';
import { UploadedDocumentService } from '../../../core/services/uploaded-document.service';

@Component({
  selector: 'app-upload-documents-step',
  templateUrl: './upload-documents-step.component.html',
  styleUrls: ['./upload-documents-step.component.scss']
})
export class UploadDocumentsStepComponent implements OnInit {
  @Input() public application: Application;
  @Output() public onAction: EventEmitter<StepperAction> = new EventEmitter();
  public documentsToBeUploaded: DocumentToBeRendered[] = [
    {
      type: DocumentTypes.GovernmentIssueId,
      label: 'Government Issue ID',
      description: 'Front and back',
      uploadedFiles: []
    },
    {
      type: DocumentTypes.PaycheckStub,
      label: 'Paycheck Stub',
      description: 'Last 30 days',
      uploadedFiles: []
    },
    {
      type: DocumentTypes.W2,
      label: 'Wage and Tax Statement',
      description: 'Most Recent',
      uploadedFiles: []
    },
    {
      type: DocumentTypes.BankStatement,
      label: 'Bank Statement',
      description: 'All pages showing 30 Days of activity and ending balance',
      uploadedFiles: []
    }
  ];

  constructor(private coreService: CoreService, private uploadedDocumentsService: UploadedDocumentService) {}

  public get disableContinueButton(): boolean {
    const allDocumentsHaveAtLeastOneUploadedFile = this.documentsToBeUploaded
      .every((document) => document.uploadedFiles.length);

    return !allDocumentsHaveAtLeastOneUploadedFile;
  }

  public ngOnInit(): void {
    this.attachDocumentFilesAlreadyUploaded();
  }

  public handleFileAction(fileEvent: FileEvent): void {
    const correspondingDocument = this.documentsToBeUploaded.find(
      (documentToBeUploaded) => documentToBeUploaded.type === fileEvent.documentKey
    )!;

    if (fileEvent.action === 'upload') {
      const newFiles = fileEvent.uploadedFiles.map((uploadedFile) => ({
        ...uploadedFile,
        displayTrashButton: true
      }));

      correspondingDocument.uploadedFiles.push(...newFiles);
    } else {
      this.handleDeleteFile(correspondingDocument, fileEvent);
    }
  }

  public handleDownloadFile(uploadedFile: UploadedFile): void {
    window.open(URL.createObjectURL(uploadedFile.file), '_blank');
  }

  public handleBackButtonClick(): void {
    this.onAction.emit({ action: Actions.Back });
  }

  public async handleContinueButtonClick(): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const createUploadedDocuments: CreateUploadedDocument[] = [];

      const storage = this.coreService.getSessionStorageData<{ token: string }>('token');
    
      this.documentsToBeUploaded.forEach((document) => {
        document.uploadedFiles = document.uploadedFiles.filter((uploadedFile) => uploadedFile.file.size);
  
        if (document.uploadedFiles.length) {
          createUploadedDocuments.push({
            documentType: document.type as DocumentTypes,
            files: document.uploadedFiles.map((uploadedFile) => uploadedFile.file)
          })
        }
      });

      if (createUploadedDocuments.length) {
        const uploadedDocuments = await lastValueFrom(
         this.uploadedDocumentsService.createUploadedDocuments(this.application.id, createUploadedDocuments, undefined, storage?.token)
        );

        this.application.uploadedDocuments = uploadedDocuments as UploadedDocument[];
      }
    } finally {
      this.coreService.setIsLoading(false);
      this.onAction.emit({
        action: Actions.Next,
        data: { application: this.application }
      });
    }
  }

  private attachDocumentFilesAlreadyUploaded(): void {
    if (this.application.uploadedDocuments?.length) {
      this.application.uploadedDocuments.forEach((uploadedDocument) => {
        const correspondingDocumentToBeUploaded = this.documentsToBeUploaded
          .find((documentToBeUploaded) => documentToBeUploaded.label === uploadedDocument.type)!;

        correspondingDocumentToBeUploaded.uploadedFiles.push({
          id: uploadedDocument.id,
          displayTrashButton: true,
          file: { name: uploadedDocument.name } as File
        });
      });
    }
  }

  private async handleDeleteFile(correspondingDocument: DocumentToBeRendered, fileEvent: FileEvent): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const uploadedFile = fileEvent.uploadedFiles[0];
      const hasFileAlreadyBeenUploaded = !uploadedFile.file.size;

      if (hasFileAlreadyBeenUploaded) {
        await new Promise((resolve) => setTimeout(() => {
          resolve({});
        }, 1000));
      }
  
      const fileToBeDeletedIdx = correspondingDocument.uploadedFiles.findIndex((uploadedFile) => uploadedFile.id === fileEvent.uploadedFiles[0].id);
  
      correspondingDocument.uploadedFiles.splice(fileToBeDeletedIdx, 1);
    } finally {
      this.coreService.setIsLoading(false);
    }
  }
}
