import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { HeaderComponent } from './header.component';
import { BackdropModule } from '../backdrop/backdrop.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, RouterModule, MatIconModule, BackdropModule],
  exports: [HeaderComponent]
})
export class HeaderModule {}
