import { LoanDecision } from "./affiliate.types";
import { AuditActivity } from "./audit-activity.types";
import { BankAccount } from "./bank-account.types";
import { Comment } from "./comment.types";
import { ContractSignature } from "./contract-signature.types";
import { BRFTradesAndValues, CreditReport, Debt, ParsedCreditReport } from "./credit-report.types";
import { Offer, Partners } from "./offer.types";
import { PaymentSetting } from './payment-setting.types';
import { UnderwritingRule } from "./underwriting-rules.types";
import { UploadedDocument } from "./uploaded-document.types";
import { User } from "./user.types";

export interface UpdateApplication {
  requestedAmount: number;
  customer: {
    firstName: string;
    middleName?: string;
    lastName: string;
    suffix?: CustomerSuffixes;
    socialSecurityNumber?: string;
    email: string;
    phone: string;
    street: string;
    city: string;
    state: string;
    stateCode: string;
    zipCode: string;
    birthDate: string;
    monthlyIncome: number;
  };
};
export enum Source {
  MobilendEnrollmentFlow = 'Mobilend Enrollment Flow',
  SafestoneEnrollmentFlow = 'Safestone Enrollment Flow',
  Purl = 'Purl',
  AddApplication = 'Add Application',
  AgentApplication = 'Agent Application',
  WebHook = 'Webhook',
  API = 'API'
}
export interface Application {
  id: number;
  integrations?: Integration[];
  source: Source;
  auditActivities?: AuditActivity[];
  bankAccount?: BankAccount;
  comments?: Comment[];
  consents: UserConsent[];
  contractSignatures?: ContractSignature[];
  creditReport: CreditReport;
  creditScore?: number;
  creditRating?: CreditRating;
  customer: Customer;
  emailMessages?: EmailMessage[];
  employmentInformation?: EmploymentInformation;
  fundedAt?: string;
  heardFrom?: string;
  leadId: string;
  offers?: Offer[];
  paymentSetting?: PaymentSetting;
  requestedAmount: number;
  selectedOfferPartner?: Partners;
  status: ApplicationStatus;
  step: ApplicationSteps;
  uploadedDocuments?: UploadedDocument[];
  underwritingRules?: UnderwritingRule[];
  users: User[];
  declinedReason: string;
  declinedLetterUrl: string;
  campaignCode?: string;
  loanPurpose?: LoanPurpose;
  affiliate: string;
  userId: number;
  brfAmount?: number;
  brfLoanAmount?: number;
  brfTradesIndexes?: number[];
  createdAt: string;
  updatedAt: string;
}
interface Customer {
  id: number;
  applicationId: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phone: string;
  street?: string;
  city?: string;
  state?: string;
  stateCode?: string;
  zipCode?: string;
  socialSecurityNumber?: string;
  birthDate?: string;
  monthsAtAddress?: number;
  residenceType?: ResidenceType;
  monthlyIncome?: number;
  monthlyRent?: number;
  mobilePhone?: string;
  suffix?: string;
  remoteAddress?: string;
  dontSellPersonalInformation: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface Integration {
  id: number;
  name: IntegrationPartners;
  externalId: string;
  json: string;
  active: boolean;
}
export enum IntegrationPartners {
  Premlo = 'Premlo',
  Monevo = 'Monevo',
  Achieve = 'Achieve',
  FreedomFinancial = 'Freedom Financial'
}
export interface EmailMessage {
  id: number;
  application?: Application;
  applicationId?: number;
  message: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
}
interface EmploymentInformation {
  id: number;
  applicationId: number;
  employerName: string;
  workPhone?: string;
  incomeType: IncomeType;
  employerStatus: EmploymentStatus;
  paymentFrequency: PaymentFrequency;
  holidayPayments?: HolidayPayments;
  monthsAtEmployer: number;
  otherIncome?: string;
  createdAt: string;
  updatedAt: string;
}
interface UserConsent {
  id: number;
  signedAt: string;
  createdAt: string;
  updatedAt: string;
}
export interface ApplicationsDashboard {
  applications: Application[];
  resume: {
    currentMonth: Resume;
    lastMonth: Resume;
  };
}
interface Resume {
  freedomFinancial: number;
  achieve: number;
  mobilend: number;
  monevo: number;
  incomplete: number;
  enrollmentFlow: number;
}
export interface BalanceChart {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: number;
      };
    };
  };
}
export interface ApplicationsTotalDashboard {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: number;
      };
    };
  };
}
export interface GetApplicationsParams {
  limit?: number;
  page?: number;
  partners?: (Partners | null)[];
  search?: string;
  sortColumn?: string;
  sortOrder?: string;
  status?: ApplicationStatus[];
  affiliateId?: number;
  source?: Source;
  step?: ApplicationSteps;
  state?: string;
  minScore?: number;
  maxScore?: number;
}
export interface GetApplicationsResponse {
  applications: Application[];
  page: number;
  totalItems: number;
  limit: number;
  itemsPerPage: number;
  orderBy: string
  orderByColumn: string;
}
export interface FindApplicationResponse {
  application: Application;
  parsedReport: ParsedCreditReport;
  eligibleDebts: Debt[];
  BRFTradesAndValues?: BRFTradesAndValues;
  underwritingRules?: UnderwritingRule[];
}
export enum CustomerSuffixes {
  Jr = 'JR',
  Sr = 'SR',
  I = 'I',
  II = 'II',
  III = 'III',
  IV = 'IV'
}
export enum IncomeType {
  Investments = 'Investments',
  Retirement = 'Retirement',
  PublicAssistance = 'Public Assistance',
  Insurance = 'Insurance',
  FinancialAID = 'Financial AID',
  RegularAllowance = 'Regular allowance',
  SocialSecurity = 'Social Security',
  Pension = 'Pension',
  Disability = 'Disability',
  Other = 'Other'
}
export enum EmploymentStatus {
  Worker = 'Worker',
  Employee = 'Employee',
  SelfEmployee = 'Self Employee',
  Student = 'Student',
  Unemployed = 'Unemployed'
}
export enum PaymentFrequency {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  BiWeekly = 'Bi Weekly',
  BiMonthly = 'Bi Monthly',
  Other = 'Other'
}
enum HolidayPayments {
  Before = 'Before',
  After = 'After'
}

export enum ApplicationStatus {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Denied = 'Denied',
  Funded = 'Funded',
  Incomplete = 'Incomplete',
  Pending = 'Pending',
  Archived = 'Archived'
}
export enum ApplicationSteps {
  PersonalInformation = 'Personal Information',
  EmploymentInformation = 'Employment Information',
  ChoosePlan = 'Choose Plan',
  ConnectBank = 'Connect Bank',
  UploadDocuments = 'Upload Documents',
  SignContract = 'Sign Contract'
}
export enum GetApplicationsOrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}
export enum GetApplicationsSortColumns {
  Id = 'id',
  Name = 'name',
  RequestedAmount = 'requestedAmount',
  CreatedAt = 'createdAt',
  Email = 'email',
  Phone = 'phone'
}
export enum UpdateApplicationStatus {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Denied = 'Denied',
  Funded = 'Funded',
  Archived = 'Archived',
  Deleted = 'Deleted'
}
export interface CreateAccountDto {
  dontSellPersonalInformation?: boolean;
  email: string;
  firstName: string;
  heardFrom?: string;
  lastName: string;
  middleName?: string;
  phone: string;
}
export interface UpdateApplicationResponse { 
  decision: LoanDecision;
  applicationId: number;
  reason?: string;
}
export interface ChangeIncome {
  monthlyIncome: number ;
}
export interface CreateEmploymentInformatioStepDto {
  incomeType: string;
  otherIncome: string;
  employerStatus: EmploymentStatus;
  employerName: string;
  workPhone: string;
  paymentFrequency: PaymentFrequency;
  holidayPayments: HolidayPayments;
  monthsAtEmployer: number;
  creditReportConsent: boolean;
  electronicConsent: boolean;
  tcpaConsent: boolean;
  equifaxConsent: boolean;
}
export interface CreatePersonalInformationStepDto {
  requestedAmount: number;
  suffix: CustomerSuffixes;
  socialSecurityNumber: string;
  mobilePhone: string;
  street: string;
  monthsAtAddress: number;
  residenceType: ResidenceType;
  monthlyRent: number;
  city: string;
  state: string;
  stateCode: string;
  zipCode: string;
  birthDate: string;
  loanPurpose: LoanPurpose;
  creditRating: CreditRating;
  monthlyIncome: number;
  campaignCode?: string;
}
export enum LoanPurpose {
  DebtConsolidation = 'Debt Consolidation',
  HomeImprovement = 'Home Improvement',
  Pool = 'Pool',
  Solar = 'Solar',
  Vacation = 'Vacation',
  Travel = 'Travel',
  NewAutoPurchase = 'New Auto Purchase',
  LargePurchase = 'Large Purchase',
  PayOffCreditCards = 'Pay Off Credit Cards',
  StudentLoanRefinancing = 'Student Loan Refinancing',
  Education = 'Education',
  SpecialOccasion = 'Special Occasion',
  CosmeticProcedures = 'Cosmetic Procedures',
  MovingAndRelocation = 'Moving and Relocation',
  HouseholdExpenses = 'Household Expenses',
  Medical = 'Medical',
  Dental = 'Dental',
  Taxes = 'Taxes',
  Business = 'Business',
  Other = 'Other',
  FuneralExpenses = 'Funeral Expenses',
  AutoLoanRefinance = 'Auto Loan Refinance',
  UsedAutoPurchase = 'Used Auto Purchase',
  AutoLeaseBuyout = 'Auto Lease Buyout',
  Baby = 'Baby',
  AdoptionExpenses = 'Adoption Expenses',
  EmergencyExpenses = 'Emergency Expenses',
  Wedding = 'Wedding',
  Engagement = 'Engagement',
  AutoRepairs = 'Auto Repairs',
  EquipmentPurchase = 'Equipment Purchase'
}
export enum ResidenceType {
  HomeOwner = 'Home Owner',
  Rent = 'Rent',
  LiveWithFamily = 'Live with Family',
  Other = 'Other'
}

export enum CreditRating {
  Excellent = 'Excellent',
  Good = 'Good',
  Fair = 'Fair',
  Poor = 'Poor'
}

export interface PersonalInformationResponse {
  application: Application;
  token?: string;
}
