<h1>Communication Consent Agreement</h1>
<div class="row gy-2">
  <div class="col-md-4">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>Welcome to Mobilend.com</span>
    </div>
  </div>
  <div class="col-md-8">
    <p>
      — Consumer’s Consent:
      <br />
      By clicking “Continue”, “Go”, or “Register” this Communication Consent Agreement (“Agreement”) is made and entered into by and between you the Consumer (otherwise known as “Consumer”, “You”, and “Your”) and Kuber Financial, LLC dba Mobilend and its affiliates (the “Company”, “We”, “Us”, “Our”).
    </p>
    <p>
      Consumer agrees to receive information and documents relating to this Agreement and the Company. Additionally, Consumer agrees to receive information and documents from and/or related to unaffiliated third-party service providers and marketers (collectively “Service Providers”). Consumer may receive information via electronic mail, text message, facsimile, voicemail, phone, or artificial or pre-recorded telephonic messages, automatic dialing technology, and any other common electronic means (collectively “Electronic Communication”). Furthermore, You agree to receive Electronic Communication from Service Providers, Company, and/or Company’s affiliates, subsidiaries, parent company(ies), agents, vendors, lenders and their assignees. Accordingly, Consumer will notify Us immediately of any changes to Consumer’s contact information. Note you may optout from receiving Electronic Communication at any time in accordance with the Optout Option detailed below.
    </p>
    <p>
      — Optout Option:
      <br />
      You may revoke this consent at any time by contacting Company by emailing us at optout@mobilend.com  or calling us at 1-866-330-1669 or through any other reasonable means. We may also send an email to the email address that You utilize for an optout request in addition to any previous email You have provided.
    </p>
    <p>
      — Purpose of Communication:
      <br />
      Some of the purposes for calls and messages may include but are not limited to:
    </p>
    <p>
      1 &nbsp; Suspected fraud or identity theft;
      <br />
      2 &nbsp; Obtaining information;
      <br />
      3 &nbsp; Transactions on or servicing of your account;
      <br />
      4 &nbsp; Marketing unsecured consumer installment loans;
      <br />
      5 &nbsp; Debt settlement products and services;
      <br />
      6 &nbsp; Other related ancillary products and services;
      <br />
      7 &nbsp; Satisfaction surveys, consumer reviews, and focus groups.
    </p>
    <p>
      — Standard Data Rates Apply:
      <br />
      Consumer understands that all costs and data rates associated with the receipt, review, and use of Electronic Communications shall be those of Consumer, such as maintaining access to the Internet or paying for text messages. When You give Us Your home and/or mobile phone number, You are indicating that You are an authorized subscriber or a non-subscriber customary user of the number provided and that We have Your permission to contact You at that number or numbers (unless prohibited by applicable law), about Your program, a consumer loan, debt relief products and services, and/or other ancillary products and services. Your consent allows us to use text messaging, artificial or prerecorded voice messages and automatic dialing technology, for all purposes not prohibited by applicable law. Message and data rates may apply.
    </p>
  </div>
</div>