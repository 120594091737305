<div class="row flex-grow-1">
  <div class="col-md-6">
    <div class="left-side">
      <img src="../../../../assets/images/consolidating.png" alt="consolidating" />
    </div>
  </div>
  <div class="col-md-6">
    <div class="right-side">
      <div>
        <a routerLink="/home">
          <img src="../../../../assets/logos/mobilend-dark-logo.png" alt="mobilend logo" />
        </a>
      </div>
      <div>
        <router-outlet></router-outlet>
      </div>
      <div class="auth-footer-container">
        <div class="rectangle"></div>
        <span>© 2016-2023 Mobilend.com, a Kuber Financial, LLC product. All rights reserved.</span>
      </div>
    </div>
  </div>
</div>
