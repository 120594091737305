<app-header></app-header>
<div class="first-container">
  <div class="left-side">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>MOBILEND.COM</span>
    </div>
    <h1>We'd Love to Hear from You</h1>
  </div>
  <div class="right-side">
    <div>
      <h1>
        Say <span class="text-purple-100">Hello!</span>
      </h1>
      <h2>
        Send us a message using the contact form. 
        <br />
        Or use information below to reach us.
      </h2>
    </div>
    <form
      [formGroup]="contactForm"
      (submit)="handleContactFormSubmit(formDirective)"
      #formDirective="ngForm"
    >
      <div class="row gy-4 mb-3">
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker appearance="outline">
            <mat-label>Your Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error>
              {{ getErrorMessage('name', 'Your Name') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker appearance="outline">
            <mat-label>Your Email</mat-label>
            <input matInput formControlName="email" />
            <mat-error>
              {{ getErrorMessage('email', 'Your Email') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone" mask="(000) 000-0000" />
            <mat-error>
              {{ getErrorMessage('phone', 'Phone') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker appearance="outline">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject" />
            <mat-error>
              {{ getErrorMessage('subject', 'Subject') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field hideRequiredMarker appearance="outline">
            <mat-label>Your Message</mat-label>
            <textarea matInput formControlName="message"></textarea>
            <mat-error>
              {{ getErrorMessage('message', 'Your Message') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="mb-5 d-flex align-items-start">
        <mat-checkbox
          formControlName="shareDataConsent"
          color="primary"
          [ngClass]="{ 'mat-checkbox-invalid': isCheckboxInvalid }"
        ></mat-checkbox>
        <div>
          <p>
            I agree to sharing my personal profile and data with Mobilend so that I can receive information about Mobilend products and services as described in our Privacy Policy
          </p>
          <mat-hint *ngIf="isCheckboxInvalid">
            You must accept to continue
          </mat-hint>
        </div>
      </div>
      <div class="d-flex justify-content-center justify-content-lg-start">
        <app-button
          label="SUBMIT"
          type="submit"
          class="flex-grow-1 flex-sm-grow-0"
        ></app-button>
      </div>
    </form>
  </div>
</div>
<div class="second-container">
  <div class="
    flex-grow-1
    d-flex
    flex-column
    align-items-center
    align-items-lg-end
    justify-content-center
  ">
    <div class="title-overview">
      <span>PLANS</span>
      <div class="rectangle"></div>
    </div>
    <h1>Contacts</h1>
  </div>
  <div class="contact-information-box">
    <span class="fw-bold text-end">
      <a
        href="https://twitter.com/MobilendInc"
        target="_blank"
        class="text-decoration-none"
      >
        twitter
      </a>
      /
      <a
        href="https://www.linkedin.com/company/mobilend-com"
        class="text-decoration-none"
        target="_blank"
      >
        linkedin
      </a>
    </span>
    <div class="contact-information-box-body">
      <span class="text-uppercase fw-bold">
        for everyone, everywhere…
      </span>
      <div class="row gy-4">
        <div class="col-md-4">
          <div class="d-flex gap-2 position-relative">
            <div class="rectangle"></div>
            <span>400 Spectrum Center Drive, Suite 350 Irvine, CA 92618</span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex flex-column gap-4">
            <div class="d-flex gap-2 position-relative">
              <div class="rectangle"></div>
              <span>(866) 330-1669</span>
            </div>
            <div class="d-flex gap-2 position-relative">
              <div class="rectangle"></div>
              <span>support@mobilend.com</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex gap-2 position-relative">
            <div class="rectangle"></div>
            <p>
              Open Monday to Friday from 8am to 5pm (Pacific Standard Time).
              <br />
              <span class="fw-bold">
                Excluding holidays.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <p>
      For partnership and corporate enquiries, please email <span class="text-cyan-100">info@mobilend.com.</span>
    </p>
  </div>
</div>
<app-footer></app-footer>