import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

import { CoreService } from './core/services/core.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public isLoading = this.coreService.isLoading$;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private coreService: CoreService,
    private renderer: Renderer2,
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'VirtualPageView',
          pageUrl: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  public ngOnInit(): void {
    this.isLoading.subscribe((isLoading) => {
      if (isLoading) {
        this.renderer.addClass(this.document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(this.document.body, 'overflow-hidden');
      }
    });
  }
}
