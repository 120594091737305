import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import {
  Application,
  GetApplicationsParams,
  ApplicationsDashboard,
  ApplicationsTotalDashboard,
  BalanceChart,
  GetApplicationsResponse,
  UpdateApplicationStatus,
  UpdateApplication,
  UpdateApplicationResponse,
  ChangeIncome,
  CreateEmploymentInformatioStepDto,
  CreatePersonalInformationStepDto,
  PersonalInformationResponse,
  Integration,
  CreateAccountDto
} from "../types/application.types";
import { environment } from "../../../environments/environment";
import { BRFTradesAndValues } from "../types/credit-report.types";
import { Offer } from "../types/offer.types";

export interface SaveSelectedTradesResponse {
  BRFTradesAndValues: BRFTradesAndValues;
  offers: Offer[];
}

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  private baseUrl = `${environment.apiUrl}/applications`;

  constructor(private http: HttpClient) {}

  public createAccount(data: CreateAccountDto, token?: string): Observable<PersonalInformationResponse> {
    return this.http.post<PersonalInformationResponse>(
      `${this.baseUrl}/account`,
      data,
      {
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public createPersonalInformationV2(applicationId: number, data: CreatePersonalInformationStepDto, token?: string): Observable<Application> {
    return this.http.post<Application>(
      `${this.baseUrl}/${applicationId}/personal-information`, 
      data, 
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public createPersonalInformation(data: CreatePersonalInformationStepDto, token?: string): Observable<PersonalInformationResponse> {
    return this.http.post<PersonalInformationResponse>(
      `${this.baseUrl}/personal-information`, 
      data, 
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public createEmploymentInformation(applicationId: number, data: CreateEmploymentInformatioStepDto, token?: string): Observable<Application> {
    return this.http.post<Application>(
      `${this.baseUrl}/${applicationId}/employment-information`, 
      data, 
      { 
        withCredentials: true, 
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined 
      }
    );
  }

  public sendApplicationToCrm(applicationId: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/${applicationId}/crm`, null, { withCredentials: true });
  }

  public getApplications({
    status,
    page,
    partners,
    limit,
    sortColumn,
    sortOrder,
    search,
    affiliateId,
    source,
    step,
    state,
    minScore,
    maxScore
  }: GetApplicationsParams): Observable<GetApplicationsResponse> {
    let params = new HttpParams()
      .set('page', page || 0)
      .set('limit', limit || 10)
      .set('order_by', sortOrder || 'DESC')
      .set('order_by_column', sortColumn || 'id');

    if (status?.length) {
      status.forEach((status) => {
        params = params.append('status[]', status);
      });
    }

    if (partners?.length) {
      partners.forEach((partner) => {
        params = params.append('partners[]', String(partner));
      });
    }

    if (search) {
      params = params.set('search', search);
    }

    if (affiliateId) {
      params = params.set('affiliateId', affiliateId);
    }

    if (source) {
      params = params.set('source', source);
    }

    if (step) {
      params = params.set('step', step);
    }

    if (state) {
      params = params.set('state', state);
    }

    if (minScore) {
      params = params.set('minScore', minScore);
    }

    if (maxScore) {
      params = params.set('maxScore', maxScore);
    }

    return this.http.get<GetApplicationsResponse>(this.baseUrl, { params, withCredentials: true });
  }

  public getDashboardApplications(): Observable<ApplicationsDashboard> {
    return this.http.get<ApplicationsDashboard>(`${this.baseUrl}/dashboard/compare/months`, { withCredentials: true });
  }

  public getDashboardTotalApplications(): Observable<ApplicationsTotalDashboard> {
    return this.http.get<ApplicationsTotalDashboard>(
      `${this.baseUrl}/dashboard/count/leads`,
      { withCredentials: true }
    );
  }

  public getBalanceChart(): Observable<BalanceChart> {
    return this.http.get<BalanceChart>(`${this.baseUrl}/dashboard/count/balance`, { withCredentials: true });
  }  

  public findApplication(applicationId: number): Observable<Application> {
    return this.http.get<Application>(`${this.baseUrl}/${applicationId}`, { withCredentials: true });
  }

  public updateApplicationStatus(applicationId: number, status: UpdateApplicationStatus): Observable<Application> {
    return this.http.patch<Application>(
      `${this.baseUrl}/${applicationId}/status`,
      { status },
      { withCredentials: true }
    );
  }

  public updateApplication(applicationId: number, data: UpdateApplication): Observable<UpdateApplicationResponse> {
    return this.http.patch<UpdateApplicationResponse>(
      `${this.baseUrl}/${applicationId}`,
      data,
      { withCredentials: true }
    );
  }

  public deleteApplication(applicationId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/${applicationId}`,
      { withCredentials: true }
    );
  }

  public getDeniedLetterUrl(applicationId: number): Observable<Blob> {
    return this.http.get<Blob>(
      `${this.baseUrl}/${applicationId}/denied-letter`, { responseType: 'blob' as 'json', withCredentials: true }
    );
  }

  public reRunApplication(applicationId: number): Observable<Application> {
    return this.http.patch<Application>(
      `${this.baseUrl}/${applicationId}/rerun-application`, null, { withCredentials: true }
    );
  }

  public sendToPremlo(applicationId: number): Observable<Integration> {
    return this.http.post<Integration>(`${this.baseUrl}/${applicationId}/premlo`, null, { withCredentials: true });
  }

  public changeIncome(applicationId: number, data: ChangeIncome): Observable<Application> {
    return this.http.patch<Application>(
      `${this.baseUrl}/${applicationId}/change-income`, data, { withCredentials: true }
    );
  }

  public saveSelectedTrades(
    applicationId: number,
    selectedTrades: number[]
  ): Observable<SaveSelectedTradesResponse> {
    return this.http.patch<SaveSelectedTradesResponse>(
      `${this.baseUrl}/${applicationId}/rerun-application/brf`,
      { brfTradesIndexesSelected: selectedTrades },
      { withCredentials: true }
    );
  }

  public updateApplicationEmail(applicationId: number, data: { email: string }): Observable<Application> {
    return this.http.patch<Application>(
      `${this.baseUrl}/${applicationId}/email`, data, { withCredentials: true }
    );
  }

  public fund(applicationId: number): Observable<Application> {
    return this.http.post<Application>(
      `${this.baseUrl}/${applicationId}/fund`,
      null,
      { withCredentials: true }
    );
  }
}
