<app-card class="p-3 d-flex flex-column gap-4">
  <div>
    <iframe class="sm_widget"></iframe>
    <img
      src="https://track.supermoney.com/aff_i?offer_id=1961&file_id=702&aff_id=1938&partner=kuber-financial"
      width="0"
      height="0"
      style="position:absolute;visibility:hidden;"
      border="0"
    />
  </div>
  <div class="d-flex justify-content-end">
    <app-button
      *ngIf="renderContinueButton"
      label="CONTINUE WITH SUPERMONEY"
      svgIcon="semiarrow-right"
      (click)="handleContinueButtonClick()"
      [disabled]="disabledContinueButton"
    ></app-button>
  </div>
</app-card>

<ng-container>
  <ng-template #confirmContinueApplicationModalContentTemplate>
    <span>
      Please make sure you have finished your Supermoney application before proceeding
    </span>
  </ng-template>

  <ng-template #confirmContinueApplicationModalActionsTemplate>
    <div class="d-flex align-items-center gap-3 justify-content-end">
      <button
        mat-button
        color="primary"
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        mat-button
        color="primary"
        (click)="handleModalConfirmButtonClick()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</ng-container>