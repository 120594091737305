<div class="first-container">
  <app-header></app-header>
  <div class="d-flex justify-content-center title-container">
    <div class="d-flex flex-column align-items-center align-items-sm-start">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>MOBILEND.COM</span>
      </div>
      <h1 class="text-center mt-3 mb-2">
        Making Finance <span class="text-purple-100">Simple</span>
      </h1>
      <h2 class="text-center">
        We make everyday financial needs simple and effective.
      </h2>
    </div>
  </div>
  <div class="apply-button-container">
    <app-button
      label="APPLY"
      svgIcon="semiarrow-right"
      routerLink="/enrollment"
    ></app-button>
    <span>Applying will not affect your credit score</span>
  </div>
  <div class="logos-carousel-container">
    <div class="logos-carousel">
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
    </div>
  </div>
</div>
<div class="
  d-flex
  flex-column
  flex-lg-row
  gap-5
  second-container
">
  <div class="
    d-flex
    flex-column
    align-items-center
    align-items-lg-start
    second-container-left-side
  ">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>MOBILEND.COM</span>
    </div>
    <h1 class="mt-1 mb-5 text-center text-lg-start">
      <span class="text-cyan-100">Making Finance</span> Simple
    </h1>
    <app-button
      label="CALL US"
      svgIcon="semiarrow-right"
      phone="tel:1-866-330-1669"
    ></app-button>
  </div>
  <div class="second-container-right-side">
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-orange-200">$0</span>
          <p>
            <span class="fw-bold">Payment Transaction Cost.</span> We don't charge our customers with any payment transaction fees when we fund our loans nor do we charge any fees on loan repayment transactions.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-green-100">
            <span class="from-text">from</span> 5.99%
          </span>
          <p>
            <span class="fw-bold">Interest Rate.</span> Our debt consolidation loan helps our customers to get ahead in life. We pay off our customers creditors directly to help our customers save.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-cyan-100">Free</span>
          <p>
            <span class="fw-bold">Consultation.</span> Give us a call to see if you qualify for a debt consolidation loan. Our financial product pays creditors directly so you can truly benefit from savings.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-purple-100">2-6.99%</span>
          <p>
            <span class="fw-bold">Application Fee.</span> Our staff works with you and your creditors in the utmost professional manner. Let our hard work translate into savings for you today.   
          </p>
        </div>
      </div>
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-yellow-200">$0</span>
          <p>
            <span class="fw-bold">No transaction fee.</span> Sometimes the pay off transactions are complicated, but let us help on the complexity and pass the savings to you. We don’t charge our customers any transaction fees.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="fee-card">
          <span class="fee-card-title text-blue-200">0%</span>
          <p>
            <span class="fw-bold">Managed account fee.</span> We don’t charge our clients any account management fees. We provide a world class client portal. It’s absolutely free to use.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="
  d-flex
  flex-column
  flex-xxl-row
  gap-4
  third-container"
>
  <div class="plan-card">
    <div class="plan-card-header">
      Conservative Plan
    </div>
    <div class="
      d-flex
      flex-column
      align-items-center
      align-items-xxl-start
      plan-card-content
    ">
      <img src="../../../assets/images/conservative-plan.png" alt="conservative plan" />
      <p>
        Take a longer period to pay off your high rate debt with a longer term debt consolidation loan. The goal of this plan is to keep your payment low and to give you an opportunity to start saving today.
      </p>
      <span>60 months to pay off</span>
    </div>
  </div>
  <div class="plan-card">
    <div class="plan-card-header">
      Dynamic Plan
    </div>
    <div class="
      d-flex
      flex-column
      align-items-center
      align-items-xxl-start
      plan-card-content
    ">
      <img src="../../../assets/images/dynamic-plan.png" alt="dynamic plan" />
      <p>
        If you have $10,000+ in credit card debt, we can quickly convert that into a simple interest debt consolidation loan. You can pay off a revolving credit card balance with our plan.
      </p>
      <span>Starting at 5.99% interest</span>
    </div>
  </div>
  <div class="plan-card">
    <div class="plan-card-header">
      Individual Plan
    </div>
    <div class="
      d-flex
      flex-column
      align-items-center
      align-items-xxl-start
      plan-card-content
    ">
      <img src="../../../assets/images/individual-plan.png" alt="individual plan" />
      <p>
        You can enjoy many of our other financial products. Call us and we can work with you to select the finance product to place you on a path to be debt free.
      </p>
      <span>Flexible payment terms</span>
    </div>
  </div>
  <div class="
    d-flex
    flex-column
    align-items-center
    align-items-xxl-end
    justify-content-end
    py-5
  ">
    <div class="title-overview">
      <span>PLANS</span>
      <div class="rectangle"></div>
    </div>
    <h1 class="text-center text-md-end mt-1 mb-4">
      <span class="text-blue-200">Simple</span> and <span class="text-purple-100">Flexible</span>
    </h1>
    <h2 class="text-center text-xxl-start">
      Our payment plans are simple and straightforward. We have plans ranging from 12 to 60 months.
    </h2>
  </div>
</div>
<div class="fourth-container">
  <div class="
    d-flex
    flex-column
    align-items-center
    align-items-xxl-start
  ">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>MOBILEND.COM</span>
    </div>
    <h1 class="text-center mt-3 mb-2">
      Our New <span class="text-purple-100">Lending Platform</span>
    </h1>
    <h2 class="text-center text-xxl-start">
      Award winning platform lets you easily manage your finances and apply to get savings anywhere in minutes.
    </h2>
  </div>
  <div class="lending-platform-container">
    <div class="lending-platform-content">
      <div class="blue-line"></div>
      <div class="credit-card-partners">
        <div class="d-flex flex-column align-items-xl-end gap-3">
          <span class="global-partners-label">
            GLOBAL PARTNERS
          </span>
          <p class="fw-bold m-0 text-xl-end">
            Our clients are utilizing their loan proceeds to settle substantial credit card balances, enabling them to initiate the process of alleviating their high-interest debt burden.
            <br />
            Whether you have $12,000 in credit card debt or a $6,000 revolving balance our staff at Mobilend.com can assist you explore your loan options.
          </p>
        </div>
      </div>
    </div>
    <img src="../../../assets/images/iphone.png" alt="iphone" />
  </div>
</div>
<div class="
  d-flex
  flex-column
  flex-xxl-row
  gap-4
  align-items-center
  fifth-container
">
  <div>
    <div class="d-flex position-relative">
      <img src="../../../assets/images/ipad.png" alt="ipad" />
      <div class="apps-container">
        <div class="zoom-container">
          <img src="../../../assets/icons/camera.svg" alt="camera" />
        </div>
        <div class="skype-container">
          <img src="../../../assets/logos/skype.png" alt="skype logo" />
        </div>
        <div class="google-meet-container">
          <img src="../../../assets/logos/google-meet.png" alt="google meet logo" />
        </div>
      </div>
      <div class="chat-container">
        <div class="blue-line"></div>
        <div class="p-3 d-flex flex-column gap-3">
          <span class="fw-bold">
            Hey there 👋 Looking to learn more about saving?
          </span>
          <div class="d-flex align-items-center">
            <div class="d-flex position-relative">
              <img src="../../../assets/images/avatar.png" alt="avatar">
              <div class="no-avatar">J</div>
            </div>
            <div class="ps-3 pe-2 flex-grow-1 d-flex">
              <div class="audio-snippet-heard"></div>
              <div class="audio-snippet-not-heard"></div>
            </div>
            <span>5:21</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="
    d-flex
    flex-column
    align-items-center
    align-items-xxl-end
    pt-xxl-7
  ">
    <div class="title-overview">
      <span>MOBILEND</span>
      <div class="rectangle"></div>
    </div>
    <h1 class="text-center text-xxl-end mt-3 mb-2">
      <span class="text-blue-200">Start Saving</span> in No Time
    </h1>
    <h2 class="text-center text-xxl-end mb-5">
      It takes only a few minutes to speak to one of our knowledgeable consumer advocates. Your path to great savings is just a few minutes away. It is that easy!
    </h2>
    <app-button
      label="CALL US"
      svgIcon="semiarrow-right"
      phone="tel:1-866-330-1669"
    ></app-button>
  </div>
</div>
<div class="d-flex flex-column sixth-container">
  <div class="
    d-flex
    flex-column
    align-items-center
    align-items-xxl-start
  ">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>MOBILEND</span>
    </div>
    <h1 class="text-center text-xxl-start mt-1 mb-3 text-blue-purple-gradient">
      We Are Available In Many States
    </h1>
    <h2 class="text-center text-xxl-start fw-semibold">
      We are based in Irvine, California proudly servicing consumers from 6 states including: California, Alabama, Idaho, South Dakota, Missouri and Utah. More States are coming soon. Please stay tuned.
    </h2>
  </div>
  <div class="
    d-flex
    flex-column
    flex-sm-row
    align-items-center
    justify-content-center
    gap-7
  ">
    <span class="phone-number">(866) 330-1669</span>
    <app-button
      label="CALL US TODAY"
      svgIcon="semiarrow-right"
      phone="tel:1-866-330-1669"
    ></app-button>
  </div>
</div>
<app-footer></app-footer>