<div>
  <h1>Choose Your Offer</h1>
</div>
<div *ngIf="!(application.creditScore && selectableOffers.length)">
  <p>We are unable to make a decision with the information provided. One of our loan specialists will be in contact with you shortly.</p>
  <p>To speak with us now, please call <a href="tel:1-866-330-1669">866-330-1669</a>.</p>
</div>
<div class="row gy-4 offers-container">
  <ng-container *ngIf="selectableOffers.length">
    <div
      *ngFor="let offer of selectableOffers"
      class="col-lg-6"
    >
      <div [ngClass]="{
        'offer-card': true,
        'selected-offer-card': offer.id === selectedOffer?.id
      }">
        <div class="offer-card-header position-relative">
          <span 
            class="text-success d-flex align-items-center position-absolute top-0 mt-2 fw-bold" 
            *ngIf="offer.partner === 'Monevo'"
          >
            <mat-icon class="h-100" [ngStyle]="{'font-size':'16px'}">
              check
            </mat-icon>
            <span>{{ offer.status }}</span>
          </span>
          <span 
            class="d-flex align-items-center position-absolute top-0 end-0 mt-2 me-2 fw-bold" 
            *ngIf="offer.partner === 'Monevo' && (offer.type === 'Personal Loan' || offer.type === 'Secured Loan')"
          >
            <span class="text-decoration-underline">{{ offer.type === 'Personal Loan' ? 'Unsecured' : 'Secured' }}</span>
          </span>
          <span 
            class="text-success d-flex align-items-center position-absolute top-0 mt-2 fw-bold" 
            *ngIf="offer.partner === 'Mobilend' || offer.partner === 'Achieve'"
          >
            <mat-icon class="h-100" [ngStyle]="{'font-size':'16px'}">
              check
            </mat-icon>
            <span>Pre-qualified</span>
          </span>
          <span 
            role="button"
            *ngIf="offer.disclosures"
            class="d-flex align-items-center position-absolute bottom-0 end-0 p-2" 
            (click)="handleMonevoOfferDisclosuresClick(offer)"
            [ngStyle]="{'font-size':'10px'}"
          >
            <span>{{ offer.lenderName }} Terms and Disclosures</span>
            <mat-icon
              class="h-100 ms-1"
              [ngStyle]="{'font-size': '12px'}"
            >
              description
            </mat-icon>
          </span>
          <div class="offer-card-title position-relative">
            <div *ngIf="offer.caveatTitle" class="position-absolute bottom-0 end-0 d-flex align-items-center justify-content-start pb-3">
              <span 
                [ngStyle]="{'font-size':'10px'}"
                class="text-black fw-bold"
              >
                {{ offer.caveatTitle }}
              </span>
              <mat-icon 
                class="h-100 ms-1"
                [ngStyle]="{'font-size':'12px'}"
                matTooltipPosition="right"
                matTooltipClass="primary-tooltip"
                matTooltip="{{ offer.caveatDescription }}"  
              >
                help
              </mat-icon>
            </div>
            <div
              role="button"
              *ngIf="offer.partner === 'Monevo'" 
              class="position-absolute bottom-0 end-0 d-flex align-items-center justify-content-start pt-2"
              (click)="handleMonevoOfferDisclaimerClick(offer)"
            >
              <span 
                [ngStyle]="{'font-size':'10px'}"
                class="text-black fw-bold"
              >
                Disclaimer
              </span>
              <mat-icon class="h-100 ms-1" [ngStyle]="{'font-size':'12px'}">
                info
              </mat-icon>
            </div>
            <span class="mt-2">
              Lending offer
              <br />
              #{{ offer.number }}
            </span>
            <div class="flex-grow-1 text-end">
              <img
                width="100"
                height="100"
                [src]="offer.iconPath"
                class="img-fluid"
                alt="Company logo"
              />
            </div>
          </div>
          <div *ngIf="offer.partner !== 'Monevo'" class="offer-card-description">
            <span>Flexible loan options</span>
            <span>Fast Funding</span>
            <span>No prepayment</span>
            <span>Flood monthly payments</span>
          </div>
          <ng-container *ngIf="offer.partner === 'Monevo'">
            <div 
              class="offer-card-description"
            >
              <span *ngFor="let feature of offer.featuresDescription">
                {{ feature }}
              </span>
            </div>
          </ng-container>
          <svg xmlns="http://www.w3.org/2000/svg" width="374" height="188" viewBox="0 0 374 188" fill="none">
            <path opacity="0.3" d="M185.142 149.936C58.4673 104.872 58.2053 197.193 0 187.244V0H374V104.545C374 125.686 323.225 199.058 185.142 149.936Z" fill="url(#paint0_linear_2_3754)"/>
            <defs>
            <linearGradient id="paint0_linear_2_3754" x1="1.03386e-05" y1="89" x2="374.628" y2="197.51" gradientUnits="userSpaceOnUse">
            <stop stop-color="#909191"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="offer-card-body">
          <div class="offer-data">
            <div class="d-flex justify-content-between">
              <span class="text-grey-500">
                Loan amount:
              </span>
              <span class="text-purple-100">
                {{ (offer.amount | currency:'USD':'symbol':'1.2-2' ) }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-grey-500">
                Loan term:
              </span>
              <span class="text-purple-100">
                {{ offer.term }} months
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-grey-500">
                {{ offer.partner === 'Monevo' ? 'APR' : 'Interest Rate' }}:
              </span>
              <span class="text-purple-100">
                {{ offer.partner === 'Monevo' ? offer.apr + '%' : ((offer.interestRate! / 100) | percent:'1.0-2') }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="text-grey-500">
                Est. total repayment:
              </span>
              <span class="text-purple-100">
                {{ offer.term * offer.monthlyPayment | currency:'USD':'symbol':'1.2-2' }}
              </span>
            </div>
          </div>
          <div class="offer-monthly-payment">
            <span>Monthly Payment</span>
            <span class="monthly-payment-value">
              {{ offer.monthlyPayment | currency:'USD':'symbol':'1.2-2' }}
            </span>
          </div>
        </div>
        <div class="offer-card-footer">
          <app-button
            label="SELECT OFFER"
            (click)="handleSelectOfferButtonClick(offer)"
          ></app-button>
        </div>
      </div>
      <ng-template #showDisclosuresModalContentTemplate let-modalContent>
        <div style="font-size: 0.875rem">
          <h3 class="fw-bold">Terms and Disclosures</h3>
          <p>{{ modalContent.disclosures }}</p>

          <h3 class="fw-bold">Personal Loan Rate</h3>
          <p>
            Personal loan rate examples (for illustrative purposes only): a $10,000 loan at 4.89% APR with a term of 3 years would
            result in 36 monthly payments of $299 (Total repayable : $10,772) and a $10,000 loan at 9.99% APR with a term of 5 years
            would result in 60 monthly payments of $201.81 (Total repayable : $12,108.60).            
            Each state has specific rules and regulations that govern lending partners. The amount you can borrow, the APR, and
            repayment term are based on your state’s laws, the lending partner, and your creditworthiness.            
            This website will submit the information you provide to lending partners, who will first use a soft credit check(s) to
            assess your eligibility for a
            loan. There is no guarantee you will be presented with any personalized loan offers, or that upon presentation of any
            personalized loan offers you will qualify for the rates, fees, or terms shown on this site. Providing your information
            on this website does not guarantee that you will be approved for a loan.
            If you receive a loan offer, it is imperative that you review the lending partners terms and conditions before
            proceeding with an application for a loan and please note, that at the point of application with the lending partner you
            may be subject to a full credit check.
          </p>
        </div>
      </ng-template>
      <ng-template #showDisclosuresModalActionsTemplate>
        <div class="d-flex align-items-center justify-content-end gap-4">
          <app-button
            variant="outlined"
            label="Close"
            size="small"
            (click)="this.modalRef!.close()"
          ></app-button>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <app-supermoney-offers
    *ngIf="application.creditScore"
    [application]="application"
    (continueButtonClick)="handleSupermoneyContinueButtonClick()"
  ></app-supermoney-offers>
</div>
<div class="
  d-flex
  justify-content-center
  justify-content-lg-end
">
  <div class="
    d-flex
    flex-column
    flex-sm-row
    flex-md-column
    flex-lg-row
    gap-4
  ">
    <app-button
      variant="outlined"
      label="BACK"
      (click)="handleBackButtonClick()"
    ></app-button>
    <app-button
      label="CONTINUE"
      svgIcon="semiarrow-right"
      (click)="handleContinueButtonClick()"
      [disabled]="!this.selectedOffer"
    ></app-button>
  </div>
</div>

<ng-container>
  <ng-template #confirmRedirectingModalContentTemplate>
    <div style="font-size: 0.875rem; font-weight: 700;">
      <p>
        You will be redirected in order to finish your application.
      </p>
      <p>
        You can go back to this page when you finish your application and click on the "Continue" button.
      </p>
    </div>
  </ng-template>
  <ng-template #confirmRedirectingModalActionsTemplate let-context>
    <div class="d-flex align-items-center justify-content-end gap-4">
      <app-button
        variant="outlined"
        label="Cancel"
        size="small"
        (click)="closeModal()"
      ></app-button>
      <app-button
        label="OK"
        size="small"
        (click)="handleModalConfirmButtonClick(context)"
      ></app-button>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template #confirmResetApplicationModalContentTemplate>
    <div style="font-size: 0.875rem; font-weight: 700;">
      <p>
        You will be redirected to the home page in order to create a new application.
      </p>
      <p>
        You can continue this application later in the customer dashboard by following the email instructions sent to you.
      </p>
    </div>
  </ng-template>
  <ng-template #confirmResetApplicationModalActionsTemplate>
    <div class="d-flex align-items-center justify-content-end gap-4">
      <app-button
        variant="outlined"
        label="Cancel"
        size="small"
        (click)="closeModal()"
      ></app-button>
      <app-button
        label="OK"
        size="small"
        (click)="handleResetModalConfirmButtonClick()"
      ></app-button>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template #showDisclaimerModalContentTemplate>
    <div style="font-size: 0.875rem">
      <h3 class="fw-bold pb-2">Disclaimer</h3>
      <p>
        The offers above that have been produced by the credit eligibility service are based on the information you provided
        when completing the request and the information in your credit file.        
        The offers are conditional and are subject to change at the point of proceeding with the credit provider, after
        verification of the information you provided, as well as other conditions as determined by the credit provider you
        proceed with and your state’s laws.        
        Please remember that your credit was not affected by checking your rates while using this service. You are under no
        obligation to proceed with any of the offers you have received. At the point of application with the credit provider,
        you may be subject to a full credit check.        
        You may receive a ‘Notice of Adverse Action’ from credit providers who declined your request for credit explaining why
        you didn’t get an offer.        
        You may receive email, paper mail, and phone (including SMS) communications from the credit providers you have received
        offers from. To opt out at any time please contact them directly.
      </p>
    </div>
  </ng-template>
  <ng-template #showDisclaimerModalActionsTemplate>
    <div class="d-flex align-items-center justify-content-end gap-4">
      <app-button
        variant="outlined"
        label="Close"
        size="small"
        (click)="this.modalRef!.close()"
      ></app-button>
    </div>
  </ng-template>
</ng-container>
