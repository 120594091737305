import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ContractSignature } from '../../../../../core/types/contract-signature.types';
import { CoreService } from '../../../../../core/services/core.service';
import { ContractSignatureService } from '../../../../../core/services/contract-signature.service';

interface ModalContext {
  contractSignature: ContractSignature;
  action: 'approve' | 'reject';
}

@Component({
  selector: 'app-update-signature-status-modal',
  templateUrl: './update-signature-status-modal.component.html'
})
export class UpdateSignatureStatusModalComponent {
  @Output() public cancel: EventEmitter<void> = new EventEmitter();
  @Output() public updateStatus: EventEmitter<ContractSignature> = new EventEmitter();
  @ViewChild('updateSignatureStatusModalContentTemplate')
  public updateSignatureStatusModalContentTemplate: TemplateRef<any>;
  @ViewChild('updateSignatureStatusModalActionsTemplate')
  public updateSignatureStatusModalActionsTemplate: TemplateRef<any>;
  public rejectReason = new FormControl('');

  constructor(
    private toastrService: ToastrService,
    private coreService: CoreService,
    private contractSignatureService: ContractSignatureService
  ) {}

  public handleCancelButtonClick(): void {
    this.cancel.emit();
  }

  public handleConfirmButtonClick({ contractSignature, action }: ModalContext): void {
    const status = action === 'approve' ? 'approved' : 'rejected';

    this.coreService.setIsLoading(true);
    this.contractSignatureService
      .updateAgreementStatus(
        contractSignature.applicationId,
        contractSignature.id,
        { status, rejectReason: this.rejectReason.value || undefined }
      )
      .subscribe((contractSignature) => {
        this.toastrService.success(
          `Agreement successfully ${status}`,
          undefined,
          { positionClass: "toast-custom-bottom-center" }
        );
        this.updateStatus.emit(contractSignature);
        this.coreService.setIsLoading(false);
      });
  }
}
