import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ContactUsComponent } from './contact-us.component';
import { SharedModule } from '../../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [ContactUsComponent],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatCheckboxModule, MatFormFieldModule, SharedModule]
})
export class ContactUsModule {}
