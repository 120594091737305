import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Application, ApplicationStatus } from "../../../core/types/application.types";
import { ModalComponent } from '../modal/modal.component';
import { AuthService } from '../../../core/services/auth.service';
import { LoggedUser } from '../../../core/types/auth.types';
import { Roles } from '../../../core/types/role.types';

@Component({
  selector: 'app-supermoney-offers',
  templateUrl: './supermoney-offers.component.html',
  styleUrls: ['./supermoney-offers.component.scss']
})
export class SupermoneyOffersComponent implements OnInit, AfterViewInit {
  @Input() public application: Application;
  @Output() public continueButtonClick: EventEmitter<void> = new EventEmitter();
  @ViewChild('confirmContinueApplicationModalContentTemplate')
  public confirmContinueApplicationModalContentTemplate: TemplateRef<any>;
  @ViewChild('confirmContinueApplicationModalActionsTemplate')
  public confirmContinueApplicationModalActionsTemplate: TemplateRef<any>;
  public disabledContinueButton: boolean = true;
  public modalRef?: MatDialogRef<ModalComponent>;
  private stateCodes: Map<string, number> = new Map([
    ['Alaska', 43],
    ['Alabama', 44],
    ['Arkansas', 45],
    ['Arizona', 47],
    ['California', 48],
    ['Colorado', 49],
    ['Connecticut', 50],
    ['District of Columbia', 51],
    ['Delaware', 52],
    ['Florida', 53],
    ['Georgia', 55],
    ['Hawaii', 57],
    ['Iowa', 58],
    ['Idaho', 59],
    ['Illinois', 60],
    ['Indiana', 61],
    ['Kansas', 62],
    ['Kentucky', 63],
    ['Louisiana', 64],
    ['Massachusetts', 65],
    ['Maryland', 66],
    ['Maine', 67],
    ['Michigan', 69],
    ['Minnesota', 70],
    ['Missouri', 71],
    ['Mississippi', 73],
    ['Montana', 74],
    ['North Carolina', 75],
    ['North Dakota', 76],
    ['Nebraska', 77],
    ['New Hampshire', 78],
    ['New Jersey', 79],
    ['New Mexico', 80],
    ['Nevada', 81],
    ['New York', 82],
    ['Ohio', 83],
    ['Oklahoma', 84],
    ['Oregon', 85],
    ['Pennsylvania', 86],
    ['Rhode Island', 89],
    ['South Carolina', 90],
    ['South Dakota', 91],
    ['Tennessee', 92],
    ['Texas', 93],
    ['Utah', 94],
    ['Virginia', 95],
    ['Vermont', 97],
    ['Washington', 98],
    ['Wisconsin', 99],
    ['West Virginia', 100],
    ['Wyoming', 101]
  ]);
  private loggedUser?: LoggedUser;

  constructor(
    @Inject('application') @Optional() private injectedApplication: Application | null,
    @Inject('continueButtonClick') @Optional() private injectedContinueButtonClickHandler: () => void | null,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    if (this.injectedApplication) {
      this.application = this.injectedApplication;
    }
  }

  public get renderContinueButton(): boolean {
    return this.application.status === ApplicationStatus.Incomplete || this.loggedUser?.role === Roles.Admin;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser() || undefined;
    (window as any).smOptions = (window as any).smOptions || {
      primaryColor: "",
      btnTextColor: "",
      linkTextColor: "",
      accentColor: "",
      ratingPositiveColor: "",
      ratingNeutralColor: "",
      ratingNegativeColor: "",
      filters: {
        show: '1',
        values: {
          loan_amount: this.application.requestedAmount,
          credit_score: this.application.creditScore,
          states_offered: this.stateCodes.get(this.application.customer.state || '')
        }
      },
      prefill: {
        address1: this.application.customer.street,
        annualIncome: this.application.customer.monthlyIncome ? this.application.customer.monthlyIncome * 12 : undefined,
        cityPrimary: this.application.customer.city,
        creditScore: this.application.creditScore,
        dob: this.application.customer.birthDate,
        email: this.application.customer.email,
        firstName: this.application.customer.firstName,
        lastName: this.application.customer.lastName,
        loanAmount: this.application.requestedAmount,
        phone: this.application.customer.phone,
        state: this.application.customer.stateCode,
        zip: this.application.customer.zipCode
      }
    };
    (window as any).iframeSrc = (window as any).iframeSrc || [];
    (window as any).iframeSrc.push({
      src: `https://track.supermoney.com/aff_c?offer_id=1961&aff_id=1938&file_id=702&partner=kuber-financial&aff_click_id=${this.application.id}&aff_sub=${this.application.id}`,
    });

    const scriptTag = document.getElementById('smEmbedHelper');

    if (scriptTag) {
      scriptTag.remove()
    }

    const script = document.createElement('script');

    script.id = 'smEmbedHelper';
    script.src = 'https://app.supermoney.com/includes/js/embed_helper.js';

    document.getElementsByTagName("head")[0].appendChild(script);
  }

  public ngAfterViewInit(): void {
    window.addEventListener('message', (event: MessageEvent) => {
      if (typeof event.data === 'object') {
        if(event.data.type === 'lead_capture_open') {
          if (event.data.value === true) {
            this.disabledContinueButton = false;
          } else {
            this.disabledContinueButton = true;
          }
        }
      }
    });
  }

  public handleContinueButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Continue Application',
        contentTemplate: this.confirmContinueApplicationModalContentTemplate,
        actionsTemplate: this.confirmContinueApplicationModalActionsTemplate
      }
    });
  }
  
  public handleModalConfirmButtonClick(): void {
    this.closeModal();
    this.continueButtonClick.emit();

    if (!!this.injectedContinueButtonClickHandler) {
      this.injectedContinueButtonClickHandler();
    }
  }

  public closeModal(): void {
    this.modalRef!.close();

    this.modalRef = undefined;
  }
}
