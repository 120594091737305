import { NgModule } from '@angular/core';

import { WhatWeDoComponent } from './what-we-do.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [WhatWeDoComponent],
  imports: [SharedModule]
})
export class WhatWeDoModule {}
