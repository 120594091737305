import { HttpClient, HttpContext, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BankAccount, PutBankAccount } from "../types/bank-account.types";
import { environment } from "../../../environments/environment";
import { DISPLAY_TOAST_ON_ERROR } from '../interceptors/error-handling.interceptor';

@Injectable({ providedIn: 'root' })
export class BankAccountService {
  private baseUrl = `${environment.apiUrl}/applications`;

  constructor(private http: HttpClient) {}

  public put(applicationId: number, data: PutBankAccount, token?: string): Observable<BankAccount> {
    const formData = new FormData();

    formData.append('accountType', data.accountType);
    formData.append('bankName', data.bankName);
    formData.append('nameOnAccount', data.nameOnAccount);
    formData.append('routingNumber', data.routingNumber);
    formData.append('accountNumber', data.accountNumber);

    if (data.bankAccountId) {
      formData.append('bankAccountId', data.bankAccountId);
    }

    if (data.signature && data.pdf) {
      formData.append('signature', data.signature);
      formData.append('pdf', data.pdf);
    }

    return this.http.put<BankAccount>(
      `${this.baseUrl}/${applicationId}/account`, 
      formData,
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public findByApplicationId(applicationId: number, displayToastOnError?: boolean): Observable<BankAccount> {
    const options = { withCredentials: true, context: new HttpContext() };

    if (displayToastOnError !== undefined) {
      options.context.set(DISPLAY_TOAST_ON_ERROR, displayToastOnError);
    }

    return this.http.get<BankAccount>(`${this.baseUrl}/${applicationId}/account`, options);
  }
}
