import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AgreementsComponent } from './agreements.component';
import { AchAgreementComponent } from './ach-agreement/ach-agreement.component';
import { LoanAgreementComponent } from './loan-agreement/loan-agreement.component';
import { SignaturesCreationComponent } from './signatures-creation/signatures-creation.component';
import { SignaturesTableComponent } from './signatures-table/signatures-table.component';
import { UpdateSignatureStatusModalComponent } from './signatures-table/update-signature-status-modal/update-signature-status-modal.component';
import { SignatureInputModule } from '../signature-input/signature-input.module';
import { ButtonModule } from '../button/button.module';
import { HasRoleModule } from '../../directives/role/has-role.module';

@NgModule({
  declarations: [
    AgreementsComponent,
    AchAgreementComponent,
    LoanAgreementComponent,
    SignaturesCreationComponent,
    SignaturesTableComponent,
    UpdateSignatureStatusModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SignatureInputModule,
    ButtonModule,
    HasRoleModule
  ],
  exports: [AgreementsComponent]
})
export class AgreementsModule {}
