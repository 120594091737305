import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WhyMobilendComponent } from './why-mobilend.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [WhyMobilendComponent],
  imports: [RouterModule, SharedModule]
})
export class WhyMobilendModule {}
