import { Component } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

import { CoreService } from '../../core/services/core.service';
import { ContactService } from '../../core/services/contact.service';
import { CreateContactRequestPayload } from '../../core/types/contact.type';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  public contactForm = this.fb.nonNullable.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    subject: ['', Validators.required],
    message: ['', Validators.required],
    shareDataConsent: [false, Validators.requiredTrue]
  });

  constructor(
    private fb: FormBuilder,
    private coreService: CoreService,
    private contactService: ContactService,
    private toastrService: ToastrService
  ) {}

  public get isCheckboxInvalid(): boolean {
    return (
      this.contactForm.controls.shareDataConsent.touched &&
      this.contactForm.controls.shareDataConsent.invalid
    );
  }

  public getErrorMessage(controlName: keyof typeof this.contactForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.contactForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    } else if (control.hasError('requiredTrue')) {
      errorMessage = `${label} must be accepted`;
    }

    return errorMessage;
  }

  public handleContactFormSubmit(formDirective: FormGroupDirective): void {
    this.contactForm.markAllAsTouched();

    if (this.contactForm.valid) {
      const contactPayload = this.contactForm.value as CreateContactRequestPayload;

      this.coreService.setIsLoading(true);
      this.contactService
        .createContactRequest(contactPayload)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe(() => {
          this.toastrService.success(
            'Contact request successfully sent. Thank you!',
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
          this.contactForm.reset();
          formDirective.resetForm();
        });
    }
  }
}
