import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { AddressInputComponent } from './address-input.component';


@NgModule({
  declarations: [AddressInputComponent],
  imports: [CommonModule, MatInputModule, MatFormFieldModule, GooglePlaceModule],
  exports: [AddressInputComponent]
})
export class AddressInputModule {}
