export interface Role {
  id: number
  name: string
  level: string
  createdAt: string
  updatedAt: string
}

export enum Roles {
  Application = 'Application',
  CustomerService = 'Customer Service',
  Affiliate = 'Affiliate',
  Underwriter = 'Underwriter',
  Admin = 'Admin',
  Agent = 'Agent'
}