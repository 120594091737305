import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first, Observable } from "rxjs";
import jwt_decode from 'jwt-decode';

import { environment } from "../../../environments/environment";
import {
  AdminChangePasswordPayload,
  AdminForgotPasswordPayload,
  AdminLoginPayload,
  AdminLogoutMessage,
  AdminSetPasswordPayload,
  LoggedUser,
  TwoFactorAuthenticationPayload,
  LoginResponse,
  AuthenticatedUser
} from "../types/auth.types";

@Injectable({ providedIn: 'root' })
export class AuthService {
  private baseUrl = `${environment.apiUrl}/auth`;

  constructor(private http: HttpClient) {}

  public login(data: AdminLoginPayload): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.baseUrl}/login`, data, { withCredentials: true });
  }

  public login2fa(data: TwoFactorAuthenticationPayload, token: string): Observable<LoginResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<LoginResponse>(`${this.baseUrl}/2fa/login`, data, { headers, withCredentials: true });
  }

  public getToken(): string | null {
    if (document.cookie) {
      const userCookieValue = this.getCookieValue('user');
      return userCookieValue;
    }
    return null
  }

  public getLoggedUser(): void | LoggedUser {
    try {
      const token = this.getToken();

      if (token) {
        return jwt_decode(token);
      }
    } catch (error) {
      this.logout().pipe(first()).subscribe();
    }
  }

  public logout(): Observable<AdminLogoutMessage> {
    document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${environment.domain};`;

    return this.http.post<AdminLogoutMessage>(`${this.baseUrl}/logout`, null, { withCredentials: true });
  }

  public forgotPassword(data: AdminForgotPasswordPayload): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/forgotpassword`, data);
  }

  public setPassword(data: AdminSetPasswordPayload, token: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/setpassword/${token}`, data);
  }

  public changePassword(data: AdminChangePasswordPayload): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/changepassword`, data, { withCredentials: true });
  }

  public getCookieValue(cookieName: string): string | null {
    const cookies = document.cookie.split('; ');
  
    const cookie = cookies.find((cookie) => {
      const [name] = cookie.split('=');
      return name === cookieName;
    });
  
    return cookie ? cookie.split('=')[1] : null;
  }

  public generateAuthSecret(): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/2fa/generate`, null, { responseType: 'text' as 'json', withCredentials: true });
  }

  public switchTwoFactorAuthentication(data: TwoFactorAuthenticationPayload): Observable<AuthenticatedUser> {
    return this.http.post<AuthenticatedUser>(`${this.baseUrl}/2fa/switch`, data, { withCredentials: true });
  }
}
