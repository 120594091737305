import { Component, EventEmitter, Input, Output, SecurityContext, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';

import { Application } from '../../../core/types/application.types';
import { Actions, StepperAction } from '../enrollment.component';
import { ContractSignatureService } from '../../../core/services/contract-signature.service';
import { CoreService } from '../../../core/services/core.service';
import { AgreementsComponent } from '../../../shared/components/agreements/agreements.component';

@Component({
  selector: 'app-sign-agreements-step',
  templateUrl: './sign-agreements-step.component.html',
  styleUrls: ['./sign-agreements-step.component.scss']
})
export class SignAgreementsStepComponent {
  @Input() public application: Application;
  @Output() public onAction: EventEmitter<StepperAction> = new EventEmitter();
  @ViewChild(AgreementsComponent) public agreementsComponent!: AgreementsComponent;
  public signature?: SafeResourceUrl;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private coreService: CoreService,
    private contractSignatureService: ContractSignatureService
  ) {}

  public get disableAgreeAndFinalizeButton(): boolean {
    return (
      !this.signature ||
      (!this.application.customer.socialSecurityNumber && this.agreementsComponent?.socialSecurityNumber.invalid)
    );
  }

  public async handleCreateSignature(signature: SafeResourceUrl): Promise<void> {
    this.signature = signature;
  }

  public handleBackButtonClick(): void {
    this.onAction.emit({ action: Actions.Back });
  }

  public async handleAgreeAndFinalizeClick(): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const storage = this.coreService.getSessionStorageData<{ token: string }>('token');
      const blobSignature = await lastValueFrom(this.http.get(
        this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.signature!)!, { responseType: 'blob' }
      ));
      const achAgreementPdfFile = await this.agreementsComponent.generateAgreementPdfFile('ach', this.signature!);
      await Promise.all([
        lastValueFrom(this.contractSignatureService.createContractSignature(
          this.application.id,
          {
            signature: blobSignature,
            agreement: 'loan',
            socialSecurityNumber: this.agreementsComponent.socialSecurityNumber.value as string
          },
          storage?.token
        )),
        lastValueFrom(this.contractSignatureService.createContractSignature(
          this.application.id,
          { signature: blobSignature, agreement: 'ach', pdf: achAgreementPdfFile },
          storage?.token
        ))
      ]);

      this.onAction.emit({ action: Actions.Next });
    } finally {
      this.coreService.setIsLoading(false);
    }
  }
}
