import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

import { AuthService } from '../../../core/services/auth.service';
import { CoreService } from '../../../core/services/core.service';
import { AdminForgotPasswordPayload } from '../../../core/types/auth.types';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public forgotPasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });
  
  constructor(
    private fb: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService, 
    private coreService: CoreService,
    private toastrService: ToastrService
  ) {}

  public getErrorMessage(): string {
    let errorMessage = '';
    const control = this.forgotPasswordForm.controls.email;

    if (control.hasError('required')) {
      errorMessage = `Email is required`;
    } else if (control.hasError('email')) {
      errorMessage = `Email must be a valid email`;
    }

    return errorMessage;
  }

  public handleForgotPasswordFormSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      const credentials = this.forgotPasswordForm.value as AdminForgotPasswordPayload;

      this.coreService.setIsLoading(true);
      this.authService
        .forgotPassword(credentials)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe(() => {
          this.toastrService.success(
            'A one-time access link has been sent to your email.',
            undefined,
            { positionClass: 'toast-custom-bottom-center' }
          );
          this.router.navigate(
            ['../login'],
            { relativeTo: this.route }
          );
        });
    }
  }
}
