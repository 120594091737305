import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule } from 'ngx-mask';

import { AddApplicationComponent } from './add-application.component';
import { CardModule } from '../card/card.module';
import { DateInputModule } from '../date-input/date-input.module';

@NgModule({
  declarations: [AddApplicationComponent],
  exports: [AddApplicationComponent],
  imports: [
    CommonModule,
    CardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    DateInputModule,
    NgxMaskModule
  ]
})
export class AddApplicationsModule {}
