  <div id="loan-agreement">
    <div class="p-0 p-sm-5 d-flex flex-column loan-agreement-page" [style.gap.px]="24">
      <div>
        <h2 class="text-center text-lg-start mb-4 pt-4 pt-sm-0 fw-bold" [style.font-size.px]="30">
          Loan Agreement
        </h2>
        <span class="d-block">400 Spectrum Center Drive, Suite 350, Irvine, CA 92618</span>
      </div>
      <p class="m-0">Thank you for choosing Mobilend. Congratulations on taking this life changing step. This is the Installment Sales Agreement for your upcoming procedure with Mobilend. Please read the contract and the information below as there are important deadlines that must be met. Delays could cause Mobilend to not be able to offer financing.</p>
      <h3>The loan agreement</h3>
      <div>
        <p>
          Mobilend requires that this contract should be signed right away, The contract will be dated to start on the day of your procedure, when scheduled. Typically, your first payment will be due 30-days after your procedure. Since most people need time to recover, you may miss some time at work. Please make sure you have reserved enough money for your first payment.
        </p>
        <p>
          There are attachments to the contract allowing us to contact you electronically and collect your payment directly from your bank, as well as an arbitration agreement in the event of a dispute.
        </p>
        <p>
          All payments are collected through ACH (electronic check) or debit card. There is a page for you to provide your banking information. Payments are automatically deducted on the day you chose when you submitted your application. Tell us now if you need to change the payment date shown in the contract on the next page.
        </p>
        <p>
          Your ACH or e-check payment will read "Private Payment Services" or "PrivatePmtSvcing" depending on your bank.
        </p>
        <p class="m-0">
          Please Note: “Once your contract is effective, email notices regarding your new account & payment reminders will come from portal1@FA-servicing.com. Please note this email address as it may go directly into your junk or spam folders. Account access will also become available online at <span [style.word-break]="'break-word'">https://myaccount.1stassociates.com/Register.aspx”</span>.
        </p>
      </div>
      <h3>Down payment</h3>
      <p class="m-0">We assume that any Down Payment due to your provider, you are paying directly to Mobilend unless arrangements are otherwise made. Please ensure these arrangements are fully understood or contact your provider for more information.</p>
      <h3>Contact us</h3>
      <p class="m-0">Our telephone number for questions is 866-330-1669. Hours of operation are 8:00am-5:00pm PST, Monday through Friday.</p>
    </div>
  
    <hr />
  
    <div class="p-0 p-sm-5 d-flex flex-column loan-agreement-page" [style.gap.px]="24">
      <h3 class="pt-4 pt-sm-0">Retail installment sale contract</h3>
      <div
        class="row no-gutters border border-grey-500"
        [style.border-radius.px]="8"
      >
        <div class="col-sm-6 p-3 border-bottom border-right border-grey-500">
          <span class="fw-bold d-block mb-3">Borrower's Name and Address	</span>
          <span class="d-block mb-2">{{ application.customer.firstName }} {{ application.customer.lastName }}</span>
          <span>{{ formattedUserAddress }}</span>
        </div>
        <div class="col-sm-6 p-3 border-bottom border-grey-500">
          <span class="fw-bold d-block mb-3">Date of Contract</span>
          <span>{{ application.createdAt | date:'MM/dd/YYYY':'UTC' }}</span>
        </div>
        <div class="col-sm-6 p-3 border-bottom border-right border-grey-500">
          <span class="fw-bold d-block mb-3">Co-Borrower's Name and Address</span>
          <span>—</span>
        </div>
        <div class="col-sm-6 p-3 border-bottom border-grey-500">
          <span class="fw-bold d-block mb-3">Contract No</span>
          <span>APL_{{ application.id }}</span>
        </div>
        <div class="col-sm-6 p-3 border-right border-grey-500">
          <span class="fw-bold d-block mb-3">Lender's Name</span>
          <span>Kuber Financial, LLC</span>
        </div>
        <div class="col-sm-6 p-3">
          <span class="fw-bold d-block mb-3">Lender's Contract Information</span>
          <span>
            866-330-1669,
            <span [style.word-break]="'break-word'">support@mobilend.com</span>
          </span>
        </div>
      </div>
      <div>
        <p>
          In this Contract the words "we," "us," and "our" refer to Lender. The words "you" and "your" refer to Borrower (and Co-Borrower, if any) named above. By signing this Contract, you are buying the "Services" described below from the Lender named above, and you agree to the terms set forth on the front and back of this Contract.
        </p>
        <p>
          Services:  Elective Surgery
        </p>
        <p class="m-0">
          The price of the Services is shown below as the "Cash Price." By signing this Contract, you choose to buy the Services on credit and agree to pay us the total principal amount shown below as the "Amount Financed," which will be (1) the total cash price of the Services; plus (2) any costs, fees, or other amounts financed; plus (3) interest at the rate of :  16.9%  on the unpaid balance until it is fully repaid. The interest will be calculated on a simple-interest basis based upon a year of 365 days and the actual number of days elapsed. You agree to pay us in the amounts and under the terms set forth in this Contract. If this Contract is signed by a Borrower and Co-Borrower, each is individually and jointly responsible for all agreements in this Contract.
        </p>
      </div>
      <div
        class="p-4 border bordey-grey-500 d-flex flex-column"
        [ngStyle]="{'border-radius.px': 8, 'gap.px': 24 }"
      >
        <h3>Federal truth in lending act disclosures</h3>
        <div class="d-flex flex-column flex-md-row" [style.gap.px]="24">
          <div [ngStyle]="{ 'flex-basis': 0, 'flex-grow': 1 }">
            <span class="fw-bold d-block">Annual Percentage Rate</span>
            <span class="d-block">The Cost of your credit as an annual rate</span>
            <span class="fw-bold">
              {{ selectedOffer!.apr! / 100 | percent:'1.1-2' }}
            </span>
          </div>
          <div [ngStyle]="{ 'flex-basis': 0, 'flex-grow': 1 }">
            <span class="fw-bold d-block">Finance Charge</span>
            <span class="d-block">The dollar Amount the credit will cost you</span>
            <span class="fw-bold">
              {{ selectedOffer!.estimatedCost! | currency:'USD':'symbol':'1.2-2' }}
            </span>
          </div>
          <div [ngStyle]="{ 'flex-basis': 0, 'flex-grow': 1 }">
            <span class="fw-bold">Amount Financed</span>
            <span class="d-block">The amount of credit provided to you or on your behalf</span>
            <span class="fw-bold">
              {{ selectedOffer!.amount | currency:'USD':'symbol':'1.2-2' }}
            </span>
          </div>
          <div [ngStyle]="{ 'flex-basis': 0, 'flex-grow': 1 }">
            <span class="fw-bold">Total of Payments</span>
            <span class="d-block">The amount you will have paid after you made all payments as scheduled.</span>
            <span class="fw-bold">
              {{ selectedOffer!.monthlyPayment * selectedOffer!.term! | currency:'USD':'symbol':'1.2-2' }}
            </span>
          </div>
        </div>
        <hr />
        <div>
          <span class="fw-bold d-block">Payment Schedule:</span>
          <span>
            {{ selectedOffer!.term }} monthly payments of {{ selectedOffer!.monthlyPayment | currency:'USD':'symbol':'1.2-2' }} beginning on {{ paymentBeginningDate | date:'MM/dd/YYYY' }}.
          </span>
        </div>
        <div>
          <span class="fw-bold d-block">Late Charges:</span>
          <span>If any payment is more than 10 days late, you will be charged a late charge of $15.00.</span>
        </div>
        <div>
          <span class="fw-bold d-block">Prepayment:</span>
          <span>If you pay early, you will not have to pay a penalty.</span>
        </div>
        <div>
          <span class="fw-bold d-block">Non-refundable Application Fee: </span>
          <span>$0.00 must be paid at time of application.</span>
        </div>
        <p class="m-0">
          Read the Contract for any additional information about nonpayment, default, any required prepayment in full before the scheduled date.
        </p>
      </div>
      <div
        class="p-4 border bordey-grey-500 d-flex flex-column"
        [ngStyle]="{'border-radius.px': 8, 'gap.px': 24 }"
      >
        <h3>Itemization of amount financed</h3>
        <div class="d-flex flex-column flex-sm-row justify-content-between fw-bold">
          <span>1. Amount financed</span>
          <span>{{ selectedOffer.amount! | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <div class="d-flex flex-column flex-sm-row justify-content-between fw-bold">
          <span>2. Origination fees</span>
          <span>{{ originationFee | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <div class="d-flex flex-column flex-sm-row justify-content-between fw-bold">
          <span>3. Amount given to you directly (1 - 2)</span>
          <span>{{ selectedOffer.amount! - originationFee | currency:'USD':'symbol':'1.2-2' }}</span>
        </div>
        <hr />
      </div>
      <div
        class="
          p-4
          border
          bordey-grey-500
          d-flex
          flex-column
          overflow-auto
        "
        [ngStyle]="{'border-radius.px': 8, 'gap.rem': 1.5 }"
      >
        <h3>Amortization schedule</h3>
        <table class="amortization-schedule-table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Payment Date</th>
              <th>Payment Amount</th>
              <th>Interest Paid</th>
              <th>Principal Paid</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let amortization of initialAmortizations">
              <td>{{ amortization.month }}</td>
              <td>{{ amortization.paymentDate | date:'MM-dd-YYYY' }}</td>
              <td>{{ amortization.paymentAmount | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{ amortization.interestAmount | currency:'USD':'symbol':'1.2-2' }}</td>
              <td>{{ amortization.principalAmount | currency:'USD':'symbol':'1.2-2'}}</td>
              <td>{{ amortization.remainingBalance | currency:'USD':'symbol':'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="m-0">
        NOTICES: The names and addresses of all persons to whom the notices required or permitted by law to be sent are set forth above.
      </p>
      <p class="m-0 fw-bold">
        Borrower and any co-borrower acknowledge that prior to signing this contract each has read and received a legible, completely filled-in copy of this contract and that, upon signing, such copy was also signed by the parties hereto.
      </p>
      <p class="m-0">
        Borrower aid any Co-Borrower acknowledge that (1) before signing this Contract each read this Contract and received a legible, completely filled-in copy of this Contract, and (2) each has received a copy of every other document that each signed during the Contract negotiations.
      </p>
      <div class="mb-4 d-flex flex-column" [style.gap.px]="24">
        <div class="d-flex flex-column flex-xl-row manual-input-container">
          <div class="d-flex align-items-center flex-grow-1" [style.gap.px]="16">
            <span class="fw-bold" [style.width.px]="100">Borrower:</span>
            <div class="flex-grow-1 border-bottom border-grey-400 text-center signature-container">
              <button
                *ngIf="!signaturesMap.get('loanAgreementFirst')"
                mat-raised-button
                color="primary"
                [disabled]="!signature"
                (click)="handleSignButtonClick('loanAgreementFirst')"
              >
                Sign
              </button>
              <img *ngIf="signaturesMap.get('loanAgreementFirst')" class="signature" [src]="signature" />
            </div>
          </div>
          <div class="d-flex align-items-center" [style.gap.px]="16">
            <span class="fw-bold pt-4 pt-sm-0" [style.width.px]="40">Date:</span>
            <div class="flex-grow-1 border-bottom border-grey-400 text-center" [style.width.px]="245">
              {{ (signedAt || today) | date:'MM/dd/YYYY' }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-xl-row align-items-xl-start manual-input-container">
          <div class="d-flex flex-grow-1" [style.gap.px]="16">
            <span class="fw-bold" [style.width.px]="100">Lender:</span>
            <div class="flex-grow-1 d-flex flex-column">
              <div class="border-bottom border-grey-400 text-center">
                Kuber Financial, LLC
              </div>
              <span class="d-block pt-2 text-grey-500 text-center" [style.font-size.px]="12">Name and title</span>
            </div>
          </div>
          <div class="d-flex" [style.gap.px]="16">
            <span class="fw-bold" [style.width.px]="40">Date:</span>
            <div class="flex-grow-1 border-bottom border-grey-400 text-center" [style.width.px]="245">
              {{ (signedAt || today) | date:'MM/dd/YYYY' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="p-0 p-sm-5 d-flex flex-column loan-agreement-page" [style.gap.px]="24">
      <h3 class="pt-4 pt-sm-0">Additional terms and conditions</h3>
      <ol class="pl-3">
        <li class="mb-4">
          Application of Payments: You agree that payments will be applied first to interest accrued but unpaid as of the date of processing of the payment, next to late charges or other charges, and finally to principal.
        </li>
        <li class="mb-4">
          Default and Acceleration: You will be in default if you fail to make payment or keep other promises you make in this Contract. If you default in making any payment when due, or in the performance of any term or condition of this Contract, we may, in addition to our other remedies, declare all unpaid sums immediately due and payable. You waive your right to require us to do certain things. Those things are (a) to demand payment of amounts do; (b) to give notice that amounts due have not been paid; (c) to obtain an official certification of finanace payments. Anyone else who agrees to keep the promises made in this Contract or who agrees to make payments to us if you fail to keep your promises under this Contract (these persons are known as "Co-Signors" or "Guarantors") also waives these rights. As required by law, you are hereby notified that a negative credit report reflecting on your credit record may be submitted to a credit reporting agency if you fail to fulfill the terms of your credit obligations. If we take any adverse action and the adverse action is based, in whole or in part, on any information contained in a consumer credit report, you have the right to obtain within 60 days a free copy of your consumer credit report form the consumer reporting agency who furnished us your consumer credit report and from any other consumer reporting agency which compiles and maintains files on consumers on a nationwide basis. You may have the right to dispute the accuracy or completeness of any information in a consumer credit report furnished by the consumer credit reporting agency.
        </li>
        <li class="mb-4">
          Co-Borrowers, Co-signers and Guarantors: If more than one person signs this Contract, each of you agrees to be fully and personally obligated to pay the full amount owed and to keep all the promises made in this Contract. Any guarantor of this Contract (as described in paragraph 3, above), is also obligated to do these things. You agree that we may enforce our rights under this Contract against each of you individually or against all of you together. This means that any one of you may be required to pay all of the amounts owed under this Contract.
        </li>
        <li class="mb-4">
          Prepayment and Acceleration: You may prepay all amounts due under this Contract at any time without penalty.
        </li>
        <li class="mb-4">
          <p class="m-0">
            Promotional Financing Option. You will be billed for monthly payments beginning one month after your date of purchase. Your billing will include interest the rate of [IntRate] to your account from the date of the purchase contract. However, if your purchase contract is paid in full within the 180 day promotional period, then all the interest you have paid will be rebated to you. The Minimum Monthly Payment may or may not pay off the promotional purchase before the end of the promotional period. Interest will be credited to your account if you meet the following conditions:
          </p>
          <ol class="pl-4" [style.list-style-type]="'lower-alpha'">
            <li>
              Make all minimum monthly payments on time.
            </li>
            <li>
              Do not incur a late fee or NSF fee.
            </li>
            <li>
              Notify us via telephone at 949-207-9323 extension 1 or via email <span [style.word-break]="'break-word'">Contact@ModernHealthFinance.com</span> before you make your final payment to confirm.
            </li>
          </ol>
        </li>
        <li class="mb-4">
          Credit Information: You warrant that all information contained in your application for credit is true and correct, and acknowledge that we have relied upon the accuracy thereof in connection with this Contract.
        </li>
        <li class="mb-4">
          Non Refundable Application Fee: A fee of $0.00 is charged and collected at time of application. "This fee pays for underwriting and third party credit report services.
        </li>
        <li class="mb-4">
          Late Charges; Returned Check Charges: If you default in the payment of any installment for a period of 10 days or more, we will collect a late charge in the amount of $15.00. You also recognize that you will pay more interest if you do not make a payment by the date it is due. If you make any payment to us or to our agent with a check that is returned unpaid, we will collect a $25.00 returned check charge.
        </li>
        <li class="mb-4">
          Governing Law: This Contract will be governed by the laws of the State of CA.
        </li>
        <li class="mb-4">
          If this Agreement is subject to a law which sets maximum interest rates and/or charges, and that law is finally interpreted so that the interest or other charges collected or to be collected in connection with this Agreement exceed such permitted limits, then: (a) we will reduce any such interest rate by the amount necessary to reduce the charge to the permitted limit; and (b) any sums already collected from you which exceeded permitted limits will be refunded to you. We may choose to make this refund by reducing the outstanding balance owed under the Agreement or by making a direct payment to you. Your acceptance of any such refund made by direct payment to you, whether by direct payment to you or by credit to your outstanding balance, will constitute a waiver of any right of action you may have arising out of such overcharge.
        </li>
        <li class="mb-4">
          Automatic Payments: You agree that if you have agreed to automatic installment payments, and you discontinue that agreement or fail to make an automatic installment payment, the interest rate you will pay under this Contract will increase to 35.90% per annum. In that event, we will notify you of your new payment schedule.
        </li>
        <li class="mb-4">
          Rights Upon Default: If (a) you default in the performance of any of the terms and conditions of this Contract, including but not limited to the making of any payment when due; (b) you become insolvent or are the subject of bankruptcy proceedings; or (c) you die, we at our option and without notice may (i) declare all unpaid sums due under this Contract immediately due and payable; or (ii) file suit against you for all unpaid sums due under this Contract. In the event that you fail to notify us of any change of address or fail to communicate with us for a period of 45 days after any default in paying any installment due under this Contract, we may collect reasonable costs of collection. In the event that any action to enforce the terms or conditions of this Contract is brought, the prevailing party shall be entitled to reasonable attorneys' fees and court costs.
        </li>
        <li>
          Other Agreements: You agree that if we accept monies in sums less than those due or make extensions of due dates or payments under this Contract, doing so will not be a waiver of any later right to enforce the contract terms as written. You agree to send any communication about a disputed debt, including any check or other payment instrument marked "paid in full" or with similar words, to the following address: Mobilend 4362 Blue Diamond Rd #102-348 Las Vegas, NV 89139. We intend to transfer this Contract to an assignee, you will be given full notice thereof and you agree that the assignee will have all our rights and remedies under this Contract and you agree to pay all that is still owed under this Contract at all times, and in the amount, to the assignee. You agree that all of the agreements between you and us are set forth in this Contract and that no modification of this Contract shall be valid unless it is made in writing and signed by you and us. You agree that if any provision of this Contract is held invalid, that shall not affect the validity of any other provision of this Contract, and that the remaining provisions of the Contract continue to be binding and enforceable.
        </li>
      </ol>
      <h3>Notice</h3>
      <p class="m-0 fw-bold">
        Any holder of this consumer credit contract is subject to all claims and defenses which the debtor could assert against the seller of goods or services obtained pursuant hereto or with the proceeds hereor. Recovery hereunder by the debtor shall not exceed amounts paid by the debtor hereunder.
      </p>
      <p class="m-0">
        Notice to Borrower: (1) Do not sign this Contract before you read it or if it contains any blank spaces to be filled in. (2) You are entitled to a completely filled-in copy of this Contract. (3) You can prepay the full amount due under this Contract at any time. (4) If you desire to pay off in advance the full amount due, that amount will be furnished upon request.
      </p>
    </div>
  
    <hr />
  
    <div class="p-0 p-sm-5 d-flex flex-column loan-agreement-page" [style.gap.px]="24">
      <h3 class="pt-4 pt-sm-0">Optional voluntary electronic payment authorization</h3>
      <p class="m-0 fw-bold">
        Notice: this electronic payment authorization is optional - i understand that i will still be able to enter into my truth-in-lending disclosure and note or retail installment agreement if i do not sign this authorization.
      </p>
      <p class="m-0 fw-bold">
        I hereby exercise my (our) option to make the payments agreed to in my Truth in Lending Disclosure and Note or Retail Installment Agreement by electronic payment and do voluntarily authorize Modern Asset Management, Inc. dba Modern Health Finance, as servicer for Mobilend, Inc its successors or assigns ("Servicer") to do the following:
      </p>
      <ol class="pl-4">
        <li class="mb-4">
          Initiate automatic electronic payments from My Bank Account specified below and debit My Bank Account on the Payment Dates specified in my Agreement, or for any lesser amount I (we) owe.
        </li>
        <li class="mb-4">
          Re-initiate a payment debit up to two additional times for the same amount if the payment is not made for any reason.
        </li>
        <li class="mb-4">
          If necessary, to credit my account to correct erroneous debits.
        </li>
        <li class="mb-4">
          Initiate a separate electronic payment from My Bank Account below for any applicable late payment charge or returned check fee in the amounts set forth in my Agreement.
        </li>
        <li>
          I may make any payment at any time by check delivered by mail or courier service to 23030 Lake Forest Dr. Suite #202, Laguna Hills, CA 92653 , that such payment is to replace my next previously authorized Electronic Payment, I acknowledge that Servicer must receive it at least three (3) business days beore the previously authorized Electronic Payment is scheduled to be made and I will notify Servicer in writing, at 23030 Lake Forest Dr. Suite #202, Laguna Hills, CA 92653 , that such payment is to replace my next previously authorized Electronic Payment. If I want such payment to be a payment in addition to the scheduled Authorized Electronic Payment, I will notify Servicer accordingly. This Electronic Payment Authorization will remain in effect unless I explicitly notify Servicer that my Electronic Payment Authorization is withdrawn.
        </li>
      </ol>
      <h3>Purpose of authorization</h3>
      <p class="m-0">
        I agree that the electronic payment authorized herein is voluntary and for my convenience. This Electronic Payment Authorization is a payment mechanism only and does not give Lender or Servicer any collection rights greater than those otherwise contained in my Agreement. This Authorization does not constitute and is not intended to constitute a security interest under state law.
      </p>
      <h3>How to withdraw your authorization</h3>
      <p class="m-0">
        I understand that this Electronic Payment Authorization is to remain in full force and effect until Servicer has received written notification from me that I wish to revoke this authorization. I understand that Servicer requires at least three (3) business days prior notice, at 23030 Lake Forest Dr. Suite #202, Laguna Hills, CA 92653 , in order to cancel this Authorization.
      </p>
      <div
        class="border border-grey-500 p-4 mb-4"
        [style.border-radius.px]="8"
      >
        <h3 class="mb-4">My designated bank account</h3>
        <div class="d-flex flex-column" [style.gap.px]="16">
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <span class="fw-bold">Name of bank:</span>
            <span>{{ bankAccount.bankName }}</span>
          </div>
          <hr />
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <span class="fw-bold">Account type:</span>
            <span>{{ bankAccount.accountType }}</span>
          </div>
          <hr />
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <span class="fw-bold">Routing number:</span>
            <span>{{ bankAccount.routingNumber }}</span>
          </div>
          <hr />
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <span class="fw-bold">Account number:</span>
            <span>{{ bankAccount.accountNumber }}</span>
          </div>
        </div>
      </div>
      <p class="m-0 fw-bold">
        By signing below, borrower agrees to the terms and conditions of this electronic funds transfer agreement.
      </p>
      <div class="d-flex flex-column flex-xl-row" [style.gap.px]="48">
        <div class="d-flex align-items-center flex-grow-1" [style.gap.px]="16">
          <span class="fw-bold">Borrower's Signature:</span>
          <div class="flex-grow-1 border-bottom border-grey-400 text-center signature-container">
            <button
              *ngIf="!signaturesMap.get('loanAgreementSecond')"
              mat-raised-button
              color="primary"
              [disabled]="!signature"
              (click)="handleSignButtonClick('loanAgreementSecond')"
            >
              Sign
            </button>
            <img *ngIf="signaturesMap.get('loanAgreementSecond')" class="signature" [src]="signature" />
          </div>
        </div>
        <div class="d-flex align-items-center" [style.gap.px]="16">
          <span class="fw-bold">Date:</span>
          <div class="flex-grow-1 border-bottom border-grey-400 text-center" [style.width.px]="245">
            {{ (signedAt || today) | date:'MM/dd/YYYY' }}
          </div>
        </div>
      </div>
    </div>
  
    <hr />
  
    <div class="p-0 p-sm-5 d-flex flex-column loan-agreement-page" [style.gap.px]="24">
      <h2 class="fw-bold pt-4 pt-sm-0" [style.font-size.px]="30">
        Arbitration agreement
      </h2>
      <div class="d-flex flex-column p-4 border border-grey-500" [style.gap.px]="8">
        <h3>Notice of arbitration agreement</h3>
        <p>
          This Arbitration Agreement provides that all disputes between you and Mobilend  will be resolved by <strong>BINDING ARBITRATION</strong>. You thus <strong>GIVE UP YOUR RIGHT TO GO TO COURT</strong> to assert or defend your rights under this contract (except for matters that may be taken to Small Claims Court and except for matters seeking public injunctive relief).
        </p>
        <ul class="pl-4">
          <li>
            Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury.        
          </li>
          <li>
            You are entitled to a FAIR HEARING, BUT the arbitration procedures are SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT.
          </li>
          <li>
            Arbitrator decisions are as enforceable as any court order and are subject to VERY LIMITED REVIEW BY A COURT.
          </li>
        </ul>
        <span class="fw-bold">
          For more details, please read this arbitration agreement carefully.
        </span>
      </div>
      <p>
        Throughout this Arbitration Agreement, Mobilend, Modern Asset Management, Inc., dba Modern Health Finance, and any future assigns, is referred to as "we" and "us," and all of Lender's customers who sign this Arbitration Agreement are referred to as "you."
      </p>
      <ol class="pl-4">
        <li class="mb-4">
          <strong>Dispute Resolution by Arbitration:</strong> Any and all claims, controversies, or disputes arising out of or related in any way to your Retail Installment Sales Agreement Truth-in-Lending Disclosure, (the "Service Agreement") entered into by you and us on the same date as this Arbitration Agreement shall be subject to binding arbitration pursuant to the under the Federal Arbitration Act. This Arbitration Agreement is made pursuant to a transaction involving interstate commerce, and shall be govemed by the Federal Arbitration Act (the "FAA"), 9 U.S.C. Sections 1-6. This Agreement applies to, without limitation, (1) all issues concerning the transaction in connection with which this Arbitration Agreement has been executed; (2) initial claims, counterclaims, cross-claims, and third-party claims, whether arising in law or equity, and whether based upon federal, state, or local law; contract; tort; fraud or other intentional tort; constitution, common law, or statute; (3) any issue as to whether any such claims, controversies, or disputes are subject to arbitration; and (4) any claims, controversies, or disputes that would otherwise be subject to class actions. This means that all claims, controversies or disputes that are the subject of class actions will also be subject to binding arbitration under the FAA and this Arbitration Agreement. THE ARBITRATOR SHALL NOT CONDUCT CLASS ARBITRATION; THAT IS, THE ARBITRATOR SHALL NOT ALLOW YOU OR US TO SERVE AS A PRIVATE ATTORNEY GENERAL, AS A REPRESENTATIVE, OR IN ANY OTHER REPRESENTATIVE CAPACITY FOR OTHERS IN THE ARBITRATION.
        </li>
        <li class="mb-4">
          <p class="m-0">
            <strong>Consent to Arbitration:</strong> You and we understand and agree that you and we are choosing arbitration rather than litigation to resolve disputes. You and we understand that you and we have the right to litigate disputes but that you and we prefer to do so through arbitration. In arbitration, you may choose to have a hearing and be represented by counsel. <strong>THEREFORE, YOU UNDERSTAND THAT BY ENTERING INTO THIS ARBITRATION AGREEMENT, YOU VOLUNTARILY AND KNOWINGLY:</strong>
          </p>
          <ol class="pl-4 fw-bold" [style.list-style-type]="'lower-alpha'">
            <li>
              WAIVE ANY RIGHTS TO HAVE A TRIAL BY JURY TO RESOLVE ANY CLAIM OR DISPUTE ALLEGED AGAINST US OR RELATED THIRD PARTIES;
            </li>
            <li>
              WAIVE YOUR RIGHT TO HAVE A COURT, OTHER THAN A SMALL CLAIMS COURT, RESOLVE ANY CLAIM OR DISPUTE ALLEGED AGAINST US OR RELATED THIRD PARTIES; AND
            </li>
            <li>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, WAIVE YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, AND/OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT FILED AGAINST US AND/OR RELATED THIRD PARTIES.
            </li>
          </ol>
        </li>
        <li class="mb-4">
          <strong>Exception for Claims for Public Injunctive Relief:</strong> You or we may, but are not required to, submit claims for public injunctive relief under state or federal statutes that specifically provide for such relief to arbitration under this Arbitration Agreement. In the event that either you or we elect to pursue such a claim through court proceedings, all other claims between us shall remain subject to the provisions of this Arbitration Agreement.
        </li>
        <li class="mb-4">
          <p class="m-0">
            <strong>Opt-Out Right:</strong> You may elect to opt out of this Arbitration Agreement by sending or delivering written notice to the address on this Arbitration Agreement that you wish to opt out of this Arbitration Agreement. This written notice must be received by us by the end of the 30th calendar day after you sign this Agreement.
          </p>
        </li>
        <li class="mb-4">
          <p class="m-0">
            <strong>Procedure for Arbitration:</strong> Arbitration maybe heard, at the claimant's election, by:
          </p>
          <ul class="mb-4" [style.list-style-type]="'disc'">
            <li>
              <span class="d-block">The American Arbitration Association:</span>
              <span class="d-block" [style.word-break]="'break-word'">https://www.adr.org</span>
              <span class="d-block">(877) 495-4185</span>
              <span class="d-block" [style.word-break]="'break-word'">casefiling@adr.org</span>
            </li>
            <li>
              <span class="d-block">JAMS: (800) 352-5267</span>
              <span class="d-block" [style.word-break]="'break-word'">http://www.jamsadr.com/adr-arbitration</span>
              <span class="d-block" [style.word-break]="'break-word'">https://www.jamsadr.com/contact</span>
            </li>
            <li>
              or any other arbitration fonun as you and we may agree.
            </li>
          </ul>
          <p [style.padding-left.px]="40">
            You may initiate an arbitration by contacting the arbitration forum of your choice at the contact points provided above. If you require assistance in a language other than English, or special services to accommodate a disability, please select an arbitration forum that can accommodate your needs.
          </p>
          <ol [style.list-style-type]="'lower-alpha'">
            <li>
              The arbitration shall be conducted by a single neutral, qualified and competent arbitrator selected by you and us under the rules of the arbitration forum selected. The arbitrator shall apply applicable substantive law consistent with the FAA and applicable statutes of limitation, and shall honor all claims of privilege recognized by law. The Arbitration shall take place in a location determined by the arbitrator the federal district of your residence.
            </li>
            <li>
              If you file for arbitration under this Arbitration Agreement, the only fee you may required to pay is $200, which is approximately equivalent to current court filing fees. We will bear all other of the arbitration, except for your attorneys' fees and costs. If we file for arbitration under this Arbitration Agreement, we will be required to pay all costs associated with the arbitration, except for your attorneys' fees and costs. However, if circumstances relating to the dispute (including, among other things, the size and nature of the dispute, the nature of the services that we have provided you, and your ability to pay) it would be unfair or burdensome for you to pay the arbitration filing fees, we will advance the initial filing, administration, and hearing fees required by the arbitrator, who will ultimately decide who will be responsible for paying those amounts.
            </li>
            <li>
              You can participate without representation or may choose to be represented by an attorney or other authorized representative, unless that choice is prohibited by applicable law. Because arbitration is a final, legally-binding process that may impact your legal rights, you may want to consider consulting an attorney. Each party, you and we, shall bear our own costs and expenses, including attorneys' fees, that we incur with respect to the arbitration.
            </li>
            <li>
              The Arbitrator shall allow for the discovery or exchange of non-privileged information relevant to the dispute, under the Arbitrator's supervision, prior to the arbitration hearing or submission of written presentations.
            </li>
            <li>
              Arbitrations may be decided upon written presentations, unless the amount of relief requested exceeds $25,000. The Arbitrator may consider dispositive motions, but shall generally hold a conference call among all the parties prior to permitting any written motion. The Arbitrator may hold hearings in person or by conference call, and hearings generally will not exceed one day. If you or we show good cause, the arbitrator may schedule additional hearings within seven calendar days after the initial hearing.
            </li>
          </ol>
        </li>
        <li class="mb-4">
          <strong>Interpretation of this Arbitration Agreement:</strong> Any dispute as to the arbitrability of a claim shall be decided by the arbitrator. Any dispute as to the validity of the portion of this agreement that prohibits class arbitration shall be a matter for resolution by a court and not by the arbitrator. In the event that the court deems the portion of this agreement that prohibits class arbitration to be unenforceable, then the court shall retain jurisdiction over the dispute and this Arbitration Agreement shall be null and void.
        </li>
        <li class="mb-4">
          <strong>Statutes of Limitations:</strong> All statutes of limitations that are applicable to any claim or dispute shall apply to any arbitration between you and us.
        </li>
        <li class="mb-4">
          <strong>Attorneys' Fees:</strong> The arbitrator may, but is not required to, award reasonable expenses and attorneys' fees to the prevailing party if allowed by statute or applicable law and by the rules of the arbitration forum.
        </li>
        <li class="mb-4">
          <strong>Awards:</strong> The Arbitrator shall issue the award in accordance with the rules of the arbitration forum. Unless you and we agree otherwise, The award shall provide the concise written reasons for the decision and shall apply any identified, pertinent contract terms, statutes and legal precedents. The arbitrator may grant any remedy, relief or outcome that you or we parties could have received in court.
        </li>
        <li class="mb-4">
          <strong>Enforcement of Award:</strong> The award of the arbitrator shall be binding and final after fifteen (15) days have passed, and judgment upon the arbitrator's award may thereafter be entered in any court having jurisdiction.
        </li>
        <li class="mb-4">
          <strong>Appeal Procedure:</strong> Within fifteen (15) days after an award by the arbitrator, any party may appeal the award by requesting in writing a new arbitration before a panel of three neutral arbitrators designated by the same arbitration service. The decision of the panel of three neutral arbitrators will be immediately binding and final.
        </li>
        <li class="mb-4">
          <strong>Small Claims Court:</strong> Notwithstanding any other provision of this Arbitration Agreement, either you or we shall retain the right to seek adjudication in Small Claims Court of any matter within its jurisdiction. Any matter not within the Small Claims Court's jurisdiction shall be resolved by arbitration as provided above. Any appeal from a Small Claims Court judgment shall be conducted in accordance with the provisions in Section 8 of this Arbitration Agreement or applicable law.
        </li>
        <li class="mb-4">
          <strong>Counterparts:</strong> This Arbitration Agreement may be executed in counterparts, each of which shall be deemed to be an original but all of which together shall be deemed to be one instrument.
        </li>
      </ol>
      <p class="fw-bold">
        By signing below, borrower agrees to the terms and conditions of this electronic funds transfer agreement.
      </p>
      <div class="d-flex flex-column flex-xl-row" [style.gap.px]="48">
        <div class="d-flex align-items-center flex-grow-1" [style.gap.px]="16">
          <span class="fw-bold">Borrower's Signature:</span>
          <div class="flex-grow-1 border-bottom border-grey-400 text-center signature-container">
            <button
              *ngIf="!signaturesMap.get('loanAgreementThird')"
              mat-raised-button
              color="primary"
              [disabled]="!signature"
              (click)="handleSignButtonClick('loanAgreementThird')"
            >
              Sign
            </button>
            <img *ngIf="signaturesMap.get('loanAgreementThird')" class="signature" [src]="signature" />
          </div>
        </div>
        <div class="d-flex align-items-center" [style.gap.px]="16">
          <span class="fw-bold">Date:</span>
          <div class="flex-grow-1 border-bottom border-grey-400 text-center" [style.width.px]="245">
            {{ (signedAt || today) | date:'MM/dd/YYYY' }}
          </div>
        </div>
      </div>
    </div>
  </div>