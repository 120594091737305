<ng-container *ngIf="renderSignatureManagement">
  <ng-container *ngIf="displaySignatureBox">
    <div class="contract-warning">
      <div class="d-flex flex-column gap-2">
        <span>It's Important</span>
        <p>
          This contract is NOT in effect until any services are rendered. Shall no services be confirmed as rendered to the consumer, the signed contract remains null and void
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9954 4.41541C12.5524 3.65033 11.4478 3.65033 11.0049 4.41541L3.47377 17.4237C3.02991 18.1903 3.58313 19.1499 4.46901 19.1499H19.5312C20.4171 19.1499 20.9703 18.1904 20.5265 17.4237L12.9954 4.41541ZM9.53365 3.56365C10.6314 1.66758 13.3689 1.66759 14.4666 3.56365L21.9977 16.5719C23.0977 18.4719 21.7267 20.8499 19.5312 20.8499H4.46901C2.27356 20.8499 0.902545 18.4719 2.00254 16.5719L9.53365 3.56365ZM12.0001 8.14987C12.4695 8.14987 12.8501 8.53043 12.8501 8.99987V12.9999C12.8501 13.4693 12.4695 13.8499 12.0001 13.8499C11.5307 13.8499 11.1501 13.4693 11.1501 12.9999V8.99987C11.1501 8.53043 11.5307 8.14987 12.0001 8.14987ZM12.0001 16.9999C12.5524 16.9999 13.0001 16.5522 13.0001 15.9999C13.0001 15.4476 12.5524 14.9999 12.0001 14.9999C11.4478 14.9999 11.0001 15.4476 11.0001 15.9999C11.0001 16.5522 11.4478 16.9999 12.0001 16.9999Z"
          fill="#FFA686"
        />
      </svg>
    </div>
    <div
      *ngIf="!application?.customer?.socialSecurityNumber"
      class="ssn-input-container"
    >
      <div class="d-flex flex-column gap-3">
        <span class="ssn-input-label">
          Social Security Number
        </span>
        <p>
          Please inform your social security number
        </p>
        <mat-form-field>
          <mat-label>Social Security Number</mat-label>
          <input
            matInput
            type="password"
            mask="000000000"
            [formControl]="socialSecurityNumber"
          />
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <app-signatures-creation
    [displaySignatureBox]="displaySignatureBox"
    (signatureAction)="handleSignatureAction($event)"
    (agreementClick)="handleAgreementClick($event)"
  ></app-signatures-creation>
  <p *ngIf="displaySignatureBox" class="agree-and-finalize-disclosure">
    * By clicking "Agree and finalize" you authorize us to add your signature to the above agreements
  </p>
</ng-container>

<app-signatures-table
  *appHasRole="[userRoles.Admin, userRoles.Underwriter, userRoles.CustomerService]"
  [contractSignatures]="application.contractSignatures"
  (downloadButtonClick)="handleDownloadPdfFile($event.agreement, $event)"
></app-signatures-table>

<ng-container>
  <ng-template #loanAgreementModalContentTemplate>
    <app-loan-agreement
      #loanAgreement
      [application]="application"
      [selectedOffer]="selectedOffer!"
      [bankAccount]="application.bankAccount!"
      [signature]="signatureToDisplay"
      [signaturesMap]="signaturesMap"
      (onSign)="handleSign($event)"
    ></app-loan-agreement>
  </ng-template>
  <ng-template #loanAgreementModalActionsTemplate>
    <div class="d-flex gap-5 justify-content-end">
      <button
        *ngIf="displayDownloadButton"
        mat-button
        color="primary"
        (click)="handleDownloadPdfFile('loan')"
      >
        Download
      </button>
      <button
        mat-button
        color="primary"
        (click)="modalRef!.close()"
      >
        Close
      </button>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template #achAgreementModalContentTemplate>
    <app-ach-agreement
      #achAgreement
      [application]="application"
      [selectedOffer]="selectedOffer!"
      [bankAccount]="application.bankAccount!"
      [signature]="signatureToDisplay"
      [signaturesMap]="signaturesMap"
      (onSign)="handleSign($event)"
    ></app-ach-agreement>
  </ng-template>
  <ng-template #achAgreementModalActionsTemplate>
    <div class="d-flex gap-5 justify-content-end">
      <button
        *ngIf="displayDownloadButton"
        mat-button
        color="primary"
        (click)="handleDownloadPdfFile('ach')"
      >
        Download
      </button>
      <button
        mat-button
        color="primary"
        (click)="modalRef!.close()"
      >
        Close
      </button>
    </div>
  </ng-template>
</ng-container>