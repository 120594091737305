<ng-template #dontSellMyPersonalInformationModalContentTemplate>
  <p>
    The California Consumer Privacy Act (CCPA), gives residents of the state of California the right to prevent businesses from selling their personal information. mobilend.com takes your privacy very seriously. We support the CCPA by allowing California residents to opt-out of any future sale of their personal information. If you would like to record your preference that mobilend.com will not sell your data, please check the box below. Please note, your choices will only apply to the browser you are using to submit this form. This also means that if you clear browser cookies, you will need to opt-out again.
  </p>
  <mat-checkbox color="primary" [formControl]="dontSellMyPersonalInformation">
    Do not sell my personal information
  </mat-checkbox>
</ng-template>

<ng-template #dontSellMyPersonalInformationModalActionsTemplate>
  <div class="
    d-flex
    align-items-center
    gap-3
    justify-content-end
  ">
    <button
      mat-button
      color="primary"
      (click)="handleCancelButtonClick()"
    >
      Cancel
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleConfirmButtonClick()"
    >
      Confirm
    </button>
  </div>
</ng-template>