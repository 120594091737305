import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { ApplicationsTableComponent } from './applications-table.component';
import { ApplicationFiltersModalComponent } from './application-filters-modal/application-filters-modal.component';
import { CardModule } from '../card/card.module';
import { TableModule } from '../table/table.module';
import { HasRoleModule } from '../../directives/role/has-role.module';

@NgModule({
  declarations: [ApplicationsTableComponent, ApplicationFiltersModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CardModule,
    TableModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    HasRoleModule
  ],
  exports: [ApplicationsTableComponent]
})
export class ApplicationsTableModule {}
