import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { ContractSignature, CreateContractSignature, SignedUrl, UpdateContractSignature, UpdateSignatureStatus } from "../types/contract-signature.types";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class ContractSignatureService {
  private baseUrl = `${environment.apiUrl}/applications`;

  constructor(private http: HttpClient) {}

  public createContractSignature(
    applicationId: number,
    createContractSignature: CreateContractSignature,
    token?: string
  ): Observable<ContractSignature> {
    const formData = new FormData();

    formData.append('signature', createContractSignature.signature);
    formData.append('agreement', createContractSignature.agreement);

    if (createContractSignature.pdf) {
      formData.append('pdf', createContractSignature.pdf);
    }

    if (createContractSignature.socialSecurityNumber) {
      formData.append('socialSecurityNumber', createContractSignature.socialSecurityNumber);
    }

    return this.http.post<ContractSignature>(
      `${this.baseUrl}/${applicationId}/signatures`,
      formData,
      {
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public getByApplicationId(applicationId: number): Observable<ContractSignature[]> {
    return this.http.get<ContractSignature[]>(
      `${this.baseUrl}/${applicationId}/signatures`,
      { withCredentials: true }
    );
  }

  public getSignatureSignedUrl(applicationId: number, documentId: number, token?: string): Observable<SignedUrl> {
    return this.http.get<SignedUrl>(
      `${this.baseUrl}/${applicationId}/signatures/${documentId}/url`,
      { 
        withCredentials: true,
        headers: token ? new HttpHeaders().set('Authorization', `Bearer ${token}`) : undefined
      }
    );
  }

  public update(
    applicationId: number,
    signatureId: number,
    updateContractSignature: UpdateContractSignature
  ): Observable<ContractSignature> {
    const formData = new FormData();

    formData.append('pdf', updateContractSignature.pdf);

    return this.http.patch<ContractSignature>(
      `${this.baseUrl}/${applicationId}/signatures/${signatureId}`,
      formData,
      { withCredentials: true }
    );
  }

  public updateAgreementStatus(
    applicationId: number,
    signatureId: number,
    updateSignatureStatus: UpdateSignatureStatus
  ): Observable<ContractSignature> {
    return this.http.patch<ContractSignature>(
      `${this.baseUrl}/${applicationId}/signatures/${signatureId}/status`,
      { ...updateSignatureStatus },
      { withCredentials: true }
    );
  }
}
