import { NgModule } from '@angular/core';

import { ApplicationsListComponent } from './applications-list.component';
import { ApplicationsTableModule } from '../applications-table/applications-table.module';


@NgModule({
  declarations: [ApplicationsListComponent],
  imports: [ApplicationsTableModule],
  exports: [ApplicationsListComponent]
})
export class ApplicationsListModule {}
