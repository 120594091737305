import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public label?: string;
  @Input() public icon?: string;
  @Input() public variant?: 'contained' | 'outlined' | 'warn' = 'contained';
  @Input() public svgIcon: string = '';
  @Input() public phone?: string;
  @Input() public disabled?: boolean;
  @Input() public size?: 'small' | 'large' = 'large';

  public handleClick(): void {
    if (this.phone) {
      window.open(this.phone, '_self');
    }
  }
}
