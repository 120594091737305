import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-signatures-creation',
  templateUrl: './signatures-creation.component.html',
  styleUrls: ['./signatures-creation.component.scss']
})
export class SignaturesCreationComponent {
  @Input() displaySignatureBox: boolean;
  @Output() signatureAction: EventEmitter<SafeResourceUrl> = new EventEmitter();
  @Output() agreementClick: EventEmitter<string> = new EventEmitter();
  public isCreating = true;
  public savedSignature?: SafeResourceUrl;
  public signature: FormControl = new FormControl('', Validators.required);

  public constructor(private sanitizer: DomSanitizer) {}

  public async handleSaveSignatureClick(): Promise<void> {
    this.savedSignature = this.sanitizer.bypassSecurityTrustResourceUrl(this.signature.value);
    this.isCreating = false;

    this.signatureAction.emit(this.savedSignature);
  }

  public handleRecreateSignatureClick(): void {
    this.isCreating = true;
  }

  public handleAgreementButtonClick(agreement: string): void {
    this.agreementClick.emit(agreement);
  }
}
