import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { FeaturesModule } from './features/features.module';
import { environment } from "../environments/environment";
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SharedModule } from './shared/shared.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CoreModule,
    AppRoutingModule,
    FeaturesModule,
    GoogleTagManagerModule.forRoot({ id: environment.gtmId }),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    SharedModule
  ]
})
export class AppModule {}
