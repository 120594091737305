<form
  [formGroup]="loginForm"
  (submit)="handleLoginFormSubmit()"
>
  <div class="form-header">
    <span class="fw-bold">Hey, Welcome Back</span>
    <h1>
      Log In to <span class="text-purple-100">Mobilend</span>
    </h1>
  </div>
  <div class="form-content">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column gap-4 mb-3">
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
          <mat-error>
            {{ getErrorMessage('email', 'Email') }}
          </mat-error>
        </mat-form-field>
        <mat-form-field hideRequiredMarker appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            [type]="hidePasswordValue ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="togglePasswordInput($event)"
          >
            <mat-icon>
              {{ hidePasswordValue ? 'visibility' : 'visibility_off' }}
            </mat-icon>
          </button>
          <mat-error>
            {{ getErrorMessage('password', 'Password') }}
          </mat-error>
        </mat-form-field>
      </div>
      <a
        routerLink="/auth/forgot-password"
        class="text-cyan-100 fw-bold text-decoration-none"
      >
        Forgot Your Password?
      </a>
    </div>
    <app-button
      label="LOG IN"
      type="submit"
    ></app-button>
  </div>
</form>