import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";

import { PageNotFoundComponent } from "./page-not-found.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [RouterModule, MatButtonModule, SharedModule]
})
export class PageNotFoundModule {}
