<app-header variant="light"></app-header>
<div class="how-it-works-page-content">
  <div class="left-side">
    <div class="
      d-flex
      flex-column
      align-items-center
      align-items-sm-start
      left-side
    ">
      <h1 class="text-center text-sm-start">
        Consolidating your debt made easy
      </h1>
      <span class="fw-bold text-blue-purple-gradient">
        Easy & transparent experience.
      </span>
    </div>
    <div class="credit-card-image-container">
      <img src="../../../assets/images/consolidating.png" alt="consolidating" />
    </div>
  </div>
  <div class="right-side">
    <div class="step-box">
      <span class="step-box-number">
        01
      </span>
      <span class="step-box-title">
        Set up your account
      </span>
      <div class="mb-5">
        <p class="step-box-description">
          You will have your own online portal, your loan information and documentation are all securely stored on mobilend.com.
        </p>
        <p class="step-box-description">
          You can login anywhere in the world and get access to your personal finance information.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/quick-underwriting.svg" alt="information" />
            <div class="mt-3">
              <span>Mobile access anywhere.</span>
              <p>
                You can pick up your mobile device anywhere and access your financial information.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/solid-security.svg" alt="lock" />
            <div class="mt-3">
              <span class="fw-bold">
                Rock solid security
              </span>
              <p>
                We store, transact and issue payment through the most secure bank grade security systems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-box">
      <span class="step-box-number">
        02
      </span>
      <span class="step-box-title">
        Mobilend.com analyzes your finances
      </span>
      <p class="mb-5 step-box-description">
        We examine your current financial situation and identify opportunities to consolidate most if not all your high rate credit card and other obligations into one single simple interest personal installment loan.
      </p>
      <div class="
        d-flex
        justify-content-center
        justify-content-md-start
      ">
        <app-button
          label="CONTACT US"
          svgIcon="semiarrow-right"
          routerLink="/contact-us"
        ></app-button>
      </div>
    </div>
    <div class="step-box">
      <span class="step-box-number">
        03
      </span>
      <span class="step-box-title">
        Get funded and get ahead
      </span>
      <p class="mb-5 step-box-description">
        Once you are approved and your loan proceeds will go into your checking account. We can consult you to identify which creditors to pay off first that will benefit you the most. We are happy to help to manage these complex transactions. Please give us a call any time. We would love to help to achieve the savings with your new debt consolidation loan.
      </p>
      <div class="row gy-4">
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/live-payment.svg" alt="computer" />
            <div class="mt-3">
              <span>Live payment data</span>
              <p>
                All of your payments will be made available in your mobilend.com portal.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/receive-new-offers.svg" alt="cellphone" />
            <div class="mt-3">
              <span>Receive new offers</span>
              <p>
                From time to time, we may uncover better products for you to save.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/customer-service.svg" alt="global" />
            <div class="mt-3">
              <span>Customer service</span>
              <p>
                We will help you manage your finances and provide additional services if needed.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="how-it-works-box">
            <img src="../../../assets/images/working-together.svg" alt="cellphone network" />
            <div class="mt-3">
              <span>Working together</span>
              <p>
                Your mobilend.com portal is where we deliver the latest information about your finances. Log in and check in often.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step-box">
      <span class="step-box-number">
        04
      </span>
      <span class="step-box-title">
        Keep yourself on track
      </span>
      <p class="mb-5 step-box-description">
        Mobilend.com will keep you on track for future borrowing and spending. Debt consolidation loans are a great first step to gain control of your personal finances. But it is up to you to keep your spending in check and borrow only when you need to. We are constantly developing new products to get our customers to the next stage of their financial life. Talk to our customer advocates for other products.
      </p>
      <div class="
        d-flex
        justify-content-center
        justify-content-md-start
      ">
        <app-button
          label="GET STARTED TODAY"
          svgIcon="semiarrow-right"
          routerLink="/enrollment"
        ></app-button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>