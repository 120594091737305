import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

import { UsersComponent } from './users.component';
import { UsersCreationEditionComponent } from './users-creation-edition/users-creation-edition.component';
import { CardModule } from '../card/card.module';
import { TableModule } from '../table/table.module';
import { ActivateDeactivateUserModalComponent } from './activate-deactivate-user-modal/activate-deactivate-user-modal.component';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { UsersFiltersModalComponent } from './users-filters-modal/users-filters-modal.component';
import { HasRoleModule } from '../../directives/role/has-role.module';

@NgModule({
  declarations: [UsersComponent, UsersCreationEditionComponent, ActivateDeactivateUserModalComponent, DeleteUserModalComponent, UsersFiltersModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    CardModule,
    HasRoleModule,
    TableModule
  ],
  exports: [UsersComponent]
})
export class UsersModule {}
