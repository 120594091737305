import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { format } from 'date-fns';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html'
})
export class DateInputComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() name: string;
  @Input() maxDate?: Date;
  public config = {
    containerClass: 'datepicker-theme',
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false
  } as BsDatepickerConfig;
  public value: Date;
  public onChange: (value: string) => void
  public onTouched: () => void;

  constructor(public control: NgControl) {
    this.control.valueAccessor = this;
  }

  public registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    if (value) {
      this.value = new Date(value);
    }
  }

  public handleDateChange(date?: Date): void {
    if (this.value !== date) {
      this.onChange(date ? format(date, 'yyyy-MM-dd') : '');
    }
  }
}
