<h1>Privacy Notice</h1>
<div class="d-flex flex-column gap-4">
  <div>
    <p>
      This privacy notice for Kuber Financial, LLC dba Mobilend (“Company,” “we,” “us,” or “our”), describes how and why we might collect, store, use, and/or share (“process”) your information when you use our services (“Services”), such as when you:
    </p>
    <ul>
      <li>
        Visit our website at https://www.kuberfinancial.com, or any website of ours that links to this privacy policy.
      </li>
      <li>
        Engage with us in other related ways – including any telephonic communications, sales, marketing, or events.
      </li>
    </ul>
    <p>
      <strong>Questions or concerns?</strong> Reading this privacy policy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@kuberfinancial.com.
    </p>
  </div>
  <div class="d-flex flex-column gap-5">
    <div class="d-flex flex-column gap-3 text-uppercase">
      <strong>Table of Contents</strong>
      <ol>
        <li>
          What information do we collect?
        </li>
        <li>
          How do we process your information?
        </li>
        <li>
          When and with whom do we share your personal information?
        </li>
        <li>
          Do we use cookies and other tracking technologies?
        </li>
        <li>
          How long do we keep your information?
        </li>
        <li>
          How do we keep your information safe?
        </li>
        <li>
          Do we collect information from minors?
        </li>
        <li>
          What are your privacy rights?
        </li>
        <li>
          Controls for do-not-track features
        </li>
        <li>
          Do california residents have specific privacy rights?
        </li>
        <li>
          Do we make updates to this notice?
        </li>
        <li>
          How can you contact us about this notice?
        </li>
        <li>
          How can you review, update or delete the data we collect from you?
        </li>
      </ol>
    </div>
    <div>
      <p class="fw-bold">
        1. WHAT INFORMATION DO WE COLLECT?
      </p>
      <div>
        <p class="fw-bold">
          Information automatically collected.
        </p>
        <p>
          <strong>In short:</strong> We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
        </p>
        <div class="mb-3">
          <p>
            <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
          </p>
          <ul>
            <li>
              Names
            </li>
            <li>
              Email addresses
            </li>
            <li>
              Social Security number
            </li>
            <li>
              Date of birth
            </li>
            <li>
              Mobilend Account ID
            </li>
            <li>
              Other similar identifiers
            </li>
          </ul>
        </div>
        <p>
          <strong>Sensitive Information.</strong> We do not process sensitive information.
        </p>
        <p>
          All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
        </p>
      </div>
      <div>
        <p class="fw-bold">
          Personal information you disclose to us.
        </p>
        <p>
          <strong>In short:</strong> some information – such as your Internet Protocol (IP) address and/or browser and device characteristics – is collected automatically when you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and similar technologies.
        </p>
        <div class="mb-3">
          <p>
            The information we collect includes:
          </p>
          <ul>
            <li>
              Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services, and which record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called “crash dumps”), and hardware settings).
            </li>
            <li>
              Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, internet service provider and/or mobile carrier, operating system, and system configuration information.
            </li>
            <li>
              Location Data. We may collect location data such as information about your device’s location, which can be either precise or imprecise. How much information we collect depends on the type and setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </p>
      <div>
        <p>
          <strong>In short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
        </p>
        <div class="mb-3">
          <p class="fw-bold">
            We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
          </p>
          <ul>
            <li>
              <strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.
            </li>
            <li>
              <strong>To respond to user inquiries/offer support to users.</strong> We may process information to respond to your inquiries and solve any potential issues you might have with the requested service.
            </li>
            <li>
              <strong>To send you marketing and promotional communications.</strong> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see “WHAT ARE YOUR PRIVACY RIGHTS?” below.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </p>
      <div>
        <p>
          <strong>In short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
        </p>
        <div class="mb-3">
          <p>
            We may need to share your personal information in the following situations:
          </p>
          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
            </li>
            <li>
              <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates, include our parent company and any subsidiaries, joint venture partners, contracted affiliates, or other companies that we control or that are under common control with us.
            </li>
            <li>
              <strong>Business Partners.</strong> We may share your information with our business partners or affiliates to offer you certain products, services, or promotions.
            </li>
            <li>
              <strong>Monevo Inc.</strong> We may share your personal data with Monevo Inc and their lending partners to try and find you a loan or other credit product. Monevo Inc may also share your personal data with TransUnion & other credit bureaus for the purposes of matching you to Monevo’s lending partners’ relevant products and services you are seeking. Full information as to how Monevo processes your personal data and your rights can be found here : <a href="https://app.monevo.us/privacy-policy">https://app.monevo.us/privacy-policy</a>.
            </li>
          </ul>
          <div class="board mb-3">
            <div class="row board-header">
              <div class="col-6">
                Reasons we can share your personal information
              </div>
              <div class="col-3 text-center">
                Does Mobilend share?
              </div>
              <div class="col-3 text-center">
                Can you limit this sharing?
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our everyday business purposes – such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our marketing purposes – to offer our products and services to you
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For joint marketing with other financial companies
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our affiliates’ everyday business purposes – information about your transactions and experiences
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our affiliate’s everyday business purposes – Information about your creditworthiness
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our affiliates to market to you
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-6">
                For our non-affiliates to market to you
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
              <div class="col-3 text-center">
                <span>Yes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </p>
      <div>
        <p>
          <strong>In short:</strong> We may use cookies and other tracking technologies to collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        5. HOW LONG DO WE KEEP YOUR INFORMATION?
      </p>
      <div>
        <p>
          <strong>In short:</strong> We keep your information as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
        </p>
        <p>
          We will only keep your personal information as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 5 years.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or if it is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        6. HOW DO WE KEEP YOUR INFORMAITON SAFE?
      </p>
      <div>
        <p>
          <strong>In short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        7. DO WE COLLECT INFORMATION FROM MINORS?
      </p>
      <div>
        <p>
          In short: We do not knowingly collect data from or market to children under 18 years of age.
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such minor and consent to dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records if you become aware of any data we may have collected from children under the age 18, please contact us at <span class="text-cyan-100">info@kuberfinancial.com</span>.
        </p>
      </div>
    </div>
    <div>
      <p id="opt-out" class="fw-bold">
        8. WHAT ARE YOUR PRIVACY RIGHTS?
      </p>
      <div>
        <p>
          <strong>In short:</strong> You may review, change, or terminate your account at any time.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
        </p>
        <p>
          If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
        </p>
        <p>
          <span class="fw-bold text-decoration-underline">Opting out of marketing and promotional communications:</span> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” below. You will then be removed from the marketing lists – however, we may still communicate with you, for example to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
        </p>
        <p>
          <span class="fw-bold text-decoration-underline">Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit <a href="http://www.aboutads.info/choices" target="_blank">https://www.aboutads.info/choices</a>.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        9. CONTROLS FOR DO NOT TRACK FEATURES
      </p>
      <div>
        <p>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate or signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized as such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </p>
      <div>
        <p>
          <strong>In short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year a free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
        </p>
        <div>
          <p class="fw-bold">
            CCPA Privacy Notice
          </p>
          <p>
            The California Code of Regulations defines a “resident” as:
          </p>
          <p>
            (1) Every individual who is in the State of California for other than a temporary or transitory purpose; and
            <br />
            (2) Every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose.
          </p>
          <p>
            All other individuals are defined as “non-residents.”
          </p>
          <p>
            If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your personal information.
          </p>
          <span
            class="dont-sell-my-personal-information"
            (click)="handleDontSellMyPersonalInformationClick()"
          >
            Don't Sell My Personal Information
          </span>
        </div>
        <div>
          <p class="fw-bold">
            What categories of personal information do we collect?
          </p>
          <p>
            We have collected the following categories of personal information in the past twelve (12) months:
          </p>
          <div class="board mb-3">
            <div class="row board-header">
              <div class="col-5">
                Category
              </div>
              <div class="col-5">
                Examples
              </div>
              <div class="col-2">
                Collected
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                A. Identifiers
              </div>
              <div class="col-5">
                <p>
                  Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, internet Protocol address, email address, social security number, and account name
                </p>
              </div>
              <div class="col-2">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                B. Personal information categories listed in California Customer Records Statute.
              </div>
              <div class="col-5">
                <p>
                  Name, contact information, education, employment history, and financial information
                </p>
              </div>
              <div class="col-2">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                C. Protected classification characteristics under California or federal law.
              </div>
              <div class="col-5">
                <p>
                  Gender and date of birth
                </p>
              </div>
              <div class="col-2">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                D. Commercial Information
              </div>
              <div class="col-5">
                <p>
                  Transaction information, purchase history, financial details, and payment information
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                E. Biometric Information
              </div>
              <div class="col-5">
                <p>
                  Fingerprints and voiceprints
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                F. Internet or other similar network activity
              </div>
              <div class="col-5">
                <p>
                  Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
                </p>
              </div>
              <div class="col-2">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                G. Geolocation data
              </div>
              <div class="col-5">
                <p>
                  Device location
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                H. Audio, electronic, visual, thermal, olfactory, or similar information
              </div>
              <div class="col-5">
                <p>
                  Images and audio, video or call recordings created in connection with our business activities
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                I. Professional or employment-related information
              </div>
              <div class="col-5">
                <p>
                  Business contact details in order to provide you our services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                </p>
              </div>
              <div class="col-2">
                <span>Yes</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                J. Education Information
              </div>
              <div class="col-5">
                <p>
                  Student records and directory information
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
            <div class="row board-content">
              <div class="col-5">
                K. Interferences drawn from other personal information
              </div>
              <div class="col-5">
                <p>
                  Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics
                </p>
              </div>
              <div class="col-2">
                <span>No</span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <p>
              We may also collect other personal information outside of these categories instances where you interact with us in person, online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>
                Receiving help through our customer support channels;
              </li>
              <li>
                Participation in customer surveys or contests; and
              </li>
              <li>
                Facilitation in the delivery of our Services and to respond to your inquiries.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p class="fw-bold">
            How do we use and share your personal information?
          </p>
          <p>
            More information about our data collection and sharing practices can be found in this privacy notice.
          </p>
          <p>
            You may contact us by visiting <a href="https://www.kuberfinancial.com/contact-us" target="_blank">https://www.kuberfinancial.com/contact-us</a>, or by referring to the contact details at the bottom of this document.
          </p>
          <p>
            If you are using an authorized agent to exercise your right to opt out, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
          </p>
        </div>
        <div>
          <p class="fw-bold">
            Will your information be shared with anyone else?
          </p>
          <p>
            We may disclose your personal information with our affiliates and/or service providers pursuant to a written contract between us and each affiliate or service provider. Each service provider is for a for-profit entity that processes the information on our behalf.
          </p>
          <p>
            We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be “selling” of your personal information.
          </p>
          <p>
            Kuber Financial, LLC has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.
          </p>
        </div>
        <div>
          <p class="fw-bold">
            Your rights with respect to your personal data
          </p>
          <div>
            <p class="text-decoration-underline">
              Right to request deletion of the data – Request to delete
            </p>
            <p>
              You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
            </p>
          </div>
          <div>
            <p class="text-decoration-underline">
              Right to be informed – Request to know
            </p>
            <div>
              <p>
                Depending on the circumstances, you have a right to know:
              </p>
              <ul>
                <li>
                  Whether we collect and use your personal information;
                </li>
                <li>
                  The categories of personal information that we collect;
                </li>
                <li>
                  The purposes for which the collected personal information is used;
                </li>
                <li>
                  Whether we sell your personal information to third parties;
                </li>
                <li>
                  The categories of personal information that we sold or disclosed for a business purpose;
                </li>
                <li>
                  The categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
                </li>
                <li>
                  The business or commercial purpose for collecting or selling personal information.
                </li>
              </ul>
            </div>
            <p>
              In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
            </p>
          </div>
          <div>
            <p class="text-decoration-underline">
              Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
            </p>
            <p>
              We will not discriminate against you if you exercise your privacy rights.
            </p>
          </div>
          <div>
            <p class="text-decoration-underline">
              Verification process
            </p>
            <p>
              Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
            </p>
          </div>
          <div>
            <p class="text-decoration-underline">
              Other privacy rights
            </p>
            <ul>
              <li>
                You may object to the processing of your personal information.
              </li>
              <li>
                You may request correction of your personal data if it is incorrect or no longer relevant or ask to restrict the processing of the information.
              </li>
              <li>
                You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
              </li>
              <li>
                You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
              </li>
            </ul>
            <p>
              To exercise these rights, you can contact us by visiting <a href="https://www.kuberfinancial.com/contact-us" target="_blank">https://www.kuberfinancial.com/contact-us</a>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we candle your data, we would like to hear from you.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </p>
      <div>
        <p>
          <strong>In short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </p>
      <div>
        <p>
          If you have questions or comments about this notice, you may email us at <span class="text-cyan-100">info@kuberfinancial.com</span> or by post to:
        </p>
        <p>
          Kuber Financial, LLC
          <br />
          400 Spectrum Center Drive, Suite 350
          <br />
          Irvine, CA 92618
          <br />
          United States
          <br />
          (866) 330-1669
        </p>
      </div>
    </div>
    <div>
      <p class="fw-bold">
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </p>
      <div>
        <p>
          Based on the applicable laws of your country, you may have the right to request access to personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete or personal information, contact us or visit the following for more information: <a href="http://kuberfinancial.com/contact-us" target="_blank">https://kuberfinancial.com/contact-us</a>.
        </p>
      </div>
    </div>
    <div id="electronic-records-statement">
      <p class="fw-bold">
        14. STATEMENT ON USE OF ELECTRONIC RECORDS
      </p>
      <div>
        <p>
          In order for you to apply for and, if approved, receive a loan or other product from Mobilend or its affiliates you need to (1) consent to receive any loan agreement, all legally required disclosures, notices (including privacy notices), and other information (together, the “Electronic Records”) in electronic form and (2) to use “Electronic Signatures” for entering into any loan agreement with Mobilend. Before you can consent as described above, Mobilend is required by law to provide you with certain information and disclosures about the use of Electronic Records. The Statement on the Use of Electronic Records (“Statement”) is intended to provide you with such information and disclosures.
        </p>
        <p>
          <strong>Scope of Your Consent.</strong> Your consent applies to (1) all Electronic Records related to servicing your loan, including but not limited to privacy and collection notices, (2) All related information provided by you to complete the application process, (3) all Electronic Records related to requesting loan or product information, replying to an advertisement or mailer, and contacting us regarding a product or transaction.
        </p>
        <p>
          <strong>Withdrawing Your Consent and Updating Your Contact Information.</strong> You may withdraw your consent to receive Electronic Records at any time after receiving a loan product, at no additional costs to you, by contacting <span class="text-cyan-100">info@kuberfinancial.com</span>. Please ensure that Kuber has your latest email address and other contact information by updating it in your customer portal.
        </p>
        <p>
          <strong>Computer or Mobile Device Requirements.</strong> Mobilend.com supports the latest web browsers. To access our site, ensure your computer or mobile device is up to date and visit our Terms & Conditions for more info. To save your Electronic Records your computer or mobile device must be capable of saving Electronic Records being viewed by your web browser. Moreover, to download, save and view a copy of your Electronic Records, you will need a browser or software capable of rendering Adobe Acrobat PDF files. You may also print Electronic Records if you have a printer and related software needed to print pages viewed in web browser.
        </p>
        <p>
          <strong>Paper Copies.</strong> You may receive paper copies of any of the Electronic Records by printing or downloading the Electronic Records Directly to your computer, or by written request, with specific details of the Electronic Records you want mailed to:
        </p>
        <p>
          Kuber Financial, LLC
          <br />
          Attn: Mobilend Records Request
          <br />
          400 Spectrum Center Drive, Suite 350
          <br />
          Irvine, CA 92618
          <br />
          United States
          <br />
          (866) 330-1669
        </p>
        <p>
          Please include your name, home address we have on record for you, account ID, and mailing address if different.
        </p>
        <p>
          <strong>Request for Mobilend to Share Your Application Information with a Third Party.</strong> For you to apply for and, if approved, receive a loan or financial product from any third party with which you request Mobilend to share your application or personal information (“Third Party”) you need to consent to receive all legally required disclosure notices (including privacy notices), and other information (the “Electronic Records”) from the Third Party in electronic form. You may receive paper copies of any of the Electronic Records of the Third party by printing or downloading such Electronic Records directly to your computer, or by written request by contacting the Third Party at their mailing address, email address, or telephone provided by Third Party. You may withdraw your consent to continue receiving Electronic Records from the Third Party at anytime by contacting the Third Party. Your consent applies to all Electronic Records related to your loan or financial product and transaction with the Third Party.
        </p>
      </div>
    </div>
  </div>
</div>

<app-dont-sell-my-personal-information-modal
  (cancel)="closeModal()"
></app-dont-sell-my-personal-information-modal>