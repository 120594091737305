<app-header></app-header>
<div class="first-container">
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column gap-3">
      <div class="title-overview">
        <div class="rectangle"></div>
        <span>for everyone, everywhere…</span>
      </div>
      <h1>
        <span class="text-purple-100">We make</span> personal finance simple and affordable
      </h1>
      <h2>
        We are redefining what personal finance looks like
      </h2>
    </div>
  </div>
  <div class="boards">
    <div class="board">
      <div class="board-content">
        <span class="text-uppercase">
          for everyone, everywhere…
        </span>
        <p>
          No matter what your financial situation may be, our customer advocates are available to talk through many personal finance offers available at mobilend.com. Our mission is to help you save.
        </p>
        <div class="images-container">
          <div class="d-flex flex-column align-items-center gap-3">
            <img src="../../../assets/images/quick-underwriting.svg" alt="underwriging" />
            <span>Quick Underwriting</span>
          </div>
          <div class="d-flex flex-column align-items-center gap-3">
            <img src="../../../assets/images/data-protection.svg" alt="data protection" />
            <span>Data Protection</span>
          </div>
          <div class="d-flex flex-column align-items-center gap-3">
            <img src="../../../assets/images/flexible-plans.svg" alt="flexible plans" />
            <span>Flexible Plans</span>
          </div>
        </div>
      </div>
    </div>
    <div class="board">
      <div class="board-content">
        <span class="text-uppercase">
          We help you to succeed
        </span>
        <p>
          Whether you have bank, credit card or auto finance debt. We can help you save by paying off your high interest loans with a single monthly payment, simple interest personal loan.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="second-container">
  <div class="d-flex flex-column flex-xl-row gap-4">
    <div class="information-container">
      <div class="image-container">
        <img
          src="../../../assets/images/forget-traditional-banking.png"
          alt="forget traditional banking"
        />
      </div>
      <div class="information-card">
        <span>Forget traditional banking</span>
        <p>
          Traditional banks do not offer debt consolidation loans. They are slow and you have to be a checking account customer first. Mobilend.com underwrites in minutes, and we can quickly consolidate debt from banks, credit companies, and auto lenders into one single, simple interest personal installment loan.
        </p>
      </div>
    </div>
    <div class="information-container">
      <div class="image-container">
        <img
          src="../../../assets/images/apply-from-anywhere.png"
          alt="apply from anywhere"
        />
      </div>
      <div class="information-card">
        <span>Apply from anywhere</span>
        <p>
          Mobilend.com is available 24/7. Our state of the art online application process takes minutes to complete. Our underwriters are experienced in personal lending and debt consolidation. They are here to help you with any questions you may have.
        </p>
        <p>
          Give us a call at <a href="tel:1-866-330-1669" class="text-decoration-none fw-bold text-cyan-100">866-330-1669</a>
        </p>
      </div>
    </div>
    <div class="information-container">
      <div class="image-container">
        <img
          src="../../../assets/images/consolidate-all-your-debt.png"
          alt="consolidate all your debt"
        />
      </div>
      <div class="information-card">
        <span>Consolidate all your debt</span>
        <p>
          If you have high rate interest from a credit card balance or are a few days behind on your personal loans, mobilend.com is here to help. Our consumer advocates work with you to reduce your monthly payments into one single simple interest payment to help you get on and get ahead in life. We send you the loan proceeds directly to your bank account in a sound and safe manner so you have the freedom to choose which credit card to pay off first.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="third-container">
  <div class="d-flex flex-column align-items-start">
    <div class="title-overview">
      <div class="rectangle"></div>
      <span>MOBILEND.COM</span>
    </div>
    <h1>Join Us</h1>
    <h2>
      Our knowledgeable staff is here to answer any questions you may have with your current personal finance obligations. Give us a call or click on contact us.
    </h2>
    <app-button
      label="CONTACT US"
      svgIcon="semiarrow-right"
      routerLink="/contact-us"
    ></app-button>
  </div>
</div>
<app-footer></app-footer>