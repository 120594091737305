import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './features/home/home.component';
import { AboutUsComponent } from './features/about-us/about-us.component';
import { WhatWeDoComponent } from './features/what-we-do/what-we-do.component';
import { WhyMobilendComponent } from './features/why-mobilend/why-mobilend.component';
import { OurServicesComponent } from './features/our-services/our-services.component';
import { HowItWorksComponent } from './features/how-it-works/how-it-works.component';
import { ContactUsComponent } from './features/contact-us/contact-us.component';
import { HelpCenterComponent } from './features/help-center/help-center.component';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'what-we-do', component: WhatWeDoComponent },
  { path: 'why-mobilend', component: WhyMobilendComponent },
  { path: 'our-services', component: OurServicesComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'help-center', component: HelpCenterComponent },
  {
    path: 'legal-center',
    loadChildren: () => import('./features/legal-center/legal-center.module').then(m => m.LegalCenterModule)
  },
  { 
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'enrollment',
    loadChildren: () => import('./features/enrollment/enrollment.module').then(m => m.EnrollmentModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./features/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'affiliate',
    loadChildren: () => import('./features/affiliate/affiliate.module').then(m => m.AffiliateModule)
  },
  {
    path: 'agent',
    loadChildren: () => import('./features/agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: 'customer-service',
    loadChildren: () => import('./features/customer-service/customer-service.module').then(m => m.CustomerServiceModule)
  },
  {
    path: 'underwriter',
    loadChildren: () => import('./features/underwriter/underwriter.module').then(m => m.UnderwriterModule)
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
