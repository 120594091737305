<div>
  <h1>Personal information</h1>
</div>
<form
  [formGroup]="personalInformationForm"
  (submit)="handleFormSubmit()"
  class="mt-5"
>
  <div class="row">
    <div
      *ngIf="!selectedAmount"
      class="col-sm-6 col-md-12 col-lg-6"
    >
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Requested Amount*</mat-label>
        <input
          matInput
          formControlName="requestedAmount"
          prefix="$"
          mask="separator.0"
          thousandSeparator=","
        />
        <mat-error>
          {{ getErrorMessage('requestedAmount', 'Requested Amount') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Suffix*</mat-label>
        <mat-select formControlName="suffix">
          <mat-option *ngFor="let option of suffixInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('suffix', 'Suffix') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field appearance="outline">
        <mat-label>Social Security Number</mat-label>
        <input
          matInput
          type="text"
          formControlName="socialSecurityNumber" 
          mask="000-00-0000"
          [ngClass]="getSSNInputClass()"
        />
        <mat-icon matSuffix (click)="toggleVisibility()">
          {{ isSSNInputVisible ? 'visibility' : 'visibility_off' }}
        </mat-icon>
        <mat-error>
          {{ getErrorMessage('socialSecurityNumber', 'Social Security Number') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <app-date-input
        formControlName="birthDate"
        label="Birth Date*"
        [maxDate]="maxBirthDate"
      ></app-date-input>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <app-address-input
        formControlName="address"
        label="Address*"
      ></app-address-input>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Residence Type*</mat-label>
        <mat-select formControlName="residenceType">
          <mat-option *ngFor="let option of residenceTypeInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('residenceType', 'Residence Type') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Months At Address*</mat-label>
        <input
          type="number"
          matInput
          formControlName="monthsAtAddress"
        />
        <mat-error>
          {{ getErrorMessage('monthsAtAddress', 'Months At Address') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Monthly Rent or Mortage*</mat-label>
        <input
          matInput
          formControlName="monthlyRent"
          prefix="$"
          mask="separator.0"
          thousandSeparator=","
        />
        <mat-error>
          {{ getErrorMessage('monthlyRent', 'Monthly Rent') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Loan Purpose*</mat-label>
        <mat-select formControlName="loanPurpose">
          <mat-option *ngFor="let option of loanPurposeInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('loanPurpose', 'Loan Porpuse') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Credit Rating*</mat-label>
        <mat-select formControlName="creditRating">
          <mat-option *ngFor="let option of creditRatingInputOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ getErrorMessage('creditRating', 'Credit Rating') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Promo Code</mat-label>
        <input matInput formControlName="campaignCode" />
        <mat-error>
          {{ getErrorMessage('campaignCode', 'Promo Code') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm-6 col-md-12 col-lg-6">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Annual Income*</mat-label>
        <input
          matInput
          aria-label="Input that displays a tooltip that has information about the income you must provide"
          formControlName="annualIncome"
          prefix="$"
          mask="separator.0"
          thousandSeparator=","
        />
        <mat-error>
          {{ getErrorMessage('annualIncome', 'Annual Income') }}
        </mat-error>
      </mat-form-field>
      <p style="font-size:10px;">* List your total available income including wages, retirement, investments, and rental properties. 
        You are not required to disclose income that is alimony, child support or separate maintenance unless you want to use that 
        income to qualify for a loan. If applicable, include self-employment salary. For total commission earnings, 
        provide an annual average. Increase non-taxable income/benefits by 25%.
      </p>
    </div>
  </div>
  <div class="personal-information-checkboxes-container">
    <div class="d-flex align-items-start">
      <mat-checkbox
        formControlName="creditReportConsent"
        color="primary"
        [ngClass]="{ 'mat-checkbox-invalid': isCheckboxInvalid('creditReportConsent') }"
      ></mat-checkbox>
      <div>
        <p>
          By submitting this registration form, I understand that I am providing written instructions in accordance with the Fair Credit Reporting Act and other applicable laws for Kuber Financial, LLC dba Mobilend (“Mobilend”) too request and receive information about me from third parties, including but not limited to a copy of my consumer credit report, score, and motor vehicle records from consumer reporting agencies, at any time for so long as I have an active Mobilend account. I further authorize Mobilend to retain a copy of my information for use in accordance with Mobliend’s <a routerLink="/legal-center/terms-conditions" target="_blank">Terms of Use</a> and <a routerLink="/legal-center/privacy-policy" target="_blank">Privacy Policy</a>.
        </p>
        <mat-hint *ngIf="isCheckboxInvalid('creditReportConsent')">
          You must accept to continue
        </mat-hint>
      </div>
    </div>
    <div class="d-flex align-items-start">
      <mat-checkbox
        formControlName="sharingConsent"
        color="primary"
        [ngClass]="{ 'mat-checkbox-invalid': isCheckboxInvalid('sharingConsent') }"
      ></mat-checkbox>
      <div>
        <p class="disclosure ps-2">
          By clicking "Continue", I acknowledge to sharing my personal data with Mobilend to request and receive information about me from third parties for the purposes of pre-qualification of loan and credit products. I understand that at any time by clicking on this <a href="/legal-center/privacy-policy/#opt-out" target="_blank">link</a> and following the instructions in the <span class="text-decoration-underline">"WHAT ARE YOUR PRIVACY RIGHTS?"</span> that I can Opt-Out.
        </p> 
        <mat-hint *ngIf="isCheckboxInvalid('sharingConsent')">
          You must accept to continue
        </mat-hint>
      </div>
    </div>
  </div>
  <p class="mt-4 mb-5">
    <strong>* We will utilize this information to access your credit scores to prequalify you for a financial product.</strong>
  </p>
  <div class="
    d-flex
    justify-content-center
    justify-content-lg-end
  ">
    <div class="
      d-flex
      flex-column
      flex-sm-row
      flex-md-column
      flex-lg-row
      gap-4
    ">
      <app-button
        variant="outlined"
        label="BACK"
        (click)="handleBackButtonClick()"
      ></app-button>
      <app-button
        type="submit"
        label="CONTINUE"
        svgIcon="semiarrow-right"
      ></app-button>
    </div>
  </div>
</form>