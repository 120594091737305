import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

import { StepperAction, Actions } from '../enrollment.component';
import { Application, CreateAccountDto, PersonalInformationResponse } from '../../../core/types/application.types';
import { CoreService } from '../../../core/services/core.service';
import { ApplicationService } from "../../../core/services/application.service";

export enum Forms {
  PersonalInformation = 'personalInformation',
  EmploymentInformation = 'employmentInformation',
}

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-create-account-step',
  templateUrl: './create-account-step.component.html',
  styleUrls: ['./create-account-step.component.scss']
})
export class CreateAccountStepComponent implements OnInit {
  @Input() selectedAmount: number;
  @Input() application?: Application;
  @Output() public onAction = new EventEmitter<StepperAction>();
  public heardFromInputOptions: SelectOption[] = [
    { label: 'Search Engine', value: 'Search Engine'},
    { label: 'Recommended by Friend or Colleague', value: 'Recommended by Friend or Colleague' },
    { label: 'Mailer', value: 'Mailer' },
    { label: 'Social Media', value: 'Social Media' },
    { label: 'Blog or Publication', value: 'Blog or Publication' },
    { label: 'Other', value: 'Other' }
  ];
  public suffixInputOptions: SelectOption[] = [
    { label: 'None', value: 'none'},
    { label: 'Jr', value: 'JR' },
    { label: 'Sr', value: 'SR' },
    { label: 'I', value: 'I' },
    { label: 'II', value: 'II' },
    { label: 'III', value: 'III' },
    { label: 'IV', value: 'IV' }
  ];
  public residenceTypeInputOptions: SelectOption[] = [
    { label: 'Home Owner', value: 'Home Owner'},
    { label: 'Rent', value: 'Rent' },
    { label: 'Live with Family', value: 'Live with Family' },
    { label: 'Other', value: 'Other' },
  ];
  public creditRatingInputOptions: SelectOption[] = [
    { label: 'Excellent  (781 - 850)', value: 'Excellent'},
    { label: 'Good  (661 - 780)', value: 'Good' },
    { label: 'Fair  (601 - 660)', value: 'Fair' },
    { label: 'Poor  (300-600)', value: 'Poor' },
  ]
  public loanPurposeInputOptions: SelectOption[] = [
    { label: 'Debt Consolidation', value: 'Debt Consolidation' },
    { label: 'Home Improvement', value: 'Home Improvement' },
    { label: 'Pool', value: 'Pool' },
    { label: 'Solar', value: 'Solar' },
    { label: 'Vacation', value: 'Vacation' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Alimony or Child Support', value: 'Alimony or Child Support' },
    { label: 'New Auto Purchase', value: 'New Auto Purchase' },
    { label: 'Large Purchase', value: 'Large Purchase' },
    { label: 'Pay Off Credit Cards', value: 'Pay Off Credit Cards' },
    { label: 'Student Loan Refinancing', value: 'Student Loan Refinancing' },
    { label: 'Education', value: 'Education' },
    { label: 'Special Occasion', value: 'Special Occasion' },
    { label: 'Cosmetic Procedures', value: 'Cosmetic Procedures' },
    { label: 'Moving and Relocation', value: 'Moving and Relocation' },
    { label: 'Household Expenses', value: 'Household Expenses' },
    { label: 'Medical', value: 'Medical' },
    { label: 'Dental', value: 'Dental' },
    { label: 'Taxes', value: 'Taxes' },
    { label: 'Business', value: 'Business' },
    { label: 'Funeral Expenses', value: 'Funeral Expenses' },
    { label: 'Auto Loan Refinance', value: 'Auto Loan Refinance' },
    { label: 'Used Auto Purchase', value: 'Used Auto Purchase' },
    { label: 'Auto Lease Buyout', value: 'Auto Lease Buyout' },
    { label: 'Baby', value: 'Baby' },
    { label: 'Adoption Expenses', value: 'Adoption Expenses' },
    { label: 'Emergency Expenses', value: 'Emergency Expenses' },
    { label: 'Wedding', value: 'Wedding' },
    { label: 'Engagement', value: 'Engagement' },
    { label: 'Auto Repairs', value: 'Auto Repairs' },
    { label: 'Equipment Purchase', value: 'Equipment Purchase' },
    { label: 'Other', value: 'Other' },
  ];
  public createAccountForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    middleName: '',
    phone: ['', [Validators.required]],
    heardFrom: ''
  });

  constructor(
    private coreService: CoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private applicationService: ApplicationService,
  ) {}

  public ngOnInit(): void {
    this.setFormsInitialValues();
  }

  public handleBackButtonClick(): void {
    this.onAction.emit({ action: Actions.Back });
  }

  public getErrorMessage(controlName: keyof typeof this.createAccountForm.controls, label: string): string {
    let errorMessage = '';
    const control = this.createAccountForm.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    }

    return errorMessage;
  }

  public async handleFormSubmit(): Promise<void> {
    this.createAccountForm.markAllAsTouched();

    if (this.createAccountForm.valid) {
      try {
        this.coreService.setIsLoading(true);
  
        const existingToken = this.coreService.getSessionStorageData<{ token: string }>('token');
        const { application, token } = await this.createAccount(existingToken?.token);
  
        if (!existingToken) {
          this.coreService.setSessionStorageData('token', { token })          
        }
  
        this.onAction.emit({ action: Actions.Next, data: { application } });
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  private setFormsInitialValues(): void {
    if (this.application) {
      const { customer } = this.application;

      this.createAccountForm.patchValue({
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        middleName: customer.middleName || '',
        phone: customer.phone
      });
    }
  }

  private createAccount(token?: string): Promise<PersonalInformationResponse> {
    const dontSellPersonalInformation = localStorage.getItem('dsmpiconsent');
    const createAccountPayload: CreateAccountDto = {
      email: this.createAccountForm.value.email!,
      firstName: this.createAccountForm.value.firstName!,
      lastName: this.createAccountForm.value.lastName!,
      heardFrom: this.createAccountForm.value.heardFrom,
      middleName: this.createAccountForm.value.middleName,
      phone: this.createAccountForm.value.phone!,
      dontSellPersonalInformation: dontSellPersonalInformation === 'true'
    };

    return lastValueFrom(this.applicationService.createAccount(createAccountPayload, token));
  }
}
