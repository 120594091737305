<h1>Select your debt amount</h1>
<div class="amount-info-container">
  <div class="selected-amount-container">
    <span class="fw-bold">Your debt amount</span>
    <span class="selected-amount">
      {{ debtAmount.value | currency:'USD':'symbol':'1.0' }}
    </span>
  </div>
  <div class="amount-input-container">
    <span class="text-grey-500 fw-bold">
      {{ 0 | currency:'USD':'symbol':'1.0' }}
    </span>
    <mat-slider
      color="primary"
      aria-label="units"
      min="0"
      step="500"
      class="flex-grow-1"
      max="100000"
      [formControl]="debtAmount"
      (input)="handleInputChange($event)"
    ></mat-slider>
    <span class="text-grey-500 fw-bold">
      {{ 100000 | currency:'USD':'symbol':'1.0' }}
    </span>
  </div>
</div>
<app-button
  label="CONTINUE"
  svgIcon="semiarrow-right"
  class="align-self-center align-self-sm-end"
  [disabled]="debtAmount.value === 0"
  (click)="handleContinueButtonClick()"
></app-button>