import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HowItWorksComponent } from './how-it-works.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [HowItWorksComponent],
  imports: [RouterModule, SharedModule]
})
export class HowItWorksModule {}
