import { Application, Integration } from "./application.types";

export interface Offers {
  debtSettlement: Offer[];
  lending: Offer[];
}
export enum OfferStatus {
  PreQualified = 'Pre-qualified',
  PreApproved = 'Pre-approved'
}
export interface Offer {
  id: number;
  amount?: number | null;
  type: OfferTypes;
  term: number;
  savings?: number | null;
  apr?: number | null;
  estimatedCost?: number | null;
  doNothingTotalCost?: number | null;
  settlementTotal?: number | null;
  interestRate?: number | null;
  fee?: number | null;
  monthlyPayment: number;
  partner: Partners;
  externalReference?: string | null;
  selected: boolean;
  externalLink?: string | null;
  application?: Application | null;
  lenderLogo?: string;
  lenderName?: string;
  status?: OfferStatus;
  caveatTitle?: string;
  caveatDescription?: string;
  disclosures?: string;
  featuresDescription?: string[];
  details?: OfferDetails | null;
  createdAt: string;
  updatedAt: string;
}
export interface OffersStatistics {
  [key: string]: {
    averageAmount: number;
    percentage: number;
  };
}
export interface OfferDetails {
  id: number;
  applicationFee: number;
  preDti: number;
  postDti: number;
  postTotalMonthlyPayment: number;
  nonBrfTotalMonthlyPayment: number;
  preTotalMonthlyPayment: number;
  paymentDifference: number;
  paymentFrequency: string;
  offer?: Offer | null;
}

export enum OfferTypes {
  DebtReliefProgram = 'Debt Relief Program',
  SecuredLoan = 'Secured Loan',
  PersonalLoan = 'Personal Loan',
  LineOfCredit = 'Line Of Credit',
  Other = 'Other'
}

export enum Partners {
  FreedomFinancial = 'Freedom Financial',
  Achieve = 'Achieve',
  Mobilend = 'Mobilend',
  Supermoney = 'Supermoney',
  Monevo = 'Monevo'
}

export interface ExternalPartnerResponse {
  offers: Offer[];
  integration: Integration;
}