<app-header></app-header>
<div class="first-container">
  <div class="d-flex flex-column flex-lg-row align-items-center mb-3">
    <div>
      <h1>
        We are lowering cost of credit with technology and an experienced team of consumer advocates
      </h1>
      <p>
        Our mission is to leverage technology to bring the best personal finance product to all Americans. Our true debt consolidation loan pays creditors directly and securely. We use technology to make complex financial transactions easy and transparent. Our customers can enjoy the savings and get ahead in life.
      </p>
    </div>
    <img src="../../../assets/images/what-we-do.png" alt="what we do" />
  </div>
  <div class="d-flex justify-content-center">
    <app-button
      label="CONTACT US TODAY"
      svgIcon="semiarrow-right"
      phone="tel:1-866-330-1669"
    ></app-button>
  </div>
</div>
<div class="second-container">
  <div class="logos-carousel-container">
    <div class="logos-carousel">
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
      <img src="../../../assets/logos/accredited-business.png" alt="accredit business logo" />
      <img src="../../../assets/logos/trustpilot.png" alt="trustpilot logo" />
      <!-- <img src="../../../assets/logos/transunion-2.png" alt="transunion logo" /> -->
      <img src="../../../assets/logos/reliant.png" alt="reliant logo" />
    </div>
  </div>
  <div class="what-we-do-boxes-container-one">
    <div class="what-we-do-box-one">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 85">
        <g id="Vector">
        <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
        <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
        <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
        <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
        </g>
        </g>
      </svg>
      <span class="what-we-do-box-one-number">
        01
      </span>
      <span class="mb-2 fw-bold">
        Underwriting
        </span>
      <p>
        We take pride in really understanding our customer’s financial situation. Our underwriters and underwriting system doesn’t simply look at credit scores and bank statements. We look through each of your financial obligations and optimize your total savings into one of our debt consolidation loans. Our underwriters are experienced and knowledgeable in finding the most amount of savings for you.
      </p>
    </div>
    <div class="what-we-do-box-one">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 85">
        <g id="Vector">
        <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
        <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
        <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
        <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
        </g>
        </g>
      </svg>
      <span class="what-we-do-box-one-number">
        02
      </span>
      <span class="mb-2 fw-bold">
        Direct Payment
        </span>
      <p>
        One of the biggest benefits of our debt consolidation product is that we pay you directly and give you the freedom to pay off creditors that you feel that's most beneficial to you. You have full control over how to reduce your monthly payments with your current creditors. Give us a call, we are happy to help you anytime to pick which creditor to pay off first.
      </p>
    </div>
    <div class="what-we-do-box-one">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 85">
        <g id="Vector">
        <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
        <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
        <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
        <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
        </g>
        </g>
      </svg>
      <span class="what-we-do-box-one-number">
        03
      </span>
      <span class="mb-2 fw-bold">
        Building Credit
        </span>
      <p>
        Mobilend.com offers simple interest personal loans. Every on time payment helps you to improve your credit standing as we report customer loan payments to national credit bureaus on a monthly basis.
      </p>
    </div>
    <div class="what-we-do-box-one">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 85">
        <g id="Vector">
        <path d="M27 0H29V56H27V0Z" fill="#F9DB87"/>
        <path d="M56 27V29L0 29L8.74224e-08 27L56 27Z" fill="#F9DB87"/>
        <path d="M47.0919 7.4939L48.5061 8.90811L8.90809 48.5061L7.49388 47.0919L47.0919 7.4939Z" fill="#F9DB87"/>
        <path d="M48.506 47.0919L47.0918 48.5061L7.49379 8.90815L8.908 7.49394L48.506 47.0919Z" fill="#F9DB87"/>
        </g>
        </g>
      </svg>
      <span class="what-we-do-box-one-number">
        04
      </span>
      <span class="mb-2 fw-bold">
        Education
        </span>
      <p>
        Mobilend.com’s mission to help our customers doesn’t stop here. We work with national debt relief education centers to help you stay on top of your finances and offer education programs to give you the knowledge and power to manage your personal finances in the future. Our mission is to get people out of debt and control their financial freedom.
      </p>
    </div>
  </div>
</div>
<div class="third-container">
  <div class="title-overview">
    <div class="rectangle"></div>
    <span>MOBILEND.COM</span>
  </div>
  <div class="row gy-7">
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/computer.svg" alt="computer" />
        <div class="mt-3">
          <span>Mobilend Platform</span>
          <p>
            Our customer portal allows you to manage your debt consolidation loan. It’s easy and user friendly.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/cloud.svg" alt="cloud" />
        <div class="mt-3">
          <span>Privacy & Security</span>
          <p>
            We take our client’s security and privacy seriously. All of our client’s financial data is securely stored in our dedicated cloud storage.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/gears.svg" alt="gears" />
        <div class="mt-3">
          <span>Unique Plans</span>
          <p>
            We know that every client has a different story so we put a unique plan for everyone. We customize our loan product to each client’s unique needs.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/certificate.svg" alt="certificate" />
        <div class="mt-3">
          <span>Free Education</span>
          <p>
            Financial freedom and financial empowerment starts with financial education. We teamed up with national personal finance education centers to make learning fun. Empowering our clients with information is our ultimate goal.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/call-center.svg" alt="call center" />
        <div class="mt-3">
          <span>U.S. Call Center</span>
          <p>
            We are proud to serve our clients right here in our Southern California call center. All calls are handled in our secured facility and by dedicated staff.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="what-we-do-box-two">
        <img src="../../../assets/images/heart.svg" alt="heart" />
        <div class="mt-3">
          <span>Customer Care</span>
          <p>
            Our customer is the lifeblood of our company. Mobilend.com takes pride in our interaction with our customers. We learn and improve one call at a time. We are happy to serve you.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>