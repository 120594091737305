<h1>Review Your Application</h1>
<div class="application-information-container">
  <div class="information-container">
    <h2>
      Loan Information
    </h2>
    <div class="row gy-4 w-100">
      <div class="col-lg-6 col-xxl-3">
        <p>
          <span class="data-label">Partner:</span>
          {{ selectedOffer.partner === 'Monevo' ? selectedOffer.lenderName : selectedOffer.partner }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <p>
          <span class="data-label">Term:</span>
          {{ selectedOffer.term }} months
        </p>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <p>
          <span class="data-label">Monthly Payment:</span>
          {{ selectedOffer.monthlyPayment | currency:'USD':'symbol':'1.2-2' }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <p>
          <span class="data-label">
            Loan Amount:
          </span>
          {{ selectedOffer.amount | currency:'USD':'symbol':'1.2-2' }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-3">
        <p>
          <span class="data-label">
            {{ selectedOffer.partner === 'Monevo' ? 'APR' : 'Interest Rate' }}:
          </span>
          {{ ((selectedOffer.partner === 'Monevo' ? selectedOffer.apr! : selectedOffer.interestRate!) / 100) | percent:'1.0-2' }}
        </p>
      </div>
      <div class="col">
        <p>
          <span class="data-label">
            Est. Total Repayment:
          </span>
          {{ selectedOffer.term * selectedOffer.monthlyPayment | currency:'USD':'symbol':'1.2-2' }}
        </p>
      </div>
    </div>
  </div>
  <div class="information-container">
    <h2>Personal Information</h2>
    <div class="row gy-4 w-100">
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">First Name:</span> {{ application.customer.firstName }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Email:</span> {{ application.customer.email }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Address:</span> {{ application.customer.street }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Middle Name:</span> {{ application.customer.middleName || 'N/A' }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Mobile Phone:</span> {{ application.customer.phone | mask:'(000) 000-0000' }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">City:</span> {{ application.customer.city }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Last Name:</span> {{ application.customer.lastName }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">Date of Birth:</span> {{ application.customer.birthDate | date:'yyyy-MM-dd':'UTC' }}
        </p>
      </div>
      <div class="col-lg-6 col-xl-4">
        <p>
          <span class="data-label">State:</span> {{ application.customer.state }}
        </p>
      </div>
      <div class="col-lg-8">
        <p>
          <span class="data-label">Suffix:</span> {{ application.customer.suffix || 'N/A' }}
        </p>
      </div>
      <div class="col-lg-4">
        <p>
          <span class="data-label">Zip Code:</span> {{ application.customer.zipCode }}
        </p>
      </div>
    </div>
  </div>
  <div class="information-container">
    <h2>Employment Information</h2>
    <div class="row gy-4 w-100">
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Income Type:</span>
          {{ application.employmentInformation!.incomeType }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Other Income:</span>
          {{ application.employmentInformation!.otherIncome || 'N/A' }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Employer Status:</span>
          {{ application.employmentInformation!.employerStatus }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Payment Frequency:</span>
          {{ application.employmentInformation!.paymentFrequency | titlecase  }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Holiday Payments:</span>
          {{ application.employmentInformation!.holidayPayments  }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Employer Name:</span>
          {{ application.employmentInformation!.employerName | titlecase  }}
        </p>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <p>
          <span class="data-label">Work Phone:</span>
          {{ application.employmentInformation!.workPhone ? (application.employmentInformation!.workPhone | mask:'(000) 000-0000') : 'N/A'  }}
        </p>
      </div>
      <div class="col-lg-6">
        <p>
          <span class="data-label">Monthly Income:</span>
          {{ application.customer.monthlyIncome | currency:'USD':'symbol':'1.2-2'  }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="
  d-flex
  justify-content-center
  justify-content-lg-end
">
  <div class="
    d-flex
    flex-column
    flex-sm-row
    flex-md-column
    flex-lg-row
    gap-4
  ">
    <app-button
      variant="outlined"
      label="BACK"
      (click)="handleBackButtonClick()"
    ></app-button>
    <app-button
      label="CONFIRM"
      svgIcon="semiarrow-right"
      (click)="handleConfirmButtonClick()"
    ></app-button>
  </div>
</div>