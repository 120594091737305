<app-header></app-header>
<div class="d-flex flex-column gap-5 p-7">
  <div class="mx-auto">
    <h1 class="mb-4">
      Legal <span class="text-purple-100">Center</span>
    </h1>
    <h2>Here you can find all the important information about using our service.</h2>
  </div>
  <div class="d-flex flex-column flex-xl-row gap-4">
    <div class="information-card">
      <span>TERMS AND CONDITIONS</span>
      <p>
        General usage terms at mobilend.com is in the link below.
      </p>
      <app-button
        label="LEARN MORE"
        routerLink="terms-conditions"
      ></app-button>
    </div>
    <div class="information-card">
      <span>PRIVACY POLICY</span>
      <p>
        Here at Mobilend.com, we take privacy seriously. Please take a look at our privacy policy. Please feel free to contact us about any concerns you may have.
      </p>
      <app-button
        label="LEARN MORE"
        routerLink="privacy-policy"
      ></app-button>
    </div>
    <div class="information-card">
      <span>COMMUNICATION CONSENT AGREEMENT</span>
      <p>
        Agreement to receive information and documents from Mobilend.com
      </p>
      <app-button
        label="LEARN MORE"
        routerLink="communication-consent-agreement"
      ></app-button>
    </div>
    <div class="information-card">
      <span>FINANCIAL EDUCATION RESOURCE CENTER</span>
      <p>
        We are here with you all the way on your financial journey. We collected a few financial education resources for you. Click below to learn more.
      </p>
      <app-button
        label="LEARN MORE"
        routerLink="financial-education-resource-center"
      ></app-button>
    </div>
    <!-- <div class="information-card">
      <span>USURY DISCLOSURE</span>
      <p>Description</p>
      <app-button
        label="LEARN MORE"
        routerLink="usury-disclosure"
      ></app-button>
    </div> -->
  </div>
</div>
<app-footer></app-footer>