<form
  [formGroup]="twoFactorAuthenticationLoginForm"
  (submit)="handleSubmit()"
>
  <h1>Multifactor <span class="text-purple-100">Authentication</span></h1>
  <span>Enter an MFA code to complete sign-in.</span>
  <div class="form-content">
    <div class="d-flex flex-column gap-4">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Code</mat-label>
        <input
          matInput
          formControlName="code"
        />
        <mat-error
          [innerHTML]="getErrorMessage('code', 'Code')"
        >
        </mat-error>
      </mat-form-field>
    </div>
    <app-button
      label="CONTINUE"
      svgIcon="semiarrow-right"
      type="submit"
    ></app-button>
  </div>
</form>