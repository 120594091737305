import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { Router } from '@angular/router';
import { FormControl, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import html2pdf from 'html2pdf.js';

import { Application } from "../../../core/types/application.types";
import { Offer } from "../../../core/types/offer.types";
import { ModalComponent } from '../modal/modal.component';
import { Roles } from '../../../core/types/role.types';
import { AchAgreementComponent } from './ach-agreement/ach-agreement.component';
import { LoanAgreementComponent } from './loan-agreement/loan-agreement.component';
import { CoreService } from '../../../core/services/core.service';
import { ContractSignature } from '../../../core/types/contract-signature.types';
import { LoggedUser } from '../../../core/types/auth.types';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit, OnChanges {
  @Input() public application: Application;
  @Output() public createSignature: EventEmitter<SafeResourceUrl> = new EventEmitter();
  @ViewChild('loanAgreementModalContentTemplate')
  public loanAgreementModalContentTemplate: TemplateRef<any>;
  @ViewChild('achAgreementModalContentTemplate')
  public achAgreementModalContentTemplate: TemplateRef<any>;
  @ViewChild('loanAgreementModalActionsTemplate')
  public loanAgreementModalActionsTemplate: TemplateRef<any>;
  @ViewChild('achAgreementModalActionsTemplate')
  public achAgreementModalActionsTemplate: TemplateRef<any>;
  public _modalRef?: MatDialogRef<ModalComponent>;
  public selectedOffer?: Offer;
  public signaturesMap: Map<string, boolean> = new Map([
    ['loanAgreementFirst', false],
    ['loanAgreementSecond', false],
    ['loanAgreementThird', false],
    ['achAgreementFirst', false],
    ['achAgreementSecond', false],
    ['achAgreementThird', false]
  ]);
  public userRoles: typeof Roles = Roles;
  public createdSignature?: SafeResourceUrl;
  public signatureToDisplay?: SafeResourceUrl;
  public displayDownloadButton = false;
  public validExistingSignatures: ContractSignature[] = [];
  public socialSecurityNumber = new FormControl('', [Validators.required]);
  public loggedUser?: LoggedUser;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private coreService: CoreService,
    private authService: AuthService
  ) {}

  public get renderSignatureManagement(): boolean {
    return (this.loggedUser?.role === this.userRoles.Application) || this.router.url === '/enrollment';
  }

  public get displaySignatureBox(): boolean {
    return this.validExistingSignatures.length < 2;
  }

  public get modalRef(): MatDialogRef<ModalComponent> | undefined {
    return this._modalRef;
  }

  public set modalRef(value: MatDialogRef<ModalComponent> | undefined) {
    this._modalRef = value;

    if (this._modalRef) {
      this._modalRef.afterClosed().subscribe(() => {
        this.displayDownloadButton = false;
      });
    }
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser() || undefined;
    this.selectedOffer = this.application.offers!.find((offer) => offer.selected);
  }

  public ngOnChanges(): void {
    const lastLoanAgreementSignature = this.application.contractSignatures
      ?.find((contractSignature) => contractSignature.agreement === 'loan');
    const lastAchAgreementSignature = this.application.contractSignatures
      ?.find((contractSignature) => contractSignature.agreement === 'ach');

    if (lastLoanAgreementSignature && lastLoanAgreementSignature.status !== 'rejected') {
      this.validExistingSignatures.push(lastLoanAgreementSignature);
    }

    if (lastAchAgreementSignature && lastAchAgreementSignature.status !== 'rejected') {
      this.validExistingSignatures.push(lastAchAgreementSignature);
    }
  }

  public async handleSignatureAction(signature?: SafeResourceUrl): Promise<void> {
    this.createdSignature = signature;

    this.createSignature?.emit(this.createdSignature);
  }

  public async handleAgreementClick(agreement: string): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const lastSignature = this.application.contractSignatures
        ?.find((contractSignature) => contractSignature.agreement === agreement);

      if (lastSignature && lastSignature.status !== 'rejected') {
        const signatureImage = await lastValueFrom(this.http.get(lastSignature.url, { responseType: 'blob' }));
        const sliceSignaturesMapFromTo = agreement === 'loan' ? [0, 3] : [3, 6];

        this.signatureToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(signatureImage));
        this.displayDownloadButton = true;

        Array.from(this.signaturesMap).slice(...sliceSignaturesMapFromTo).forEach(([key]) => {
          this.signaturesMap.set(key, true);
        });
      } else if (this.createSignature) {
        this.signatureToDisplay = this.createdSignature;
      }

      this.modalRef = this.dialog.open(ModalComponent, {
        autoFocus: 'dialog',
        height: '100%',
        data: {
          contentTemplate: agreement === 'loan' ?
            this.loanAgreementModalContentTemplate :
            this.achAgreementModalContentTemplate,
          actionsTemplate: agreement === 'loan' ?
            this.loanAgreementModalActionsTemplate :
            this.achAgreementModalActionsTemplate
        },
        restoreFocus: false
      });
    } finally {
      this.coreService.setIsLoading(false);
    }
  }

  public async handleSign(signatureIdentifier: string): Promise<void> {
    this.signaturesMap.set(signatureIdentifier, true);
  }

  public async handleDownloadPdfFile(agreement: 'loan' | 'ach', contractSignature?: ContractSignature): Promise<void> {
    try {
      this.coreService.setIsLoading(true);

      const a = document.createElement('a');
      let pdfFile: Blob;

      if (contractSignature?.signedAgreementPdfUrl) {
        pdfFile = await lastValueFrom(this.http.get(contractSignature.signedAgreementPdfUrl, { responseType: 'blob' }));
      } else {
        pdfFile = await this.generateAgreementPdfFile(agreement, this.signatureToDisplay!);
      }

      const url = URL.createObjectURL(pdfFile);
      a.href = url;
      a.download = `${agreement}-agreement.pdf`;

      a.click();
      URL.revokeObjectURL(url);
      a.remove();
    } finally {
      this.coreService.setIsLoading(false);
    }
  }

  public async generateAgreementPdfFile(agreement: 'loan' | 'ach', signature: SafeResourceUrl): Promise<File> {
    this.signaturesMap.forEach((_, key) => {
      this.signaturesMap.set(key, true);
    });

    const componentClass = agreement === 'loan' ? LoanAgreementComponent : AchAgreementComponent;
    const component = this.viewContainerRef.createComponent<any>(componentClass);

    try {
      component.location.nativeElement.style.display = 'none';
      component.instance.application = this.application;
      component.instance.bankAccount = this.application.bankAccount!;
      component.instance.selectedOffer = this.selectedOffer!;
      component.instance.signature = signature;
      component.instance.signaturesMap = this.signaturesMap;
      component.instance.setExistingSignatureDate();
      component.instance.setInitialAmortizations?.();
      component.instance.changeDetectorRef.detectChanges();
      
      const styles = (component.componentType as any)['ɵcmp'].styles[0] as string;
      const cleanedStyles = styles.replace(/\[_ngcontent-%COMP%\]/g, '');
      const htmlWithStyles = `
        <html>
          <head>
            <style>
              ${cleanedStyles}
            </style>
          </head>
          <body>
            ${component.location.nativeElement.innerHTML}
          </body>
        </html>
      `;
      const pdfOptions = {
        image: { type: 'jpeg', quality: 0.7 },
        html2canvas: { scale: 1, useCORS: true },
        pagebreak: { mode: ['avoid-all'] },
        jsPDF: {}
      };

      if (agreement === 'ach') {
        pdfOptions.jsPDF = { format: [210, 510] }
      }

      const pdf = await html2pdf().set(pdfOptions).from(htmlWithStyles).outputPdf('blob');

      return new File([pdf], `${agreement}-agreement.pdf`, { type: 'application/pdf' });
    } finally {
      component.destroy();
    }
  }
}
