import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { WarningComponent } from './warning.component';

@NgModule({
  declarations: [WarningComponent],
  imports: [CommonModule, MatIconModule],
  exports: [WarningComponent]
})
export class WarningModule {}
